import { useState } from "react";
import "../../styles/pricing_card.css";
// import images
import red_ring from "../../images/ring_red.svg";
import red_green from "../../images/ring_green.svg";
import red_blue from "../../images/ring_blue.svg";
import red_white from "../../images/ring_white.svg";
import checkbox from "../../images/grey_check.svg";
// import Recoil and atoms
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "./../../../Atoms";
// import comps
import PricingCardExecute from "./PricingCardsExecute";
// import paragraphs
import * as int from "../../../paragraphs/pricing_cards_paragraphs";
// define rings colors object
const rings = {
  red: red_ring,
  green: red_green,
  blue: red_blue,
  white: red_white,
};

function PricingCard(props) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [clicked, setClicked] = useState(false);
  // destructure props
  const { color, popular, price, period, periodCode } = props;

  return (
    <>
      {/* if btn clicked this comp will render which will execute some js code to handle the payment  */}
      {clicked && <PricingCardExecute periodCode={periodCode} />}
      <div className="pricing_card_wrapper">
        <div className="ring_popular_wrapper">
          <img src={rings[color]} alt="ring" className="ring" />
          {popular && (
            <span className="popular">{int.popular[displayLanguage]}</span>
          )}
        </div>

        <p>{period}</p>
        <div className="price">
          <strong>€{price}</strong>
          <small>{int.perMonth[displayLanguage]}</small>
        </div>
        <ul>
          <li>
            <img src={checkbox} alt="checkbox" /> 50,000{" "}
            {int.channels[displayLanguage]}
          </li>
          <li>
            <img src={checkbox} alt="checkbox" />
            {int.imageQuality[displayLanguage]}
          </li>
          <li>
            <img src={checkbox} alt="checkbox" />
            {int.premiumChannels[displayLanguage]}
          </li>
          <li>
            <img src={checkbox} alt="checkbox" />
            {int.seriesAndMovies[displayLanguage]}
          </li>
        </ul>
        <button
          className="choose_plan_btn"
          onClick={() => {
            setClicked(true);
          }}
        >
          {int.choosePlan[displayLanguage]}
        </button>
      </div>
    </>
  );
}

export default PricingCard;
