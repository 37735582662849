import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { grey } from "@mui/material/colors";
// Recoil imports
import { useRecoilState, useRecoilValue } from "recoil";
import {
  displayLanguageAtom,
  emailWhatsappAtom,
  isVoucherValid,
} from "../../../Atoms";
// import images
import IPTV_preferences_decoration3 from "../../images/secure_pay/IPTV_preferences_decoration3.webp";
// import paragraphs
import * as int from "../../../paragraphs/payment_paragraphs.js";

///

function IPTVPreferencesSubscriptions({ setSelected, stepSetter }) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [error, setError] = useState(false);
  const [untouched, setUntouched] = useState(true);
  const [emailWhatsapp, setEmailWhatsapp] = useRecoilState(emailWhatsappAtom);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [wrapperClass, setWrapperClass] = useState(
    "payment_credit_card_wrapper  animation_wrapper_entrance2 animation_wrapper"
  );
  //
  function insertDashes(str) {
    if (str.length >= 7) {
      return str.slice(0, 3) + "-" + str.slice(3, 6) + "-" + str.slice(6);
    } else if (str.length >= 4) {
      return str.slice(0, 3) + "-" + str.slice(3);
    } else {
      return str;
    }
  }
  const handleContinue = () => {
    const { email, whatsapp } = emailWhatsapp;
    if ((email === "") & (whatsapp === "")) {
      setError(true);
    } else {
      stepSetter(2);
    }
  };
  const handleBack = () => {
    setSelected("bundle_selection");
  };

  const handleEmailOnChange = (e) => {
    setUntouched(false);
    const oldEmailWhatsapp = { ...emailWhatsapp };
    oldEmailWhatsapp["email"] = e.target.value;
    setEmailWhatsapp(oldEmailWhatsapp);
  };

  const handleWhatsappOnChange = (e) => {
    setUntouched(false);
    // check if str contains dashes and digits only or digits only, if so let it go/ we just do this to restrict the input to accept numbers only
    const inputValue = e.target.value;
    if (
      /^[\d\-]+$/.test(inputValue) ||
      /^\d+$/.test(inputValue) ||
      inputValue == ""
    ) {
      const oldEmailWhatsapp = { ...emailWhatsapp };
      oldEmailWhatsapp["whatsapp"] = insertDashes(inputValue.replace(/-/g, ""));
      setEmailWhatsapp(oldEmailWhatsapp);
    }
  };

  const produceEmailWhatsappError = () => {
    if (error) {
      return (
        <div className="email_whatsapp_input_error prevent_select">
          {int.emailWhatsappInputError[displayLanguage]}
        </div>
      );
    }
  };
  //
  useEffect(() => {
    setWrapperClass("payment_credit_card_wrapper  animation_wrapper ");
    // raise error if email and whatsapp are both empty
    const { email, whatsapp } = emailWhatsapp;
    if ((email === "") & (whatsapp === "") & !untouched) {
      setError(true);
    }
  }, [emailWhatsapp]);
  return (
    <div className={wrapperClass}>
      <div className="payment_credit_cards_first_half">
        <img
          style={{ display: imgLoaded ? "block" : "none" }}
          src={IPTV_preferences_decoration3}
          alt="IPTV_preferences_decoration3"
          onLoad={() => {
            setImgLoaded(true);
          }}
        />

        {!imgLoaded && (
          <div>
            <Skeleton
              variant="rectangular"
              width={170}
              height={341}
              animation="wave"
              sx={{ color: grey[900] }}
            />
          </div>
        )}
      </div>
      <div className="payment_credit_cards_second_half">
        <div className="bundle_selection_instruction">
          <h1>{int.contactInfoPrompt[displayLanguage]}</h1>
          <p>{int.accountDeliveryInstruction[displayLanguage]}</p>
        </div>
        <form onSubmit={handleContinue}>
          <div className="credit_card_form">
            <div>
              <label htmlFor="email">{int.email[displayLanguage]}*</label>
              <div className="input_wrapper">
                <input
                  type="email"
                  name="email"
                  value={emailWhatsapp["email"]}
                  placeholder="ex: crystal+@example.co.uk"
                  onChange={handleEmailOnChange}
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="whatsapp_number">
                {int.whatsappNumber[displayLanguage]}*
              </label>
              <div className="input_wrapper">
                <input
                  type="whatsapp_number"
                  value={emailWhatsapp["whatsapp"]}
                  placeholder="ex: 345-953-2100"
                  onChange={handleWhatsappOnChange}
                  required
                />
              </div>
              {produceEmailWhatsappError()}
            </div>
            <div className="actioning_btns_wrapper">
              <button
                className="validate_btn"
                type="submit"
                onClick={handleContinue}
              >
                {int.continueButton[displayLanguage]}
              </button>
              <button className="validate_btn return_btn" onClick={handleBack}>
                {int.backButton[displayLanguage]}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default IPTVPreferencesSubscriptions;
