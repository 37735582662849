import { useState, useEffect } from "react";
import axios from "axios";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
// import components
import PaymentDetailsHeader from "./PaymenDetailstHeader";
import PaymentDetailsCreditCard from "./PaymentDetailsCreditCard";
import PaymentDetailsPaypal from "./PaymentDetailsPaypal";
// Import images
import secure_pay_lock from "../../images/secure_pay/secure_pay_lock.svg";
// import CSS
import "../../styles/account_settings.css";
// import paragraphs
import * as int from "../../../paragraphs/payment_paragraphs.js";

function PaymentDetails({ stepSetter, setCurrentStep }) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper_entrance2 animation_wrapper"
  );

  // this is the selected state based on which the settings comps will be conditionally rendered
  // please note that the setter and the state have been passed down to the AccountSettingsHeader and its from there where we updtae the state
  const [selected, setSelected] = useState("credit_card");

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
  }, []);

  return (
    <div className={wrapperClass}>
      <div className="payment_detail_header">
        <h1>{int.paymentDetails[displayLanguage]}</h1>
        <div className="card_is_secure_wrapper">
          <img src={secure_pay_lock} alt="lock_icon" />
          <div>
            <span>{int.cardSecure[displayLanguage]}</span>
            <p>{int.dataProtection[displayLanguage]}</p>
          </div>
        </div>
      </div>
      <PaymentDetailsHeader setSelected={setSelected} selected={selected} />
      {selected == "credit_card" && (
        <PaymentDetailsCreditCard stepSetter={stepSetter} />
      )}
      {selected == "paypal" && <PaymentDetailsPaypal />}
      {selected == "amazon_pay" && "amazon_pay"}
    </div>
  );
}

export default PaymentDetails;
