// import images
import { useEffect, useState } from "react";
import paypal_decoration from "../../images/secure_pay/paypal_decoration.svg";
import paypal_logo from "../../images/secure_pay/paypal_logo.svg";
import Skeleton from "@mui/material/Skeleton";
import { grey } from "@mui/material/colors";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
// import paragraphs
import * as int from "../../../paragraphs/payment_paragraphs.js";
///
function PaymentDetailsPaypal() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [wrapperClass, setWrapperClass] = useState(
    "payment_credit_card_wrapper animation_wrapper_entrance2 animation_wrapper"
  );

  useEffect(() => {
    setWrapperClass("payment_credit_card_wrapper animation_wrapper ");
  }, []);
  //
  return (
    <div className={wrapperClass}>
      <div className="payment_credit_cards_first_half">
        <img
          src={paypal_decoration}
          alt="credit_cards_decoration"
          style={{ display: imgLoaded ? "block" : "none" }}
          onLoad={() => {
            setImgLoaded(true);
          }}
        />
        {!imgLoaded && (
          <div>
            <Skeleton
              variant="rectangular"
              width={170}
              height={341}
              animation="wave"
              sx={{ color: grey[900] }}
            />
          </div>
        )}
      </div>
      <div className="payment_credit_cards_second_half second_half_paypal">
        <img src={paypal_logo} alt="paypal_logo" className="paypal_logo" />
        <div className="credit_card_form">
          <div className="actioning_btns_wrapper">
            <button className="validate_btn paypal_btn" type="submit">
              {int.continueButton[displayLanguage]}
            </button>
            <button className="validate_btn return_btn">
              {int.backButton[displayLanguage]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetailsPaypal;
