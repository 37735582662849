export const faqHeader = {
  en: "Frequently Asked Questions (FAQ)",
  fr: "Questions Fréquemment Posées (FAQ)",
  es: "Preguntas Frecuentes (FAQ)",
  de: "Häufig gestellte Fragen (FAQ)",
  it: "Domande Frequenti (FAQ)",
  tr: "Sıkça Sorulan Sorular (SSS)",
  ru: "Часто задаваемые вопросы (FAQ)",
  pl: "Najczęściej Zadawane Pytania (FAQ)",
};

export const whatIsIPTV = {
  en: "What is IPTV?",
  fr: "Qu'est-ce que l'IPTV ?",
  es: "¿Qué es IPTV?",
  de: "Was ist IPTV?",
  it: "Cos'è l'IPTV?",
  tr: "IPTV nedir?",
  ru: "Что такое IPTV?",
  pl: "Czym jest IPTV?",
};

export const whatIsIPTVAnswer1 = {
  en: "IPTV, short for Internet Protocol Television, is a modern way of watching television programs and content using an internet connection. Instead of traditional broadcasting methods like satellite or cable, IPTV delivers television signals through internet networks.",
  fr: "L'IPTV, abréviation de Télévision sur Protocole Internet, est une méthode moderne pour regarder des programmes de télévision et du contenu en utilisant une connexion internet. Au lieu des méthodes de diffusion traditionnelles telles que le satellite ou le câble, l'IPTV transmet les signaux de télévision via les réseaux internet.",
  es: "IPTV, siglas de Televisión por Protocolo de Internet, es una forma moderna de ver programas de televisión y contenido utilizando una conexión a internet. En lugar de los métodos de transmisión tradicionales como el satélite o el cable, el IPTV entrega señales de televisión a través de redes de internet.",
  de: "IPTV, kurz für Internet Protocol Television, ist eine moderne Möglichkeit, Fernsehprogramme und Inhalte über eine Internetverbindung zu sehen. Anstatt traditioneller Übertragungsmethoden wie Satellit oder Kabel überträgt IPTV Fernsehsignale über Internetnetzwerke.",
  it: "IPTV, acronimo di Televisione via Protocollo Internet, è un modo moderno per guardare programmi televisivi e contenuti utilizzando una connessione internet. Invece dei metodi di trasmissione tradizionali come il satellite o il cavo, l'IPTV trasmette segnali televisivi attraverso reti internet.",
  tr: "İnternet Protokolü Televizyonu (IPTV), televizyon programlarını ve içeriği internet bağlantısı kullanarak izlemenin modern bir yoludur. Uydu veya kablo gibi geleneksel yayın yöntemleri yerine, IPTV televizyon sinyallerini internet ağları üzerinden iletmektedir.",
  ru: "IPTV, сокращение от Интернет-телевидение по протоколу интернета, является современным способом просмотра телевизионных программ и контента с использованием интернет-соединения. Вместо традиционных методов трансляции, таких как спутниковое или кабельное телевидение, IPTV передает телевизионные сигналы через интернет-сети.",
  pl: "IPTV, skrót od Internet Protocol Television, to nowoczesny sposób oglądania programów telewizyjnych i treści za pomocą połączenia internetowego. Zamiast tradycyjnych metod nadawania, takich jak satelita czy kabel, IPTV dostarcza sygnały telewizyjne przez sieci internetowe.",
};

export const whatIsIPTVAnswer2 = {
  en: "This means you can stream TV shows, movies, sports events, and other media directly to your smart TV, computer, or mobile device. IPTV offers a convenient and flexible viewing experience, allowing you to access a wide range of channels and on-demand content with just an internet connection.",
  fr: "Cela signifie que vous pouvez diffuser des émissions de télévision, des films, des événements sportifs et d'autres contenus directement sur votre téléviseur intelligent, votre ordinateur ou votre appareil mobile. L'IPTV offre une expérience de visionnage pratique et flexible, vous permettant d'accéder à une large gamme de chaînes et de contenus à la demande avec une simple connexion internet.",
  es: "Esto significa que puedes transmitir programas de televisión, películas, eventos deportivos y otros medios directamente a tu televisor inteligente, computadora o dispositivo móvil. IPTV ofrece una experiencia de visualización conveniente y flexible, lo que te permite acceder a una amplia gama de canales y contenido a pedido con solo una conexión a internet.",
  de: "Dies bedeutet, dass du TV-Sendungen, Filme, Sportveranstaltungen und andere Medien direkt auf deinem Smart-TV, Computer oder Mobilgerät streamen kannst. IPTV bietet ein bequemes und flexibles Fernseherlebnis, das es dir ermöglicht, mit nur einer Internetverbindung auf eine Vielzahl von Kanälen und On-Demand-Inhalten zuzugreifen.",
  it: "Ciò significa che puoi trasmettere in streaming programmi TV, film, eventi sportivi e altri contenuti direttamente sul tuo smart TV, computer o dispositivo mobile. IPTV offre un'esperienza di visione comoda e flessibile, permettendoti di accedere a una vasta gamma di canali e contenuti on-demand con una semplice connessione internet.",
  tr: "Bu, TV şovlarını, filmleri, spor etkinliklerini ve diğer medyaları doğrudan akıllı TV'nizde, bilgisayarınızda veya mobil cihazınızda izleyebileceğiniz anlamına gelir. IPTV, sadece bir internet bağlantısıyla geniş bir kanal ve isteğe bağlı içerik yelpazesine erişmenizi sağlayan uygun ve esnek bir izleme deneyimi sunar.",
  ru: "Это означает, что вы можете потоково передавать телешоу, фильмы, спортивные события и другие медиа напрямую на ваш смарт-телевизор, компьютер или мобильное устройство. IPTV предлагает удобный и гибкий просмотр, позволяя вам получить доступ к широкому спектру каналов и контента по запросу только с помощью интернет-подключения.",
  pl: "Oznacza to, że możesz strumieniować programy telewizyjne, filmy, wydarzenia sportowe i inne media bezpośrednio na telewizorze inteligentnym, komputerze lub urządzeniu mobilnym. IPTV oferuje wygodne i elastyczne doświadczenie oglądania, umożliwiając dostęp do szerokiej gamy kanałów i treści na żądanie za pomocą tylko połączenia internetowego.",
};
export const howItWorks = {
  en: "How Does our IPTV Work?",
  fr: "Comment fonctionne notre IPTV ?",
  es: "¿Cómo funciona nuestro IPTV?",
  de: "Wie funktioniert unser IPTV?",
  it: "Come funziona il nostro IPTV?",
  tr: "IPTV'miz Nasıl Çalışır?",
  ru: "Как работает наш IPTV?",
  pl: "Jak działa nasza usługa IPTV?",
};

export const howItWorksAnswer1 = {
  en: "In our IPTV service, we make it easy for you to watch TV shows and movies. We give you a special file or a web link that acts like a playlist. This file or link contains all the information about the TV channels and media you can watch. Most modern apps and devices can read this file or link, so you can enjoy IPTV on whatever device you like.",
  fr: "Dans notre service IPTV, nous facilitons le visionnage de séries télévisées et de films. Nous vous fournissons un fichier spécial ou un lien web qui agit comme une playlist. Ce fichier ou lien contient toutes les informations sur les chaînes de télévision et les médias que vous pouvez regarder. La plupart des applications et des appareils modernes peuvent lire ce fichier ou lien, vous permettant ainsi de profiter de l'IPTV sur l'appareil de votre choix.",
  es: "En nuestro servicio de IPTV, te facilitamos ver programas de televisión y películas. Te proporcionamos un archivo especial o un enlace web que funciona como una lista de reproducción. Este archivo o enlace contiene toda la información sobre los canales de televisión y los medios que puedes ver. La mayoría de las aplicaciones y dispositivos modernos pueden leer este archivo o enlace, por lo que puedes disfrutar de IPTV en el dispositivo que prefieras.",
  de: "In unserem IPTV-Service machen wir es Ihnen einfach, TV-Sendungen und Filme anzusehen. Wir geben Ihnen eine spezielle Datei oder einen Web-Link, der wie eine Wiedergabeliste fungiert. Diese Datei oder der Link enthält alle Informationen über die TV-Sender und Medien, die Sie ansehen können. Die meisten modernen Apps und Geräte können diese Datei oder den Link lesen, sodass Sie IPTV auf dem von Ihnen gewünschten Gerät genießen können.",
  it: "Nel nostro servizio IPTV, ti facilitiamo la visione di programmi TV e film. Ti forniamo un file speciale o un link web che funziona come una playlist. Questo file o link contiene tutte le informazioni sui canali TV e sui media che puoi guardare. La maggior parte delle app e dei dispositivi moderni può leggere questo file o link, quindi puoi goderti l'IPTV sul dispositivo che preferisci.",
  tr: "IPTV hizmetimizde, TV şovlarını ve filmleri izlemenizi kolaylaştırıyoruz. Size bir çalma listesi gibi davranan özel bir dosya veya web bağlantısı sağlıyoruz. Bu dosya veya bağlantı, izleyebileceğiniz TV kanalları ve medya hakkındaki tüm bilgileri içerir. Çoğu modern uygulama ve cihaz bu dosyayı veya bağlantıyı okuyabilir, bu nedenle istediğiniz cihazda IPTV'nin keyfini çıkarabilirsiniz.",
  ru: "В нашем IPTV-сервисе мы облегчаем просмотр телешоу и фильмов. Мы предоставляем вам специальный файл или веб-ссылку, которые действуют как плейлист. Этот файл или ссылка содержат всю информацию о телевизионных каналах и медиа, которые вы можете смотреть. Большинство современных приложений и устройств могут читать этот файл или ссылку, поэтому вы можете наслаждаться IPTV на любом устройстве, которое вам нравится.",
  pl: "W naszej usłudze IPTV ułatwiamy oglądanie programów telewizyjnych i filmów. Dostarczamy ci specjalny plik lub link internetowy, który działa jak playlista. Ten plik lub link zawiera wszystkie informacje o kanałach telewizyjnych i mediach, które możesz oglądać. Większość nowoczesnych aplikacji i urządzeń może odczytać ten plik lub link, dzięki czemu możesz cieszyć się IPTV na dowolnym urządzeniu, które preferujesz.",
};

export const howItWorksAnswer2 = {
  en: "We also support MAG technology, which is a popular kind of device for IPTV. It lets you connect your TV to the internet and watch IPTV directly on your TV screen. With MAG, you can change channels, explore different shows, and have a user-friendly experience without needing extra devices. By offering both the file/link option and MAG support, we give you different ways to access our IPTV service based on the devices you prefer to use.",
  fr: "Nous prenons également en charge la technologie MAG, qui est un type d'appareil populaire pour l'IPTV. Elle vous permet de connecter votre téléviseur à Internet et de regarder l'IPTV directement sur votre écran de télévision. Avec MAG, vous pouvez changer de chaînes, explorer différents programmes et profiter d'une expérience conviviale sans avoir besoin de dispositifs supplémentaires. En proposant à la fois l'option du fichier/lien et le support MAG, nous vous offrons différentes façons d'accéder à notre service IPTV en fonction des appareils que vous préférez utiliser.",
  es: "También ofrecemos soporte para la tecnología MAG, que es un tipo popular de dispositivo para IPTV. Te permite conectar tu televisor a internet y ver IPTV directamente en la pantalla de tu televisor. Con MAG, puedes cambiar de canales, explorar diferentes programas y tener una experiencia fácil de usar sin necesidad de dispositivos adicionales. Al ofrecer tanto la opción de archivo/enlace como el soporte MAG, te brindamos diferentes formas de acceder a nuestro servicio de IPTV según los dispositivos que prefieras utilizar.",
  de: "Wir unterstützen auch die MAG-Technologie, die eine beliebte Art von Gerät für IPTV ist. Damit kannst du deinen Fernseher mit dem Internet verbinden und IPTV direkt auf deinem Fernsehbildschirm anschauen. Mit MAG kannst du Kanäle wechseln, verschiedene Shows erkunden und eine benutzerfreundliche Erfahrung ohne zusätzliche Geräte haben. Indem wir sowohl die Option der Datei/des Links als auch MAG-Unterstützung anbieten, geben wir dir verschiedene Möglichkeiten, auf unseren IPTV-Service zuzugreifen, je nach den Geräten, die du bevorzugst.",
  it: "Supportiamo anche la tecnologia MAG, che è un tipo di dispositivo popolare per l'IPTV. Ti consente di connettere il tuo televisore a Internet e guardare l'IPTV direttamente sullo schermo del televisore. Con MAG, puoi cambiare canali, esplorare diversi programmi e avere un'esperienza facile da usare senza bisogno di dispositivi aggiuntivi. Offrendo sia l'opzione del file/link che il supporto MAG, ti offriamo diverse modalità di accesso al nostro servizio IPTV in base ai dispositivi che preferisci utilizzare.",
  tr: "Ayrıca IPTV için popüler bir cihaz türü olan MAG teknolojisini de destekliyoruz. Bu teknoloji, televizyonunuzu internete bağlamanıza ve IPTV'yi doğrudan televizyon ekranında izlemenize olanak sağlar. MAG ile kanalları değiştirebilir, farklı programları keşfedebilir ve ekstra cihazlara ihtiyaç duymadan kullanıcı dostu bir deneyim yaşayabilirsiniz. Dosya/bağlantı seçeneği ve MAG desteği sunarak, IPTV hizmetimize erişmek için tercih ettiğiniz cihazlara göre farklı yöntemler sunuyoruz.",
  ru: "Мы также поддерживаем технологию MAG, которая является популярным типом устройства для IPTV. Она позволяет подключить ваш телевизор к интернету и смотреть IPTV непосредственно на экране телевизора. С помощью MAG вы можете переключать каналы, исследовать разные передачи и иметь удобный пользовательский опыт без необходимости использовать дополнительные устройства. Предлагая как опцию файла/ссылки, так и поддержку MAG, мы предоставляем вам разные способы доступа к нашему IPTV-сервису в зависимости от устройств, которые вы предпочитаете использовать.",
  pl: "Obsługujemy również technologię MAG, która jest popularnym rodzajem urządzenia do IPTV. Pozwala to podłączyć telewizor do internetu i oglądać IPTV bezpośrednio na ekranie telewizora. Za pomocą MAG możesz zmieniać kanały, przeglądać różne programy i mieć przyjazne dla użytkownika doświadczenie bez konieczności korzystania z dodatkowych urządzeń. Oferując zarówno opcję pliku/linku, jak i wsparcie MAG, dajemy Ci różne sposoby dostępu do naszej usługi IPTV w zależności od preferowanych przez Ciebie urządzeń.",
};

export const equipmentNeeded = {
  en: "What Equipment Do I Need To Use IPTV?",
  fr: "Quels équipements ai-je besoin pour utiliser l'IPTV ?",
  es: "¿Qué equipo necesito para usar IPTV?",
  de: "Welche Ausrüstung benötige ich, um IPTV zu verwenden?",
  it: "Di quale attrezzatura ho bisogno per utilizzare IPTV?",
  tr: "IPTV kullanmak için hangi ekipmana ihtiyacım var?",
  ru: "Какое оборудование мне нужно для использования IPTV?",
  pl: "Jakie wyposażenie jest potrzebne do korzystania z IPTV?",
};
export const equipmentNeededAnswer = {
  en: "To enjoy our IPTV, you don't need any specific or specialized equipment. In fact, you can use a variety of devices that support video streaming players, making it flexible and accessible for everyone. Here are some examples of the devices you can use:",
  fr: "Pour profiter de notre IPTV, vous n'avez besoin d'aucun équipement spécifique ou spécialisé. En fait, vous pouvez utiliser une variété d'appareils qui prennent en charge les lecteurs de diffusion vidéo, ce qui le rend flexible et accessible pour tous. Voici quelques exemples d'appareils que vous pouvez utiliser :",
  es: "Para disfrutar de nuestro IPTV, no necesitas ningún equipo específico o especializado. De hecho, puedes utilizar una variedad de dispositivos que admiten reproductores de transmisión de video, lo que lo hace flexible y accesible para todos. Aquí tienes algunos ejemplos de los dispositivos que puedes usar:",
  de: "Um unser IPTV zu genießen, benötigen Sie keine spezifische oder spezialisierte Ausrüstung. Tatsächlich können Sie eine Vielzahl von Geräten verwenden, die Videostreaming-Player unterstützen, was es flexibel und für jeden zugänglich macht. Hier sind einige Beispiele für die Geräte, die du verwenden kannst:",
  it: "Per goderti il nostro IPTV, non hai bisogno di alcuna attrezzatura specifica o specializzata. In effetti, puoi utilizzare una varietà di dispositivi che supportano i lettori di streaming video, rendendolo flessibile e accessibile per tutti. Ecco alcuni esempi dei dispositivi che puoi utilizzare:",
  tr: "IPTV'mizi keyfini çıkarmak için herhangi bir özel veya uzmanlaşmış ekipmana ihtiyacınız yok. Aslında, herkes için esnek ve erişilebilir olması için video akışı oynatıcılarını destekleyen çeşitli cihazları kullanabilirsiniz. İşte kullanabileceğiniz cihazların bazı örnekleri:",
  ru: "Для наслаждения нашим IPTV вам не нужно никакого специального или специализированного оборудования. Фактически, вы можете использовать различные устройства, поддерживающие видеоплееры для потоковой передачи видео, что делает его гибким и доступным для всех. Вот несколько примеров устройств, которые вы можете использовать:",
  pl: "Aby korzystać z naszego IPTV, nie potrzebujesz żadnego konkretnego ani specjalistycznego sprzętu. Faktycznie, możesz używać różnych urządzeń obsługujących odtwarzacze strumieniowe wideo, co czyni go elastycznym i dostępnym dla wszystkich. Oto kilka przykładów urządzeń, których możesz używać:",
};

export const equipmentNeededAnswerBullet1 = {
  en: "Computers: Whether you have a desktop or laptop computer, you can easily access IPTV services using popular web browsers or dedicated IPTV apps.",
  fr: "Ordinateurs : Que vous disposiez d'un ordinateur de bureau ou d'un ordinateur portable, vous pouvez facilement accéder aux services IPTV en utilisant des navigateurs web populaires ou des applications IPTV dédiées.",
  es: "Computadoras: Ya sea que tengas una computadora de escritorio o una laptop, puedes acceder fácilmente a los servicios de IPTV utilizando navegadores web populares o aplicaciones de IPTV dedicadas.",
  de: "Computer: Egal ob du einen Desktop-Computer oder ein Laptop hast, du kannst ganz einfach auf IPTV-Dienste zugreifen, indem du beliebte Webbrowser oder dedizierte IPTV-Apps verwendest.",
  it: "Computer: Che tu abbia un computer desktop o un portatile, puoi accedere facilmente ai servizi IPTV utilizzando i browser web più popolari o app IPTV dedicate.",
  tr: "Bilgisayarlar: Masaüstü veya dizüstü bilgisayarınız olsun, popüler web tarayıcılarını veya özel IPTV uygulamalarını kullanarak IPTV hizmetlerine kolayca erişebilirsiniz.",
  ru: "Компьютеры: Будь у вас настольный компьютер или ноутбук, вы можете легко получить доступ к IPTV-сервисам с помощью популярных веб-браузеров или специальных IPTV-приложений.",
  pl: "Komputery: Bez względu na to, czy masz komputer stacjonarny czy przenośny, możesz łatwo uzyskać dostęp do usług IPTV za pomocą popularnych przeglądarek internetowych lub dedykowanych aplikacji IPTV.",
};

export const equipmentNeededAnswerBullet2 = {
  en: "TV Sets: Smart TVs are equipped with built-in streaming capabilities, allowing you to directly access IPTV services. If you have a non-smart TV, you can connect an external streaming device (such as a Fire TV Stick, Roku, or Chromecast) to enjoy IPTV.",
  fr: "Téléviseurs : Les téléviseurs intelligents sont équipés de capacités de streaming intégrées, vous permettant d'accéder directement aux services IPTV. Si vous possédez un téléviseur non intelligent, vous pouvez connecter un appareil de streaming externe (comme une clé Fire TV Stick, Roku ou Chromecast) pour profiter de l'IPTV.",
  es: "Televisores: Los televisores inteligentes están equipados con capacidades de transmisión integradas, lo que te permite acceder directamente a los servicios de IPTV. Si tienes un televisor no inteligente, puedes conectar un dispositivo de transmisión externo (como un Fire TV Stick, Roku o Chromecast) para disfrutar de IPTV.",
  de: "Fernsehgeräte: Smart-TVs sind mit integrierten Streaming-Funktionen ausgestattet, die es dir ermöglichen, direkt auf IPTV-Dienste zuzugreifen. Wenn du einen nicht-smarten Fernseher besitzt, kannst du ein externes Streaming-Gerät (wie einen Fire TV Stick, Roku oder Chromecast) anschließen, um IPTV zu genießen.",
  it: "Televisori: Le Smart TV sono dotate di funzionalità di streaming integrate, che ti consentono di accedere direttamente ai servizi IPTV. Se possiedi un televisore non smart, puoi collegare un dispositivo di streaming esterno (come un Fire TV Stick, Roku o Chromecast) per goderti l'IPTV.",
  tr: "TV Setleri: Akıllı TV'ler, dahili akış yetenekleriyle donatılmıştır, böylece IPTV hizmetlerine doğrudan erişebilirsiniz. Eğer akıllı olmayan bir TV'niz varsa, IPTV keyfi için bir Fire TV Stick, Roku veya Chromecast gibi harici bir akış cihazını bağlayabilirsiniz.",
  ru: "Телевизоры: Смарт-телевизоры оснащены встроенными возможностями потокового вещания, позволяющими вам непосредственно получать доступ к IPTV-сервисам. Если у вас есть телевизор без смарт-функций, вы можете подключить внешнее устройство для потокового воспроизведения (например, Fire TV Stick, Roku или Chromecast), чтобы наслаждаться IPTV.",
  pl: "Telewizory: Smart TV są wyposażone w wbudowane możliwości strumieniowania, pozwalając na bezpośredni dostęp do usług IPTV. Jeśli posiadasz telewizor bez smart TV, możesz podłączyć zewnętrzne urządzenie do strumieniowego odtwarzania (takie jak Fire TV Stick, Roku lub Chromecast), aby korzystać z IPTV.",
};

export const equipmentNeededAnswerBullet3 = {
  en: "Gaming Consoles: Modern gaming consoles like PlayStation and Xbox often have IPTV apps available, so you can use them to stream television content on your TV.",
  fr: "Consoles de jeu : Les consoles de jeu modernes comme PlayStation et Xbox ont souvent des applications IPTV disponibles, vous permettant de les utiliser pour diffuser du contenu télévisé sur votre téléviseur.",
  es: "Consolas de juegos: Las consolas de juegos modernas como PlayStation y Xbox suelen tener aplicaciones de IPTV disponibles, por lo que puedes usarlas para transmitir contenido televisivo en tu televisor.",
  de: "Spielekonsolen: Moderne Spielekonsolen wie PlayStation und Xbox verfügen häufig über IPTV-Apps, mit denen du Fernsehinhalte auf deinem Fernseher streamen kannst.",
  it: "Console da gioco: Le console da gioco moderne come PlayStation e Xbox spesso dispongono di app IPTV, quindi puoi usarle per lo streaming di contenuti televisivi sul tuo televisore.",
  tr: "Oyun Konsolları: PlayStation ve Xbox gibi modern oyun konsolları genellikle IPTV uygulamaları sunar, bu nedenle televizyon içeriklerini TV'nizde izlemek için bunları kullanabilirsiniz.",
  ru: "Игровые приставки: Современные игровые приставки, такие как PlayStation и Xbox, часто имеют доступные IPTV-приложения, поэтому вы можете использовать их для потоковой передачи телевизионного контента на своем телевизоре.",
  pl: "Konsolki do gier: Współczesne konsolki do gier, takie jak PlayStation i Xbox, często mają dostępne aplikacje IPTV, dzięki czemu możesz ich używać do streamowania treści telewizyjnych na swoim telewizorze.",
};
export const equipmentNeededAnswerBullet4 = {
  en: "Phones and Tablets: Smartphones and tablets are excellent portable options for IPTV. You can download IPTV apps from app stores and enjoy your favorite shows on the go.",
  fr: "Téléphones et Tablettes : Les smartphones et les tablettes sont d'excellentes options portables pour l'IPTV. Vous pouvez télécharger des applications IPTV depuis les magasins d'applications et profiter de vos émissions préférées en déplacement.",
  es: "Teléfonos y Tablets: Los teléfonos inteligentes y las tabletas son excelentes opciones portátiles para IPTV. Puedes descargar aplicaciones de IPTV desde las tiendas de aplicaciones y disfrutar de tus programas favoritos mientras te desplazas.",
  de: "Telefone und Tablets: Smartphones und Tablets sind hervorragende tragbare Optionen für IPTV. Du kannst IPTV-Apps aus den App-Stores herunterladen und unterwegs deine Lieblingssendungen genießen.",
  it: "Telefoni e Tablet: Gli smartphone e i tablet sono ottime opzioni portatili per l'IPTV. Puoi scaricare le app IPTV dagli store e goderti i tuoi programmi preferiti ovunque tu sia.",
  tr: "Telefonlar ve Tabletler: Akıllı telefonlar ve tabletler, IPTV için mükemmel taşınabilir seçeneklerdir. Uygulama mağazalarından IPTV uygulamalarını indirebilir ve favori programlarınızı yolculukta bile izleyebilirsiniz.",
  ru: "Телефоны и Планшеты: Смартфоны и планшеты являются отличными портативными вариантами для IPTV. Вы можете загружать приложения IPTV из магазинов приложений и наслаждаться просмотром ваших любимых шоу в дороге.",
  pl: "Telefony i Tablety: Smartfony i tablety to doskonałe przenośne urządzenia do oglądania IPTV. Możesz pobierać aplikacje IPTV ze sklepów aplikacji i cieszyć się ulubionymi programami w podróży.",
};
export const equipmentNeededAnswerBullet5 = {
  en: "Other Devices: The versatility of IPTV extends to other devices such as media streaming boxes, streaming sticks, and even some digital media players. The great thing about IPTV is that it supports various video players, and there's no specific requirement for a particular player. As long as your device supports video streaming, you can find compatible apps or use web-based players to access and enjoy IPTV content hassle-free.",
  fr: "Autres Appareils : La polyvalence de l'IPTV s'étend à d'autres appareils tels que les boîtiers de streaming multimédia, les clés de streaming et même certains lecteurs multimédias numériques. Le grand avantage de l'IPTV est qu'il prend en charge différents lecteurs vidéo et qu'il n'y a pas d'exigence spécifique pour un lecteur particulier. Tant que votre appareil prend en charge la diffusion vidéo, vous pouvez trouver des applications compatibles ou utiliser des lecteurs basés sur le Web pour accéder et profiter du contenu IPTV sans problème.",
  es: "Otros Dispositivos: La versatilidad del IPTV se extiende a otros dispositivos como cajas de streaming de medios, sticks de streaming e incluso algunos reproductores de medios digitales. Lo genial del IPTV es que admite varios reproductores de video y no hay un requisito específico para un reproductor en particular. Siempre que tu dispositivo admita la transmisión de video, puedes encontrar aplicaciones compatibles o usar reproductores basados en web para acceder y disfrutar del contenido de IPTV sin problemas.",
  de: "Andere Geräte: Die Vielseitigkeit von IPTV erstreckt sich auf andere Geräte wie Media-Streaming-Boxen, Streaming-Sticks und sogar einige digitale Mediengeräte. Das Tolle an IPTV ist, dass es verschiedene Video-Player unterstützt und keine spezifischen Anforderungen an einen bestimmten Player bestehen. Solange dein Gerät das Video-Streaming unterstützt, kannst du kompatible Apps finden oder webbasierte Player verwenden, um auf IPTV-Inhalte problemlos zuzugreifen und sie zu genießen.",
  it: "Altri Dispositivi: La versatilità dell'IPTV si estende ad altri dispositivi come box per lo streaming multimediale, chiavette per lo streaming e persino alcuni lettori multimediali digitali. La cosa fantastica dell'IPTV è che supporta vari lettori video e non vi è alcun requisito specifico per un lettore particolare. Finché il tuo dispositivo supporta lo streaming video, puoi trovare app compatibili o utilizzare lettori basati sul web per accedere e goderti i contenuti IPTV senza problemi.",
  tr: "Diğer Cihazlar: IPTV'nin çok yönlülüğü, ortam akış kutuları, akış cihazları ve hatta bazı dijital ortam oynatıcılar gibi diğer cihazlara kadar uzanır. IPTV'nin harika bir özelliği, çeşitli video oynatıcıları desteklemesi ve belirli bir oynatıcı için spesifik bir gereklilik olmamasıdır. Cihazınız video akışını desteklediği sürece, uyumlu uygulamaları bulabilir veya IPTV içeriğine sorunsuz erişmek ve keyfini çıkarmak için web tabanlı oynatıcıları kullanabilirsiniz.",
  ru: "Другие устройства: Возможности IPTV распространяются на другие устройства, такие как медиа-стримеры, стриминговые устройства и даже некоторые цифровые медиа-плееры. Замечательное в IPTV состоит в том, что оно поддерживает различные видео-плееры, и нет конкретных требований к определенному плееру. Пока ваше устройство поддерживает видео-стриминг, вы можете найти совместимые приложения или использовать веб-плееры для доступа и комфортного просмотра контента IPTV.",
  pl: "Inne Urządzenia: Elastyczność IPTV obejmuje także inne urządzenia, takie jak pudełka do strumieniowania multimediów, urządzenia do strumieniowania i nawet niektóre odtwarzacze multimedialne. Wspaniałą rzeczą w IPTV jest to, że obsługuje różne odtwarzacze wideo i nie ma konkretnych wymagań dotyczących danego odtwarzacza. Jeśli twoje urządzenie obsługuje strumieniowanie wideo, możesz znaleźć kompatybilne aplikacje lub korzystać z odtwarzaczy internetowych, aby bezproblemowo uzyskać dostęp i cieszyć się treściami IPTV.",
};

export const serviceCost = {
  en: "How Much Does Your IPTV Service Cost?",
  fr: "Combien coûte votre service IPTV ?",
  es: "¿Cuánto cuesta su servicio de IPTV?",
  de: "Wie viel kostet Ihr IPTV-Service?",
  it: "Quanto costa il vostro servizio IPTV?",
  tr: "IPTV hizmetiniz ne kadar tutar?",
  ru: "Сколько стоит ваша услуга IPTV?",
  pl: "Ile kosztuje usługa IPTV?",
};

export const serviceCostAnswer1 = {
  en: "We offer a variety of subscription plans to cater to different needs and preferences. Currently, we have four subscription options available: 1 month, 3 months, 6 months, and 1 year.",
  fr: "Nous proposons une variété de plans d'abonnement pour répondre à différents besoins et préférences. Actuellement, nous avons quatre options d'abonnement disponibles : 1 mois, 3 mois, 6 mois et 1 an.",
  es: "Ofrecemos una variedad de planes de suscripción para satisfacer diferentes necesidades y preferencias. Actualmente, tenemos cuatro opciones de suscripción disponibles: 1 mes, 3 meses, 6 meses y 1 año.",
  de: "Wir bieten eine Vielzahl von Abonnementplänen an, um verschiedenen Bedürfnissen und Vorlieben gerecht zu werden. Derzeit haben wir vier Abonnementoptionen zur Verfügung: 1 Monat, 3 Monate, 6 Monate und 1 Jahr.",
  it: "Offriamo una varietà di piani di abbonamento per soddisfare diverse esigenze e preferenze. Attualmente, abbiamo quattro opzioni di abbonamento disponibili: 1 mese, 3 mesi, 6 mesi e 1 anno.",
  tr: "Farklı ihtiyaçlara ve tercihlere uygun çeşitli abonelik planları sunuyoruz. Şu anda, 1 ay, 3 ay, 6 ay ve 1 yıl olmak üzere dört abonelik seçeneğimiz bulunmaktadır.",
  ru: "Мы предлагаем различные варианты подписки, чтобы удовлетворить разные потребности и предпочтения. В настоящее время у нас есть четыре варианта подписки: 1 месяц, 3 месяца, 6 месяцев и 1 год.",
  pl: "Oferujemy różnorodne plany abonamentowe, aby sprostać różnym potrzebom i preferencjom. Obecnie dostępne są cztery opcje subskrypcji: 1 miesiąc, 3 miesiące, 6 miesięcy i 1 rok.",
};
export const serviceCostAnswer2 = {
  en: "While all our plans provide access to our extensive range of channels and features, we highly recommend the one-year plan. It is our most popular option and offers the best value for money compared to the other plans. By opting for the one-year plan, you not only get uninterrupted access to our IPTV service for an extended period but also enjoy significant cost savings.",
  fr: "Bien que tous nos forfaits donnent accès à notre large gamme de chaînes et de fonctionnalités, nous recommandons vivement le forfait d'un an. C'est notre option la plus populaire et elle offre le meilleur rapport qualité-prix par rapport aux autres forfaits. En optant pour le forfait d'un an, vous bénéficiez non seulement d'un accès ininterrompu à notre service IPTV pendant une période prolongée, mais vous réalisez également des économies de coûts importantes.",
  es: "Si bien todos nuestros planes brindan acceso a nuestra amplia gama de canales y funciones, recomendamos encarecidamente el plan de un año. Es nuestra opción más popular y ofrece la mejor relación calidad-precio en comparación con los otros planes. Al optar por el plan de un año, no solo obtienes acceso ininterrumpido a nuestro servicio de IPTV durante un período prolongado, sino que también disfrutas de significativos ahorros de costos.",
  de: "Obwohl alle unsere Pläne Zugang zu unserem umfangreichen Angebot an Kanälen und Funktionen bieten, empfehlen wir nachdrücklich den Ein-Jahres-Plan. Es ist unsere beliebteste Option und bietet das beste Preis-Leistungs-Verhältnis im Vergleich zu den anderen Plänen. Wenn du dich für den Ein-Jahres-Plan entscheidest, erhältst du nicht nur einen unterbrechungsfreien Zugang zu unserem IPTV-Service für einen längeren Zeitraum, sondern sparst auch erheblich an Kosten.",
  it: "Mentre tutti i nostri piani offrono accesso alla nostra vasta gamma di canali e funzionalità, raccomandiamo vivamente il piano annuale. È la nostra opzione più popolare e offre il miglior rapporto qualità-prezzo rispetto agli altri piani. Optando per il piano annuale, otterrai non solo un accesso ininterrotto al nostro servizio IPTV per un periodo prolungato, ma potrai anche godere di significativi risparmi economici.",
  tr: "Tüm planlarımız geniş kanal ve özellik yelpazemize erişim sağlasa da, bir yıllık planımızı şiddetle öneriyoruz. Bu en popüler seçeneğimizdir ve diğer planlara kıyasla en iyi değeri sunar. Bir yıllık planı tercih ederek, IPTV hizmetimize kesintisiz bir erişim elde etmekle kalmaz, aynı zamanda önemli ölçüde maliyet tasarrufu sağlarsınız.",
  ru: "Все наши планы предоставляют доступ к нашему обширному выбору каналов и функций, но мы настоятельно рекомендуем план на один год. Это наша самая популярная опция и предлагает наилучшее соотношение цены и качества по сравнению с другими планами. Оптимальным выбором является план на один год, который не только обеспечивает беспрерывный доступ к нашей IPTV услуге на продолжительный срок, но и позволяет существенно сэкономить.",
  pl: "Chociaż wszystkie nasze plany umożliwiają dostęp do szerokiej gamy kanałów i funkcji, gorąco polecamy plan na rok. Jest to nasza najpopularniejsza opcja i oferuje najlepszą wartość w porównaniu do innych planów. Wybierając plan na rok, otrzymujesz nie tylko nieprzerwany dostęp do naszej usługi IPTV przez dłuższy okres, ale także korzystasz z znacznych oszczędności finansowych.",
};
export const serviceCostAnswer3 = {
  en: "To view and choose from our subscription plans, please click [here](insert link to subscription plans). We have detailed information about each plan, including pricing, features, and benefits, to help you make an informed decision.",
  fr: "Pour consulter et choisir parmi nos plans d'abonnement, veuillez cliquer [ici](insérer le lien vers les plans d'abonnement). Nous disposons d'informations détaillées sur chaque plan, y compris les tarifs, les fonctionnalités et les avantages, pour vous aider à prendre une décision éclairée.",
  es: "Para ver y elegir entre nuestros planes de suscripción, por favor haz clic [aquí](insertar enlace a los planes de suscripción). Tenemos información detallada sobre cada plan, incluyendo precios, características y beneficios, para ayudarte a tomar una decisión informada.",
  de: "Um unsere Abonnementpläne anzusehen und auszuwählen, klicke bitte [hier](Link zu den Abonnementplänen einfügen). Wir haben detaillierte Informationen zu jedem Plan, einschließlich Preisen, Funktionen und Vorteilen, um dir bei deiner Entscheidung zu helfen.",
  it: "Per visualizzare e scegliere tra i nostri piani di abbonamento, clicca [qui](inserisci il link ai piani di abbonamento). Abbiamo informazioni dettagliate su ogni piano, compresi prezzi, caratteristiche e vantaggi, per aiutarti a prendere una decisione informata.",
  tr: "Abonelik planlarımızı görüntülemek ve seçmek için lütfen [buraya](abonelik planlarına yönlendiren bağlantıyı buraya ekleyin) tıklayın. Her plan hakkında fiyatlandırma, özellikler ve avantajlar gibi detaylı bilgilere sahibiz, böylece bilinçli bir karar verebilmenize yardımcı oluruz.",
  ru: "Чтобы просмотреть и выбрать из наших планов подписки, пожалуйста, нажмите [здесь](вставьте ссылку на планы подписки). У нас есть подробная информация о каждом плане, включая цены, функции и преимущества, чтобы помочь вам принять обоснованное решение.",
  pl: "Aby wyświetlić i wybrać spośród naszych planów abonamentowych, proszę kliknąć [tutaj](wstaw link do planów abonamentowych). Mamy szczegółowe informacje na temat każdego planu, w tym ceny, funkcje i korzyści, aby pomóc Ci podjąć świadomą decyzję.",
};
export const serviceCostAnswer4 = {
  en: "If you have any further questions or need assistance with selecting the right plan for you, please feel free to reach out to our customer support team. We're here to help!",
  fr: "Si vous avez d'autres questions ou avez besoin d'aide pour choisir le bon plan, n'hésitez pas à contacter notre équipe d'assistance clientèle. Nous sommes là pour vous aider !",
  es: "Si tienes alguna otra pregunta o necesitas ayuda para seleccionar el plan adecuado para ti, no dudes en comunicarte con nuestro equipo de atención al cliente. ¡Estamos aquí para ayudarte!",
  de: "Wenn du weitere Fragen hast oder Unterstützung bei der Auswahl des richtigen Plans benötigst, zögere nicht, dich an unser Kundensupport-Team zu wenden. Wir sind hier, um zu helfen!",
  it: "Se hai ulteriori domande o hai bisogno di assistenza nella scelta del piano giusto per te, non esitare a contattare il nostro team di supporto clienti. Siamo qui per aiutarti!",
  tr: "Eğer başka sorularınız varsa veya sizin için doğru planı seçerken yardıma ihtiyacınız varsa, müşteri destek ekibimize başvurmaktan çekinmeyin. Yardımcı olmak için buradayız!",
  ru: "Если у вас возникли дополнительные вопросы или вам нужна помощь в выборе подходящего плана, не стесняйтесь обратиться к нашей команде службы поддержки клиентов. Мы здесь, чтобы помочь!",
  pl: "Jeśli masz jakiekolwiek dodatkowe pytania lub potrzebujesz pomocy w wyborze odpowiedniego planu dla Ciebie, śmiało skontaktuj się z naszym zespołem obsługi klienta. Jesteśmy tutaj, aby pomóc!",
};

export const paymentMethods = {
  en: "What Payment Methods Do You Accept For IPTV Subscriptions?",
  fr: "Quels modes de paiement acceptez-vous pour les abonnements IPTV ?",
  es: "¿Qué métodos de pago aceptan para las suscripciones de IPTV?",
  de: "Welche Zahlungsmethoden akzeptieren Sie für IPTV-Abonnements?",
  it: "Quali metodi di pagamento accettate per gli abbonamenti IPTV?",
  tr: "IPTV abonelikleri için hangi ödeme yöntemlerini kabul ediyorsunuz?",
  ru: "Какие методы оплаты вы принимаете для IPTV-подписок?",
  pl: "Jakie metody płatności akceptujecie dla subskrypcji IPTV?",
};

export const paymentMethodsAnswer = {
  en: "We accept Visa, Mastercard, American Express, and PayPal as payment methods for IPTV subscriptions. These options provide our customers with a variety of convenient and secure ways to complete their purchases. Whether you prefer to use a credit card or opt for the flexibility of PayPal, we strive to accommodate different preferences and ensure a smooth payment experience.",
  fr: "Nous acceptons Visa, Mastercard, American Express et PayPal comme modes de paiement pour les abonnements IPTV. Ces options offrent à nos clients une variété de moyens pratiques et sécurisés pour effectuer leurs achats. Que vous préfériez utiliser une carte de crédit ou opter pour la flexibilité de PayPal, nous nous efforçons de répondre à différentes préférences et de garantir une expérience de paiement fluide.",
  es: "Aceptamos Visa, Mastercard, American Express y PayPal como métodos de pago para las suscripciones de IPTV. Estas opciones ofrecen a nuestros clientes una variedad de formas convenientes y seguras de completar sus compras. Ya sea que prefieras utilizar una tarjeta de crédito o optar por la flexibilidad de PayPal, nos esforzamos por adaptarnos a diferentes preferencias y garantizar una experiencia de pago sin problemas.",
  de: "Wir akzeptieren Visa, Mastercard, American Express und PayPal als Zahlungsmethoden für IPTV-Abonnements. Diese Optionen bieten unseren Kunden eine Vielzahl bequemer und sicherer Möglichkeiten, ihre Einkäufe abzuschließen. Ob du lieber eine Kreditkarte verwendest oder die Flexibilität von PayPal bevorzugst, wir bemühen uns, unterschiedlichen Vorlieben gerecht zu werden und ein reibungsloses Zahlungserlebnis zu gewährleisten.",
  it: "Accettiamo Visa, Mastercard, American Express e PayPal come metodi di pagamento per gli abbonamenti IPTV. Queste opzioni offrono ai nostri clienti una varietà di modi convenienti e sicuri per completare i loro acquisti. Che tu preferisca utilizzare una carta di credito o optare per la flessibilità di PayPal, cerchiamo di soddisfare diverse preferenze e garantire un'esperienza di pagamento senza intoppi.",
  tr: "IPTV abonelikleri için ödeme yöntemi olarak Visa, Mastercard, American Express ve PayPal'ı kabul ediyoruz. Bu seçenekler, müşterilerimize alışverişlerini tamamlamak için çeşitli uygun ve güvenli yollar sunar. Kredi kartı kullanmayı tercih etseniz veya PayPal'ın esnekliğini seçseniz de, farklı tercihlere uyum sağlamaya ve sorunsuz bir ödeme deneyimi sağlamaya çalışıyoruz.",
  ru: "Мы принимаем платежи за подписку на IPTV с помощью карт Visa, Mastercard, American Express и PayPal. Эти варианты предоставляют нашим клиентам разнообразные удобные и безопасные способы завершения покупки. Независимо от того, предпочитаете ли вы использовать кредитную карту или выбираете гибкость PayPal, мы стремимся учитывать разные предпочтения и обеспечить безопасный опыт платежей.",
  pl: "Akceptujemy karty Visa, Mastercard, American Express oraz PayPal jako metody płatności za subskrypcje IPTV. Opcje te zapewniają naszym klientom różnorodne wygodne i bezpieczne sposoby dokonywania zakupów. Bez względu na to, czy wolisz korzystać z karty kredytowej, czy preferujesz elastyczność PayPal, staramy się uwzględnić różne preferencje i zapewnić płynne doświadczenie płatności.",
};

export const freeTrialQuestion = {
  en: "Is There A Free Trial Available For Your IPTV Service?",
  fr: "Proposez-vous un essai gratuit pour votre service IPTV ?",
  es: "¿Hay disponible una prueba gratuita para su servicio de IPTV?",
  de: "Gibt es eine kostenlose Testversion für Ihren IPTV-Service?",
  it: "È disponibile una prova gratuita per il vostro servizio IPTV?",
  tr: "IPTV hizmetiniz için ücretsiz deneme süresi var mı?",
  ru: "Доступна ли бесплатная пробная версия для вашего IPTV-сервиса?",
  pl: "Czy dostępna jest darmowa wersja próbna dla usługi IPTV?",
};

export const freeTrialAnswer = {
  en: "Yes, we offer a trial period of 24 hours for our IPTV service. During this trial, we provide access to a selection of a few channels to allow you to test the quality and performance of our service. If you're interested in obtaining a trial account, we kindly ask you to contact our customer service team via WhatsApp. They will be more than happy to assist you in setting up your trial and answering any questions you may have. We believe that trying our service firsthand is the best way for you to determine if it meets your needs and expectations.",
  fr: "Oui, nous proposons une période d'essai de 24 heures pour notre service IPTV. Pendant cette période d'essai, nous vous donnons accès à une sélection de quelques chaînes pour vous permettre de tester la qualité et les performances de notre service. Si vous souhaitez obtenir un compte d'essai, nous vous invitons à contacter notre service clientèle via WhatsApp. Ils se feront un plaisir de vous aider à configurer votre essai et de répondre à toutes vos questions. Nous pensons que essayer notre service de première main est la meilleure façon pour vous de déterminer s'il répond à vos besoins et attentes.",
  es: "Sí, ofrecemos un período de prueba de 24 horas para nuestro servicio de IPTV. Durante este período de prueba, proporcionamos acceso a una selección de algunos canales para permitirte probar la calidad y el rendimiento de nuestro servicio. Si estás interesado en obtener una cuenta de prueba, te pedimos que te pongas en contacto con nuestro equipo de atención al cliente a través de WhatsApp. Estarán encantados de ayudarte a configurar tu prueba y responder a cualquier pregunta que puedas tener. Creemos que probar nuestro servicio de primera mano es la mejor manera de determinar si cumple con tus necesidades y expectativas.",
  de: "Ja, wir bieten eine Testphase von 24 Stunden für unseren IPTV-Service an. Während dieses Tests bieten wir Zugang zu einer Auswahl von einigen Kanälen, um die Qualität und Leistung unseres Services zu testen. Wenn du an einem Testkonto interessiert bist, bitten wir dich, dich mit unserem Kundenservice-Team über WhatsApp in Verbindung zu setzen. Sie helfen dir gerne bei der Einrichtung deines Tests und beantworten alle Fragen, die du hast. Wir glauben, dass das Ausprobieren unseres Services aus erster Hand der beste Weg ist, um festzustellen, ob er deinen Bedürfnissen und Erwartungen entspricht.",
  it: "Sì, offriamo un periodo di prova di 24 ore per il nostro servizio IPTV. Durante questa prova, forniamo accesso a una selezione di alcuni canali per consentirti di testare la qualità e le prestazioni del nostro servizio. Se sei interessato a ottenere un account di prova, ti chiediamo gentilmente di contattare il nostro servizio clienti tramite WhatsApp. Saranno lieti di assisterti nella configurazione della prova e nel rispondere a eventuali domande che potresti avere. Crediamo che provare il nostro servizio di persona sia il modo migliore per determinare se soddisfa le tue esigenze e aspettative.",
  tr: "Evet, IPTV hizmetimiz için 24 saatlik bir deneme süresi sunuyoruz. Bu deneme süresi boyunca, hizmetimizin kalitesini ve performansını test etmeniz için birkaç kanala erişim sağlıyoruz. Deneme hesabı almak isterseniz, lütfen müşteri hizmetleri ekibimizle WhatsApp üzerinden iletişime geçmenizi rica ederiz. Denemenizi kurmanıza ve sorularınızı yanıtlamanıza memnuniyetle yardımcı olacaklardır. Hizmetimizi bizzat denemenin, ihtiyaçlarınızı ve beklentilerinizi karşılayıp karşılamadığını en iyi şekilde belirlemenin yoludur.",
  ru: "Да, мы предлагаем пробный период в течение 24 часов для нашей IPTV-услуги. В течение этого периода мы предоставляем доступ к выбору нескольких каналов, чтобы вы могли протестировать качество и производительность нашей услуги. Если вас интересует получение пробного аккаунта, мы просим вас связаться с нашей службой поддержки клиентов через WhatsApp. Они будут рады помочь вам настроить пробный период и ответить на все ваши вопросы. Мы считаем, что опыт использования нашей услуги первыми руками является лучшим способом для вас определить, соответствует ли она вашим потребностям и ожиданиям.",
  pl: "Tak, oferujemy okres próbny trwający 24 godziny dla naszej usługi IPTV. W trakcie tego okresu próbnego zapewniamy dostęp do wybranej grupy kilku kanałów, aby umożliwić Ci przetestowanie jakości i wydajności naszej usługi. Jeśli jesteś zainteresowany uzyskaniem konta próbnego, uprzejmie prosimy o kontakt z naszym zespołem obsługi klienta za pośrednictwem WhatsApp. Z przyjemnością pomogą Ci w konfiguracji konta próbnego i odpowiedzą na wszystkie pytania, jakie możesz mieć. Uważamy, że wypróbowanie naszej usługi z pierwszej ręki to najlepszy sposób, aby ocenić, czy spełnia Twoje potrzeby i oczekiwania.",
};

export const internetConnectionQuestion = {
  en: "Do I Need A High-Speed Internet Connection To Use IPTV?",
  fr: "Ai-je besoin d'une connexion internet haut débit pour utiliser l'IPTV ?",
  es: "¿Necesito una conexión a internet de alta velocidad para usar IPTV?",
  de: "Benötige ich eine Hochgeschwindigkeits-Internetverbindung, um IPTV zu nutzen?",
  it: "Ho bisogno di una connessione Internet ad alta velocità per utilizzare l'IPTV?",
  tr: "IPTV kullanmak için yüksek hızlı bir internet bağlantısına ihtiyacım var mı?",
  ru: "Мне нужно высокоскоростное подключение к интернету для использования IPTV?",
  pl: "Czy do korzystania z IPTV potrzebuję szybkiego połączenia internetowego?",
};

export const internetConnectionAnswer1 = {
  en: "To use IPTV, it is recommended to have a minimum internet speed of 4MB/s (equivalent to 400KB/s). However, our study indicates that most European countries have internet connection speeds that meet our IPTV server requirements. This means that you can enjoy watching your TV shows without experiencing significant interruptions or buffering issues.",
  fr: "Pour utiliser l'IPTV, il est recommandé d'avoir une vitesse de connexion internet minimale de 4 Mo/s (équivalent à 400 Ko/s). Cependant, notre étude indique que la plupart des pays européens disposent de vitesses de connexion internet qui répondent aux exigences de notre serveur IPTV. Cela signifie que vous pouvez profiter de vos émissions de télévision sans subir d'interruptions significatives ou de problèmes de mise en mémoire tampon.",
  es: "Para utilizar IPTV, se recomienda tener una velocidad mínima de conexión a internet de 4 MB/s (equivalente a 400 KB/s). Sin embargo, nuestro estudio indica que la mayoría de los países europeos tienen velocidades de conexión a internet que cumplen con los requisitos de nuestro servidor IPTV. Esto significa que puedes disfrutar de tus programas de televisión sin experimentar interrupciones significativas o problemas de almacenamiento en búfer.",
  de: "Um IPTV zu nutzen, wird eine Mindestinternetgeschwindigkeit von 4 MB/s (entspricht 400 KB/s) empfohlen. Unsere Studie zeigt jedoch, dass die meisten europäischen Länder über Internetverbindungsgeschwindigkeiten verfügen, die unseren IPTV-Serveranforderungen entsprechen. Das bedeutet, dass Sie Ihre Fernsehsendungen genießen können, ohne wesentliche Unterbrechungen oder Pufferungsprobleme zu erleben.",
  it: "Per utilizzare l'IPTV, si consiglia di avere una velocità di connessione internet minima di 4 MB/s (equivalenti a 400 KB/s). Tuttavia, il nostro studio indica che la maggior parte dei paesi europei ha velocità di connessione internet che soddisfano i requisiti del nostro server IPTV. Ciò significa che è possibile godersi la visione dei programmi TV senza subire interruzioni significative o problemi di buffering.",
  tr: "IPTV kullanmak için minimum 4 MB/s (400 KB/s'ye eşdeğer) internet hızına sahip olmanız önerilir. Ancak, çalışmamız Avrupa'daki çoğu ülkenin IPTV sunucu gereksinimlerini karşılayan internet bağlantısı hızlarına sahip olduğunu göstermektedir. Bu, TV programlarınızı önemli kesintiler veya arabellek sorunları olmadan izleyebileceğiniz anlamına gelir.",
  ru: "Для использования IPTV рекомендуется иметь минимальную скорость интернет-соединения 4 Мб/с (эквивалентно 400 КБ/с). Однако наше исследование показывает, что большинство европейских стран имеют скорости интернет-соединения, которые соответствуют требованиям нашего сервера IPTV. Это означает, что вы можете наслаждаться просмотром телевизионных программ без значительных перебоев или проблем с буферизацией.",
  pl: "Aby korzystać z IPTV, zaleca się mieć minimalną prędkość połączenia internetowego wynoszącą 4 MB/s (czyli 400 KB/s). Jednak nasze badania wskazują, że większość krajów europejskich ma prędkości połączeń internetowych spełniające wymagania naszego serwera IPTV. Oznacza to, że możesz cieszyć się oglądaniem swoich ulubionych programów telewizyjnych bez większych przeszkód czy problemów z buforowaniem.",
};
export const internetConnectionAnswer2 = {
  en: "Our IPTV service is designed to ensure that you can simultaneously use your internet for other purposes without compromising the performance of either. In other words, our IPTV service will not deplete your internet speed, allowing you to have a seamless experience while using both IPTV and other online activities.",
  fr: "Notre service IPTV est conçu pour vous permettre d'utiliser simultanément votre connexion internet à d'autres fins sans compromettre les performances de l'un ou l'autre. En d'autres termes, notre service IPTV ne réduira pas votre vitesse internet, vous permettant ainsi de profiter d'une expérience fluide tout en utilisant à la fois l'IPTV et d'autres activités en ligne.",
  es: "Nuestro servicio de IPTV está diseñado para asegurarte que puedes usar simultáneamente tu conexión a internet para otros propósitos sin comprometer el rendimiento de ninguno de ellos. En otras palabras, nuestro servicio de IPTV no agotará tu velocidad de internet, permitiéndote tener una experiencia fluida mientras usas tanto el IPTV como otras actividades en línea.",
  de: "Unser IPTV-Service ist so konzipiert, dass du gleichzeitig dein Internet für andere Zwecke nutzen kannst, ohne die Leistung von beiden zu beeinträchtigen. Mit anderen Worten wird unser IPTV-Service deine Internetgeschwindigkeit nicht beeinträchtigen, so dass du eine nahtlose Erfahrung hast, während du sowohl IPTV als auch andere Online-Aktivitäten nutzt.",
  it: "Il nostro servizio IPTV è progettato per garantire che tu possa utilizzare contemporaneamente la tua connessione internet per altri scopi senza compromettere le prestazioni di entrambi. In altre parole, il nostro servizio IPTV non ridurrà la velocità della tua connessione internet, consentendoti di avere un'esperienza fluida mentre utilizzi sia l'IPTV che altre attività online.",
  tr: "IPTV hizmetimiz, her ikisini de etkilemeden aynı anda interneti diğer amaçlar için kullanabilmenizi sağlamak üzere tasarlanmıştır. Başka bir deyişle, IPTV hizmetimiz internet hızınızı düşürmez, böylece hem IPTV hem de diğer çevrimiçi faaliyetlerinizi kullanırken kesintisiz bir deneyim yaşayabilirsiniz.",
  ru: "Наш сервис IPTV разработан таким образом, чтобы вы могли одновременно использовать интернет для других целей, не ухудшая работу ни IPTV, ни других активностей. Другими словами, наш сервис IPTV не снизит скорость вашего интернет-соединения, что позволит вам наслаждаться бесперебойным просмотром IPTV и выполнением других онлайн-задач.",
  pl: "Nasz serwis IPTV został zaprojektowany tak, abyś mógł jednocześnie korzystać z internetu do innych celów, niezależnie od wydajności żadnego z nich. Innymi słowy, nasz serwis IPTV nie wpływa na prędkość twojego internetu, co pozwala na płynne korzystanie zarówno z IPTV, jak i innych czynności online.",
};
export const deviceUsageQuestion = {
  en: "How Many Devices Can I Use With One IPTV Subscription?",
  fr: "Combien d'appareils puis-je utiliser avec un abonnement IPTV ?",
  es: "¿Cuántos dispositivos puedo utilizar con una suscripción de IPTV?",
  de: "Mit wie vielen Geräten kann ich ein IPTV-Abonnement verwenden?",
  it: "Quanti dispositivi posso utilizzare con un abbonamento IPTV?",
  tr: "Bir IPTV aboneliği ile kaç cihaz kullanabilirim?",
  ru: "На скольких устройствах я могу использовать одну подписку IPTV?",
  pl: "Ile urządzeń mogę używać jednocześnie z jedną subskrypcją IPTV?",
};
export const deviceUsageAnswer = {
  en: "With one IPTV subscription purchased from us, you can use it on a single device. If you wish to use it on additional devices, you would need to purchase a separate IPTV account for each device. However, we do offer a discount if you require more than three accounts. To avail of this discount or if you have any other queries regarding device usage, please feel free to contact our support team via WhatsApp. They will be more than happy to assist you and provide further information on multiple account options.",
  fr: "Avec un abonnement IPTV acheté auprès de nous, vous pouvez l'utiliser sur un seul appareil. Si vous souhaitez l'utiliser sur des appareils supplémentaires, vous devrez acheter un compte IPTV séparé pour chaque appareil. Cependant, nous offrons une réduction si vous avez besoin de plus de trois comptes. Pour bénéficier de cette réduction ou si vous avez d'autres questions concernant l'utilisation de plusieurs appareils, n'hésitez pas à contacter notre équipe de support via WhatsApp. Ils se feront un plaisir de vous aider et de vous fournir plus d'informations sur les options de comptes multiples.",
  es: "Con una suscripción IPTV comprada en nuestro servicio, puedes usarla en un solo dispositivo. Si deseas usarla en dispositivos adicionales, deberás comprar una cuenta IPTV separada para cada dispositivo. Sin embargo, ofrecemos un descuento si necesitas más de tres cuentas. Para obtener este descuento o si tienes alguna otra consulta relacionada con el uso de dispositivos, no dudes en contactar a nuestro equipo de soporte a través de WhatsApp. Estarán encantados de ayudarte y brindarte más información sobre las opciones de cuentas múltiples.",
  de: "Mit einem IPTV-Abonnement, das du bei uns kaufst, kannst du es auf einem Gerät verwenden. Wenn du es auf zusätzlichen Geräten nutzen möchtest, musst du für jedes Gerät ein separates IPTV-Konto erwerben. Wir bieten jedoch einen Rabatt an, wenn du mehr als drei Konten benötigst. Um diesen Rabatt in Anspruch zu nehmen oder wenn du weitere Fragen zur Gerätenutzung hast, kontaktiere bitte unser Support-Team über WhatsApp. Sie helfen dir gerne weiter und geben weitere Informationen zu den Optionen für mehrere Konten.",
  it: "Con un abbonamento IPTV acquistato da noi, puoi utilizzarlo su un singolo dispositivo. Se desideri utilizzarlo su dispositivi aggiuntivi, dovrai acquistare un account IPTV separato per ogni dispositivo. Tuttavia, offriamo uno sconto se ne hai bisogno di più di tre. Per usufruire di questo sconto o se hai altre domande riguardanti l'uso dei dispositivi, ti preghiamo di contattare il nostro team di supporto tramite WhatsApp. Saranno lieti di assisterti e fornirti ulteriori informazioni sulle opzioni di account multipli.",
  tr: "Bize ait bir IPTV aboneliği ile bir cihazda kullanabilirsiniz. İlave cihazlarda kullanmak isterseniz, her bir cihaz için ayrı bir IPTV hesabı satın almanız gerekecektir. Ancak üçten fazla hesaba ihtiyacınız varsa indirim sunuyoruz. Bu indirimden yararlanmak veya cihaz kullanımıyla ilgili başka sorularınız varsa, lütfen WhatsApp üzerinden destek ekibimize başvurun. Sizi memnuniyetle yardımcı olacaklar ve çoklu hesap seçenekleri hakkında daha fazla bilgi sunacaklar.",
  ru: "С одной подпиской IPTV, приобретенной у нас, вы можете использовать ее на одном устройстве. Если вы хотите использовать ее на дополнительных устройствах, вам необходимо приобрести отдельную учетную запись IPTV для каждого устройства. Однако мы предлагаем скидку, если вам нужно более трех учетных записей. Чтобы воспользоваться этой скидкой или если у вас есть другие вопросы относительно использования нескольких устройств, не стесняйтесь обратиться в нашу службу поддержки через WhatsApp. Они с радостью помогут вам и предоставят дополнительную информацию о вариантах нескольких учетных записей.",
  pl: "Z jednym subskrypcją IPTV zakupioną od nas możesz jej używać na jednym urządzeniu. Jeśli chcesz używać jej na dodatkowych urządzeniach, musisz zakupić osobne konto IPTV dla każdego urządzenia. Jednak oferujemy zniżkę, jeśli potrzebujesz więcej niż trzy konta. Aby skorzystać z tej zniżki lub jeśli masz inne pytania dotyczące korzystania z wielu urządzeń, skontaktuj się z naszym zespołem wsparcia za pośrednictwem WhatsApp. Chętnie pomogą i udzielą dalszych informacji na temat opcji wielu kont.",
};

export const troubleshootingQuestion = {
  en: "How Do I Troubleshoot Problems With My IPTV Service?",
  fr: "Comment résoudre les problèmes avec mon service IPTV ?",
  es: "¿Cómo solucionar problemas con mi servicio de IPTV?",
  de: "Wie kann ich Probleme mit meinem IPTV-Dienst beheben?",
  it: "Come risolvere i problemi con il mio servizio IPTV?",
  tr: "IPTV Hizmetimdeki Sorunları Nasıl Gideririm?",
  ru: "Как устранить проблемы с моей IPTV-службой?",
  pl: "Jak rozwiązywać problemy z moją usługą IPTV?",
};
export const troubleshootingAnswer1 = {
  en: "If you encounter any issues with our IPTV service, we recommend two primary avenues for troubleshooting. First, you can refer to our set-up page, which provides comprehensive instructions on how to install and configure our IPTV on your specific devices. This resource is designed to guide you through the process and address common troubleshooting scenarios.",
  fr: "Si vous rencontrez des problèmes avec notre service IPTV, nous vous recommandons deux principales méthodes de dépannage. Tout d'abord, vous pouvez consulter notre page de configuration, qui fournit des instructions détaillées sur l'installation et la configuration de notre IPTV sur vos appareils spécifiques. Cette ressource est conçue pour vous guider tout au long du processus et résoudre les problèmes courants.",
  es: "Si encuentras algún problema con nuestro servicio de IPTV, te recomendamos dos vías principales para solucionarlo. En primer lugar, puedes consultar nuestra página de configuración, que proporciona instrucciones completas sobre cómo instalar y configurar nuestro IPTV en tus dispositivos específicos. Este recurso está diseñado para guiarte en el proceso y abordar escenarios comunes de resolución de problemas.",
  de: "Wenn Sie Probleme mit unserem IPTV-Dienst haben, empfehlen wir Ihnen zwei Hauptwege zur Fehlerbehebung. Zunächst können Sie auf unsere Einrichtungsseite verweisen, auf der umfassende Anweisungen zur Installation und Konfiguration unseres IPTV auf Ihren spezifischen Geräten bereitgestellt werden. Diese Ressource wurde entwickelt, um Sie durch den Prozess zu führen und gängige Fehlerbehebungsszenarien anzusprechen.",
  it: "Se riscontri problemi con il nostro servizio IPTV, ti consigliamo due vie principali per la risoluzione dei problemi. In primo luogo, puoi consultare la nostra pagina di configurazione, che fornisce istruzioni dettagliate su come installare e configurare il nostro IPTV sui tuoi dispositivi specifici. Questa risorsa è progettata per guidarti nel processo e affrontare scenari comuni di risoluzione dei problemi.",
  tr: "IPTV hizmetimizle ilgili herhangi bir sorunla karşılaşırsanız, sorun giderme için iki temel yöntem öneriyoruz. İlk olarak, belirli cihazlarınıza IPTV'mizi nasıl kurup yapılandıracağınıza dair kapsamlı talimatlar sağlayan kurulum sayfamıza başvurabilirsiniz. Bu kaynak, süreçte sizi yönlendirmek ve yaygın sorun giderme senaryolarını ele almak için tasarlanmıştır.",
  ru: "Если у вас возникли проблемы с нашей IPTV-службой, мы рекомендуем два основных способа устранения неполадок. В первую очередь, вы можете обратиться к нашей странице настройки, где предоставлены подробные инструкции по установке и настройке нашей IPTV на ваших конкретных устройствах. Этот ресурс предназначен для руководства вами через процесс и устранения распространенных сценариев устранения неполадок.",
  pl: "Jeśli napotkasz jakiekolwiek problemy z naszą usługą IPTV, zalecamy skorzystanie z dwóch głównych metod rozwiązywania problemów. Po pierwsze, możesz skonsultować naszą stronę konfiguracyjną, która zawiera szczegółowe instrukcje dotyczące instalacji i konfiguracji naszego IPTV na Twoich konkretnych urządzeniach. Ten zasób został zaprojektowany w celu przeprowadzenia Cię przez proces i rozwiązania często spotykanych scenariuszy problemów.",
};
export const troubleshootingAnswer2 = {
  en: "Alternatively, you can reach out to our dedicated customer support team via WhatsApp. They are available to provide personalized assistance and help resolve any problems you may be experiencing with your IPTV service. Our support team is well-equipped to handle various technical issues and can offer step-by-step guidance to ensure a smooth and enjoyable IPTV experience.",
  fr: "Alternativement, vous pouvez contacter notre équipe de support client dédiée via WhatsApp. Ils sont disponibles pour fournir une assistance personnalisée et vous aider à résoudre tout problème que vous pourriez rencontrer avec votre service IPTV. Notre équipe de support est bien équipée pour traiter différentes problématiques techniques et peut vous offrir un accompagnement étape par étape afin de garantir une expérience IPTV fluide et agréable.",
  es: "Como alternativa, puedes ponerte en contacto con nuestro equipo de soporte al cliente dedicado a través de WhatsApp. Están disponibles para brindar asistencia personalizada y ayudarte a resolver cualquier problema que puedas estar experimentando con tu servicio de IPTV. Nuestro equipo de soporte está bien preparado para manejar diversos problemas técnicos y puede ofrecerte orientación paso a paso para garantizar una experiencia de IPTV fluida y agradable.",
  de: "Alternativ kannst du dich über WhatsApp an unser engagiertes Kundensupport-Team wenden. Sie stehen zur Verfügung, um persönliche Unterstützung zu bieten und Probleme mit deinem IPTV-Dienst zu lösen. Unser Support-Team ist bestens gerüstet, um verschiedene technische Probleme zu bewältigen und kann schrittweise Anleitung geben, um ein reibungsloses und angenehmes IPTV-Erlebnis zu gewährleisten.",
  it: "In alternativa, puoi contattare il nostro dedicato team di supporto clienti tramite WhatsApp. Sono disponibili per fornire assistenza personalizzata e aiutarti a risolvere eventuali problemi che potresti riscontrare con il tuo servizio IPTV. Il nostro team di supporto è ben attrezzato per gestire varie problematiche tecniche e può offrire una guida passo-passo per assicurare un'esperienza IPTV fluida e piacevole.",
  tr: "Alternatif olarak, WhatsApp üzerinden özel müşteri destek ekibimize başvurabilirsiniz. IPTV hizmetinizle ilgili yaşadığınız herhangi bir sorunu çözmek ve kişiselleştirilmiş yardım sağlamak için hazırlar. Destek ekibimiz çeşitli teknik sorunları ele almak için donanımlıdır ve sorunsuz ve keyifli bir IPTV deneyimi sağlamak için adım adım rehberlik sunabilir.",
  ru: "В качестве альтернативы вы можете обратиться в нашу специализированную службу поддержки клиентов через WhatsApp. Они готовы предоставить персонализированную помощь и помочь решить любые проблемы, с которыми вы можете столкнуться при использовании IPTV-сервиса. Наша служба поддержки хорошо подготовлена к решению различных технических вопросов и может предложить пошаговое руководство, чтобы обеспечить гладкую и приятную работу с IPTV.",
  pl: "Alternatywnie, możesz skontaktować się z naszym dedykowanym zespołem obsługi klienta za pośrednictwem WhatsApp. Są oni dostępni, aby zapewnić spersonalizowaną pomoc i pomóc rozwiązać wszelkie problemy, z którymi możesz się spotkać podczas korzystania z usługi IPTV. Nasz zespół wsparcia jest dobrze wyposażony w rozwiązywanie różnych problemów technicznych i może zapewnić krok po kroku instrukcje, aby zapewnić płynne i przyjemne doświadczenie z IPTV.",
};
export const troubleshootingAnswer3 = {
  en: "Whether you prefer to follow the set-up page or seek direct assistance from our customer support, we are committed to helping you troubleshoot and resolve any concerns you may have with our IPTV service.",
  fr: "Que vous préfériez suivre la page de configuration ou demander une assistance directe à notre support client, nous nous engageons à vous aider à résoudre les problèmes et à résoudre toutes les préoccupations que vous pourriez avoir avec notre service IPTV.",
  es: "Ya sea que prefieras seguir la página de configuración o buscar ayuda directa de nuestro servicio de atención al cliente, nos comprometemos a ayudarte a resolver cualquier problema o inquietud que puedas tener con nuestro servicio de IPTV.",
  de: "Ob du lieber der Einrichtungsseite folgen möchtest oder direkte Unterstützung von unserem Kundensupport suchst, wir sind bestrebt, dir bei der Fehlerbehebung und Behebung von Bedenken im Zusammenhang mit unserem IPTV-Dienst zu helfen.",
  it: "Che tu preferisca seguire la pagina di configurazione o cercare assistenza diretta dal nostro supporto clienti, ci impegniamo ad aiutarti a risolvere qualsiasi problema o preoccupazione che potresti avere con il nostro servizio IPTV.",
  tr: "Kurulum sayfasını takip etmeyi tercih etseniz de, müşteri desteğimizden doğrudan yardım isteseniz de, IPTV hizmetimizle ilgili herhangi bir endişeyi gidermek ve çözmek için size yardımcı olma konusunda kararlıyız.",
  ru: "Независимо от того, предпочитаете ли вы следовать странице настройки или обратиться за прямой помощью к нашей службе поддержки клиентов, мы готовы помочь вам устранить и решить все проблемы, которые могут возникнуть с нашей IPTV-службой.",
  pl: "Niezależnie od tego, czy wolisz postępować zgodnie z stroną konfiguracyjną, czy szukasz bezpośredniej pomocy naszego wsparcia klienta, zobowiązujemy się pomóc ci w rozwiązywaniu i rozwiązywaniu wszelkich problemów związanych z naszą usługą IPTV.",
};
export const customerSupportQuestion = {
  en: "Do You Offer Customer Support For Your IPTV Service?",
  fr: "Offrez-vous un support client pour votre service IPTV ?",
  es: "¿Ofrecen soporte al cliente para su servicio de IPTV?",
  de: "Bieten Sie Kundensupport für Ihren IPTV-Dienst an?",
  it: "Offrite supporto clienti per il vostro servizio IPTV?",
  tr: "IPTV hizmetiniz için müşteri desteği sağlıyor musunuz?",
  ru: "Предоставляете ли вы поддержку клиентам для своей IPTV-службы?",
  pl: "Czy oferujecie wsparcie klienta dla usługi IPTV?",
};
export const customerSupportAnswer1 = {
  en: "Yes, we offer customer support for our IPTV service. If you have any questions, concerns, or need assistance with our service, we are here to help. You have two convenient options to reach out to our support team.",
  fr: "Oui, nous offrons un support client pour notre service IPTV. Si vous avez des questions, des préoccupations ou besoin d'aide concernant notre service, nous sommes là pour vous aider. Vous avez deux options pratiques pour contacter notre équipe de support.",
  es: "Sí, ofrecemos soporte al cliente para nuestro servicio de IPTV. Si tienes alguna pregunta, inquietud o necesitas ayuda con nuestro servicio, estamos aquí para ayudarte. Tienes dos opciones convenientes para comunicarte con nuestro equipo de soporte.",
  de: "Ja, wir bieten Kundensupport für unseren IPTV-Dienst an. Wenn du Fragen, Bedenken hast oder Unterstützung bei unserem Service benötigst, stehen wir dir zur Verfügung. Du hast zwei praktische Möglichkeiten, um mit unserem Support-Team Kontakt aufzunehmen.",
  it: "Sì, offriamo supporto clienti per il nostro servizio IPTV. Se hai domande, preoccupazioni o hai bisogno di assistenza con il nostro servizio, siamo qui per aiutarti. Hai due opzioni comode per contattare il nostro team di supporto.",
  tr: "Evet, IPTV hizmetimiz için müşteri desteği sağlıyoruz. Servisimizle ilgili herhangi bir sorunuz, endişeniz veya yardıma ihtiyacınız varsa, size yardımcı olmak için buradayız. Destek ekibimize başvurmanız için iki uygun seçeneğiniz bulunmaktadır.",
  ru: "Да, мы предоставляем поддержку клиентам для нашей IPTV-службы. Если у вас есть вопросы, проблемы или вам требуется помощь с нашей услугой, мы готовы вам помочь. У вас есть два удобных варианта обратиться в нашу службу поддержки.",
  pl: "Tak, oferujemy wsparcie klienta dla naszej usługi IPTV. Jeśli masz jakiekolwiek pytania, obawy lub potrzebujesz pomocy dotyczącej naszej usługi, jesteśmy tutaj, aby pomóc. Masz dwie wygodne opcje kontaktu z naszym zespołem wsparcia.",
};

export const customerSupportAnswer2 = {
  en: "First, you can send us an email at",
  fr: "Tout d'abord, vous pouvez nous envoyer un e-mail à",
  es: "Primero, puedes enviarnos un correo electrónico a",
  de: "Zunächst kannst du uns eine E-Mail senden an",
  it: "Innanzitutto, puoi inviarci una email a",
  tr: "İlk olarak, bize bir e-posta gönderebilirsiniz:",
  ru: "В первую очередь, вы можете отправить нам письмо на",
  pl: "Najpierw możesz do nas napisać e-mail na",
};
export const customerSupportAnswer3 = {
  en: "support@crystaliptv.co.uk",
  fr: "support@crystaliptv.co.uk",
  es: "support@crystaliptv.co.uk",
  de: "Zsupport@crystaliptv.co.uk",
  it: "support@crystaliptv.co.uk",
  tr: "support@crystaliptv.co.uk",
  ru: "support@crystaliptv.co.uk",
  pl: "support@crystaliptv.co.uk",
};
export const customerSupportAnswer4 = {
  en: "Simply provide us with the details of your query, and our support team will respond to you as soon as possible, addressing your questions and providing the necessary assistance.",
  fr: "Fournissez simplement les détails de votre demande, et notre équipe de support vous répondra dans les meilleurs délais, en traitant vos questions et en fournissant l'assistance nécessaire.",
  es: "Simplemente proporciónanos los detalles de tu consulta y nuestro equipo de soporte te responderá lo antes posible, abordando tus preguntas y brindando la asistencia necesaria.",
  de: "Gib uns einfach die Details zu deiner Anfrage und unser Support-Team wird sich so schnell wie möglich bei dir melden, um deine Fragen zu beantworten und die erforderliche Unterstützung zu bieten.",
  it: "Fornisci semplicemente i dettagli della tua richiesta e il nostro team di supporto ti risponderà al più presto, affrontando le tue domande e fornendo l'assistenza necessaria.",
  tr: "Sadece sorununuzun detaylarını bize sağlayın ve destek ekibimiz, sorularınıza cevap vererek ve gerekli yardımı sağlayarak size en kısa sürede geri dönüş yapacaktır.",
  ru: "Просто предоставьте нам детали вашего запроса, и наша служба поддержки ответит вам как можно скорее, отвечая на ваши вопросы и предоставляя необходимую помощь.",
  pl: "Po prostu podaj nam szczegóły swojego zapytania, a nasz zespół wsparcia odpowie tak szybko, jak to możliwe, zajmując się twoimi pytaniami i zapewniając niezbędną pomoc.",
};
export const customerSupportAnswer5 = {
  en: "Alternatively, you can also send us a message via WhatsApp. Our WhatsApp number is",
  fr: "Alternativement, vous pouvez également nous envoyer un message via WhatsApp. Notre numéro WhatsApp est",
  es: "Alternativamente, también puedes enviarnos un mensaje a través de WhatsApp. Nuestro número de WhatsApp es",
  de: "Alternativ können Sie uns auch eine Nachricht per WhatsApp senden. Unsere WhatsApp-Nummer lautet",
  it: "In alternativa, puoi anche inviarci un messaggio tramite WhatsApp. Il nostro numero di WhatsApp è",
  tr: "Bunun yerine, bize bir mesaj da WhatsApp üzerinden gönderebilirsiniz. WhatsApp numaramız",
  ru: "Также вы можете отправить нам сообщение через WhatsApp. Наш номер WhatsApp",
  pl: "Alternatywnie, możesz również wysłać nam wiadomość za pośrednictwem WhatsApp. Nasz numer WhatsApp to",
};
export const customerSupportAnswer6 = {
  en: "Feel free to reach out to us on WhatsApp, and our support team will be available to assist you promptly.",
  fr: "N'hésitez pas à nous contacter sur WhatsApp, notre équipe de support sera disponible pour vous aider rapidement.",
  es: "No dudes en contactarnos a través de WhatsApp, y nuestro equipo de soporte estará disponible para ayudarte rápidamente.",
  de: "Zögern Sie nicht, uns über WhatsApp zu kontaktieren. Unser Support-Team steht Ihnen umgehend zur Verfügung.",
  it: "Non esitare a contattarci su WhatsApp, il nostro team di supporto sarà disponibile ad assisterti prontamente.",
  tr: "WhatsApp üzerinden bize gönül rahatlığıyla ulaşabilirsiniz, destek ekibimiz size hızlı bir şekilde yardımcı olacaktır.",
  ru: "Не стесняйтесь обратиться к нам в WhatsApp, и наша служба поддержки будет готова помочь вам незамедлительно.",
  pl: "Nie wahaj się skontaktować z nami przez WhatsApp, a nasz zespół wsparcia będzie dostępny, aby szybko ci pomóc.",
};
export const customerSupportAnswer7 = {
  en: "We strive to provide excellent customer support to ensure your IPTV experience is smooth and enjoyable. Whether you have technical inquiries, require troubleshooting assistance, or seek general information, please don't hesitate to contact us. We're here to help you make the most of our IPTV service.",
  fr: "Nous nous efforçons de fournir un excellent service client pour garantir que votre expérience IPTV soit fluide et agréable. Que vous ayez des questions techniques, besoin d'une assistance de dépannage ou recherchiez des informations générales, n'hésitez pas à nous contacter. Nous sommes là pour vous aider à profiter au maximum de notre service IPTV.",
  es: "Nos esforzamos por brindar un excelente soporte al cliente para garantizar que su experiencia de IPTV sea fluida y agradable. Ya sea que tenga consultas técnicas, necesite asistencia para solucionar problemas o busque información general, no dude en contactarnos. Estamos aquí para ayudarlo a aprovechar al máximo nuestro servicio de IPTV.",
  de: "Wir sind bestrebt, exzellenten Kundensupport zu bieten, um sicherzustellen, dass Ihr IPTV-Erlebnis reibungslos und angenehm verläuft. Egal, ob Sie technische Fragen haben, Unterstützung bei der Fehlerbehebung benötigen oder allgemeine Informationen suchen, zögern Sie nicht, uns zu kontaktieren. Wir sind hier, um Ihnen bei der bestmöglichen Nutzung unseres IPTV-Dienstes zu helfen.",
  it: "Ci sforziamo di offrire un eccellente supporto clienti per garantire che la tua esperienza IPTV sia fluida e piacevole. Che tu abbia domande tecniche, necessiti di assistenza per la risoluzione dei problemi o cerchi informazioni generali, non esitare a contattarci. Siamo qui per aiutarti a sfruttare al massimo il nostro servizio IPTV.",
  tr: "IPTV deneyiminizin sorunsuz ve keyifli olmasını sağlamak için mükemmel müşteri desteği sağlamayı hedefliyoruz. Teknik sorularınız, sorun giderme yardımı gereksinimleriniz veya genel bilgi talepleriniz olsun, lütfen bizimle iletişime geçmekten çekinmeyin. IPTV hizmetimizden en iyi şekilde faydalanmanıza yardımcı olmak için buradayız.",
  ru: "Мы стремимся предоставить отличную поддержку клиентов, чтобы обеспечить плавное и приятное использование IPTV. Будь то технические вопросы, помощь в устранении неполадок или общая информация, не стесняйтесь обращаться к нам. Мы здесь, чтобы помочь вам получить максимум от нашей IPTV-услуги.",
  pl: "Dążyliśmy do zapewnienia doskonałej obsługi klienta, aby zapewnić płynne i przyjemne doświadczenie z IPTV. Bez względu na to, czy masz pytania techniczne, potrzebujesz pomocy w rozwiązywaniu problemów, czy szukasz ogólnych informacji, prosimy o kontakt. Jesteśmy tutaj, aby pomóc Ci jak najlepiej korzystać z naszej usługi IPTV.",
};

export const videoQualityQuestion = {
  en: "What Is The Quality Of The Video And Audio On Your IPTV Service?",
  fr: "Quelle est la qualité de la vidéo et de l'audio sur votre service IPTV ?",
  es: "¿Cuál es la calidad del video y el audio en su servicio de IPTV?",
  de: "Wie ist die Qualität von Video und Audio auf Ihrem IPTV-Dienst?",
  it: "Qual è la qualità del video e dell'audio sul vostro servizio IPTV?",
  tr: "IPTV hizmetinizdeki video ve ses kalitesi nedir?",
  ru: "Какое качество видео и аудио в вашей IPTV-службе?",
  pl: "Jaka jest jakość wideo i dźwięku w usłudze IPTV?",
};
export const videoQualityAnswer1 = {
  en: "At our IPTV service, we strive to provide an exceptional viewing experience with high-quality video and audio. We support three major video qualities: SD (Standard Definition), FHD (Full High Definition), and even 4K/UHD (Ultra High Definition). It's important to note that while we offer 4K/UHD capability, 4K channels are relatively rare in the streaming landscape. Only a minority of channels currently stream in 4K resolution.",
  fr: "Sur notre service IPTV, nous nous efforçons de fournir une expérience de visionnage exceptionnelle avec une vidéo et un audio de haute qualité. Nous prenons en charge trois principales qualités vidéo : SD (définition standard), FHD (haute définition complète) et même 4K/UHD (ultra haute définition). Il est important de noter que, bien que nous proposions la compatibilité 4K/UHD, les chaînes en 4K sont relativement rares dans le paysage du streaming. Seule une minorité de chaînes diffusent actuellement en résolution 4K.",
  es: "En nuestro servicio de IPTV, nos esforzamos por proporcionar una experiencia de visualización excepcional con video y audio de alta calidad. Soportamos tres calidades de video principales: SD (definición estándar), FHD (alta definición completa) e incluso 4K/UHD (ultra alta definición). Es importante tener en cuenta que, si bien ofrecemos capacidad 4K/UHD, los canales en 4K son relativamente raros en el panorama de transmisión. Solo una minoría de canales transmiten actualmente en resolución 4K.",
  de: "Bei unserem IPTV-Dienst streben wir danach, ein außergewöhnliches Seherlebnis mit hochwertigem Video und Audio zu bieten. Wir unterstützen drei Hauptvideoqualitäten: SD (Standard Definition), FHD (Full High Definition) und sogar 4K/UHD (Ultra High Definition). Es ist wichtig zu beachten, dass 4K-Kanäle im Streaming-Bereich relativ selten sind, obwohl wir 4K/UHD-Funktionen anbieten. Derzeit senden nur wenige Kanäle in 4K-Auflösung.",
  it: "Presso il nostro servizio IPTV, ci sforziamo di offrire un'esperienza di visione eccezionale con video e audio di alta qualità. Supportiamo tre principali qualità video: SD (definizione standard), FHD (alta definizione completa) e persino 4K/UHD (ultra alta definizione). È importante notare che, sebbene offriamo la compatibilità 4K/UHD, i canali in 4K sono relativamente rari nel panorama dello streaming. Attualmente, solo una minoranza di canali trasmette in risoluzione 4K.",
  tr: "IPTV hizmetimizde, yüksek kaliteli video ve ses ile olağanüstü bir izleme deneyimi sunmaya çalışıyoruz. SD (Standart Tanımlı), FHD (Tam Yüksek Çözünürlük) ve hatta 4K/UHD (Ultra Yüksek Çözünürlük) olmak üzere üç ana video kalitesini destekliyoruz. 4K/UHD yeteneği sunsak da, 4K kanallar akış ortamında nispeten nadirdir. Şu anda sadece kanalların bir azı 4K çözünürlükte yayın yapmaktadır.",
  ru: "В рамках нашей IPTV-службы мы стремимся предоставить исключительный опыт просмотра с высококачественным видео и аудио. Мы поддерживаем три основные качества видео: SD (стандартное разрешение), FHD (полное высокое разрешение) и даже 4K/UHD (ультра высокое разрешение). Важно отметить, что хотя у нас есть возможность 4K/UHD, 4K-каналы относительно редки в стриминговом пространстве. В настоящее время лишь небольшое количество каналов транслируют в разрешении 4K.",
  pl: "W ramach naszej usługi IPTV staramy się zapewnić wyjątkowe wrażenia z oglądania dzięki wysokiej jakości wideo i dźwięku. Obsługujemy trzy główne jakości wideo: SD (Standardowa definicja), FHD (Pełna wysoka definicja) oraz nawet 4K/UHD (Ultra wysoka definicja). Ważne jest zauważenie, że chociaż oferujemy możliwość 4K/UHD, kanały w jakości 4K są stosunkowo rzadkie w krajobrazie strumieniowym. Obecnie tylko mniejszość kanałów nadaje w rozdzielczości 4K.",
};
export const videoQualityAnswer2 = {
  en: "However, when it comes to movies and series, if they were originally released in 4K, our IPTV service ensures that you can enjoy them in their original 4K resolution. This means that if a movie or series was originally produced and released in 4K, you will have access to it in 4K quality through our service. We are committed to providing the best possible video and audio quality to enhance your entertainment experience.",
  fr: "Cependant, en ce qui concerne les films et les séries, si ils ont été initialement diffusés en 4K, notre service IPTV garantit que vous pouvez les apprécier dans leur résolution 4K d'origine. Cela signifie que si un film ou une série a été initialement produit et diffusé en 4K, vous y aurez accès en qualité 4K via notre service. Nous nous engageons à fournir la meilleure qualité vidéo et audio possible pour améliorer votre expérience de divertissement.",
  es: "Sin embargo, cuando se trata de películas y series, si se lanzaron originalmente en 4K, nuestro servicio de IPTV asegura que puedas disfrutarlas en su resolución 4K original. Esto significa que si una película o serie fue producida y lanzada originalmente en 4K, tendrás acceso a ella en calidad 4K a través de nuestro servicio. Nos comprometemos a proporcionar la mejor calidad de video y audio posible para mejorar tu experiencia de entretenimiento.",
  de: "Was Filme und Serien betrifft, sorgt unser IPTV-Dienst dafür, dass du sie in ihrer ursprünglichen 4K-Auflösung genießen kannst, sofern sie ursprünglich in 4K veröffentlicht wurden. Das bedeutet, dass du Zugang zu Filmen oder Serien in 4K-Qualität hast, wenn sie ursprünglich in 4K produziert und veröffentlicht wurden. Wir sind bestrebt, die bestmögliche Video- und Audioqualität bereitzustellen, um dein Unterhaltungserlebnis zu verbessern.",
  it: "Tuttavia, per quanto riguarda i film e le serie, se sono stati originariamente rilasciati in 4K, il nostro servizio IPTV assicura che tu possa goderli nella loro risoluzione 4K originale. Ciò significa che se un film o una serie è stato originariamente prodotto e distribuito in 4K, avrai accesso ad esso in qualità 4K attraverso il nostro servizio. Ci impegniamo a fornire la migliore qualità video e audio possibile per migliorare la tua esperienza di intrattenimento.",
  tr: "Ancak, film ve diziler söz konusu olduğunda, eğer 4K olarak yayınlandılarsa, IPTV hizmetimiz onları orijinal 4K çözünürlükleriyle keyifle izlemenizi sağlar. Bu, bir film veya dizi 4K olarak orijinal olarak üretildiyse, hizmetimiz aracılığıyla ona 4K kalitesinde erişim sağlayacağınız anlamına gelir. Eğlence deneyiminizi artırmak için en iyi video ve ses kalitesini sunma taahhüdündeyiz.",
  ru: "Однако, когда речь идет о фильмах и сериалах, если они были изначально выпущены в формате 4K, наша IPTV-служба обеспечивает возможность наслаждаться ими в оригинальном разрешении 4K. Это означает, что если фильм или сериал были изначально созданы и выпущены в формате 4K, вы сможете просматривать их в качестве 4K через нашу службу. Мы стремимся предоставить лучшее возможное качество видео и аудио для улучшения вашего развлекательного опыта.",
  pl: "Jednak jeśli chodzi o filmy i seriale, jeśli zostały one pierwotnie wydane w jakości 4K, nasza usługa IPTV zapewnia, że będziesz mógł je oglądać w oryginalnej rozdzielczości 4K. Oznacza to, że jeśli film lub serial był pierwotnie produkowany i wydany w jakości 4K, będziesz miał do niego dostęp w jakości 4K dzięki naszej usłudze. Jest dla nas priorytetem zapewnienie jak najlepszej jakości wideo i dźwięku, aby zwiększyć Twoje doznania rozrywkowe.",
};

export const feelFreeToContactUs = {
  en: "Please feel free to contact us if you have any questions!",
  fr: "N'hésitez pas à nous contacter si vous avez des questions !",
  es: "¡No dude en contactarnos si tiene alguna pregunta!",
  de: "Fühlen Sie sich frei, uns zu kontaktieren, wenn Sie Fragen haben!",
  it: "Non esitate a contattarci se avete domande!",
  tr: "Herhangi bir sorunuz varsa lütfen bize ulaşmaktan çekinmeyin!",
  ru: "Пожалуйста, не стесняйтесь связаться с нами, если у вас есть вопросы!",
  pl: "Prosimy o kontakt, jeśli masz jakiekolwiek pytania!",
};
export const WhatDoesCrystalInclude = {
  en: "What does Crystal+ include?",
  fr: "Que comprend Crystal+ ?",
  es: "¿Qué incluye Crystal+?",
  de: "Was beinhaltet Crystal+?",
  it: "Cosa include Crystal+?",
  tr: "Crystal+ neleri içerir?",
  ru: "Что входит в Crystal+?",
  pl: "Co zawiera Crystal+?",
};
export const WhatDoesCrystalIncludeAnswer = {
  en: "Crystal+ offers an impressive range of the world's channels in high quality, from Full HD to 4K. Enjoy a vast collection of top movies and series, including content from Netflix, Disney+, HBO Max, and others. Elevate your entertainment experience with Crystal+.",
  fr: "Crystal+ propose une gamme impressionnante de chaînes du monde entier en haute qualité, du Full HD à la 4K. Profitez d'une vaste collection de films et de séries de premier plan, notamment du contenu de Netflix, Disney+, HBO Max et d'autres. Rehaussez votre expérience de divertissement avec Crystal+.",
  es: "Crystal+ ofrece una impresionante gama de canales de todo el mundo en alta calidad, desde Full HD hasta 4K. Disfruta de una amplia colección de las mejores películas y series, incluido contenido de Netflix, Disney+, HBO Max y otros. Eleva tu experiencia de entretenimiento con Crystal+.",
  de: "Crystal+ bietet eine beeindruckende Auswahl an weltweiten Kanälen in hoher Qualität, von Full HD bis 4K. Genießen Sie eine umfangreiche Sammlung von Top-Filmen und Serien, einschließlich Inhalten von Netflix, Disney+, HBO Max und anderen. Erhöhen Sie Ihr Unterhaltungserlebnis mit Crystal+.",
  it: "Crystal+ offre un'impressionante gamma di canali mondiali in alta qualità, dal Full HD al 4K. Goditi una vasta collezione di film e serie di alto livello, inclusi contenuti da Netflix, Disney+, HBO Max e altri. Eleva la tua esperienza di intrattenimento con Crystal+.",
  tr: "Crystal+, Full HD'den 4K'ya kadar yüksek kalitede dünya kanallarının etkileyici bir yelpazesini sunar. Netflix, Disney+, HBO Max ve diğerlerinden içerik de dahil olmak üzere en iyi filmler ve dizilerin geniş bir koleksiyonunun tadını çıkarın. Eğlence deneyiminizi Crystal+ ile yükseltin.",
  ru: "Crystal+ предлагает впечатляющий ассортимент каналов мира высокого качества, от Full HD до 4K. Наслаждайтесь обширной коллекцией лучших фильмов и сериалов, включая контент от Netflix, Disney+, HBO Max и других. Поднимите свой опыт развлечений с Crystal+.",
  pl: "Crystal+ oferuje imponującą gamę światowych kanałów w wysokiej jakości, od Full HD do 4K. Ciesz się obszerną kolekcją najlepszych filmów i seriali, w tym treściami z serwisów Netflix, Disney+, HBO Max i innych. Podnieś swój poziom rozrywki dzięki Crystal+.",
};
export const howMuchDoesItCost = {
  en: "How much does it cost?",
  fr: "Combien cela coûte-t-il ?",
  es: "¿Cuánto cuesta?",
  de: "Wie viel kostet es?",
  it: "Quanto costa?",
  tr: "Ne kadar maliyeti var?",
  ru: "Сколько это стоит?",
  pl: "Ile to kosztuje?",
};
export const howMuchDoesItCostAnswer = {
  en: "Crystal+ offers flexible pricing options to suit your preferences. You can choose from various plans: €65.99 per year, €39.99 per six months, €20.99 per 3 months, or €09.99 per month. The most cost-effective choice is the yearly plan, providing you with the best value for your entertainment.",
  fr: "Crystal+ propose des options de tarification flexibles pour répondre à vos préférences. Vous pouvez choisir parmi différentes formules : 65,99 € par an, 39,99 € pour six mois, 20,99 € pour 3 mois ou 9,99 € par mois. Le choix le plus rentable est le plan annuel, vous offrant le meilleur rapport qualité-prix pour vos divertissements.",
  es: "Crystal+ ofrece opciones de precios flexibles para adaptarse a tus preferencias. Puedes elegir entre varios planes: 65,99 € por año, 39,99 € por seis meses, 20,99 € por 3 meses o 9,99 € por mes. La opción más rentable es el plan anual, que te brinda el mejor valor para tu entretenimiento.",
  de: "Crystal+ bietet flexible Preisoptionen, um Ihren Vorlieben gerecht zu werden. Sie können aus verschiedenen Plänen wählen: 65,99 € pro Jahr, 39,99 € für sechs Monate, 20,99 € für 3 Monate oder 9,99 € pro Monat. Die kostengünstigste Wahl ist der Jahresplan, der Ihnen das beste Preis-Leistungs-Verhältnis für Ihre Unterhaltung bietet.",
  it: "Crystal+ offre opzioni di prezzo flessibili per soddisfare le tue preferenze. Puoi scegliere tra vari piani: 65,99 € all'anno, 39,99 € per sei mesi, 20,99 € per 3 mesi o 9,99 € al mese. La scelta più conveniente è il piano annuale, che ti offre il miglior valore per il tuo intrattenimento.",
  tr: "Crystal+, tercihlerinize uygun esnek fiyat seçenekleri sunar. Çeşitli planlardan seçim yapabilirsiniz: yıllık 65,99 €, altı aylık 39,99 €, 3 aylık 20,99 € veya aylık 9,99 €. En maliyet etkin seçenek yıllık plan olup size en iyi eğlence değerini sunar.",
  ru: "Crystal+ предлагает гибкую систему ценообразования, которая подходит под ваши предпочтения. Вы можете выбрать из разных планов: 65,99 € в год, 39,99 € за полгода, 20,99 € за 3 месяца или 9,99 € в месяц. Самый выгодный выбор - годовой план, который обеспечивает вам наилучшее соотношение цены и качества для развлечений.",
  pl: "Crystal+ oferuje elastyczne opcje cenowe, aby dostosować się do Twoich preferencji. Możesz wybierać spośród różnych planów: 65,99 € za rok, 39,99 € za pół roku, 20,99 € za 3 miesiące lub 9,99 € za miesiąc. Najbardziej opłacalnym wyborem jest plan roczny, który zapewnia najlepszą wartość dla Twojej rozrywki.",
};
export const devicesToStream = {
  en: "On what devices can I stream Crystal+?",
  fr: "Sur quels appareils puis-je diffuser Crystal+ ?",
  es: "¿En qué dispositivos puedo ver Crystal+?",
  de: "Auf welchen Geräten kann ich Crystal+ streamen?",
  it: "Su quali dispositivi posso guardare Crystal+?",
  tr: "Crystal+’ı hangi cihazlarda izleyebilirim?",
  ru: "На каких устройствах я могу смотреть Crystal+?",
  pl: "Na jakich urządzeniach mogę oglądać Crystal+?",
};
export const devicesToStreamAnswer = {
  en: "Crystal+ offers compatibility across a wide range of devices. Our IPTV services are accessible on smartphones, tablets, TV sets, gaming consoles, and more. You have the freedom to choose your preferred streaming app, ensuring a seamless entertainment experience tailored to your device of choice.",
  fr: "Crystal+ offre une compatibilité avec une large gamme d'appareils. Nos services IPTV sont accessibles sur les smartphones, les tablettes, les téléviseurs, les consoles de jeu et plus encore. Vous avez la liberté de choisir votre application de streaming préférée, garantissant une expérience de divertissement fluide adaptée à l'appareil de votre choix.",
  es: "Crystal+ ofrece compatibilidad en una amplia gama de dispositivos. Nuestros servicios de IPTV son accesibles en teléfonos inteligentes, tabletas, televisores, consolas de juego y más. Tienes la libertad de elegir tu aplicación de streaming preferida, garantizando una experiencia de entretenimiento perfecta adaptada a tu dispositivo favorito.",
  de: "Crystal+ bietet Kompatibilität mit einer breiten Palette von Geräten. Unsere IPTV-Dienste sind auf Smartphones, Tablets, Fernsehern, Spielekonsolen und mehr zugänglich. Sie haben die Freiheit, Ihre bevorzugte Streaming-App zu wählen, um ein nahtloses Unterhaltungserlebnis zu gewährleisten, das auf Ihr gewähltes Gerät zugeschnitten ist.",
  it: "Crystal+ offre compatibilità su una vasta gamma di dispositivi. I nostri servizi IPTV sono accessibili su smartphone, tablet, televisori, console da gioco e altro. Hai la libertà di scegliere la tua app di streaming preferita, garantendo un'esperienza di intrattenimento senza interruzioni adatta al tuo dispositivo preferito.",
  tr: "Crystal+, geniş bir cihaz yelpazesi üzerinde uyumluluk sunar. IPTV hizmetlerimiz akıllı telefonlarda, tabletlerde, televizyonlarda, oyun konsollarında ve daha fazlasında erişilebilir. Tercih ettiğiniz yayın uygulamasını seçme özgürlüğünüz bulunur, böylece tercih ettiğiniz cihaza özelleştirilmiş sorunsuz bir eğlence deneyimi yaşarsınız.",
  ru: "Crystal+ обеспечивает совместимость с широким спектром устройств. Наши IPTV-сервисы доступны на смартфонах, планшетах, телевизорах, игровых консолях и многом другом. Вы имеете свободу выбора предпочитаемого приложения для потоковой передачи, обеспечивая бесперебойный опыт развлечений, подходящий для выбранного вами устройства.",
  pl: "Crystal+ oferuje kompatybilność z szerokim zakresem urządzeń. Nasze usługi IPTV są dostępne na smartfonach, tabletach, telewizorach, konsolach do gier i nie tylko. Masz swobodę wyboru ulubionej aplikacji do streamingu, zapewniając płynne doświadczenie rozrywkowe dostosowane do wybranego przez Ciebie urządzenia.",
};
export const channelsInfo = {
  en: "What are the channels Crystal+ streams?",
  fr: "Quelles sont les chaînes diffusées par Crystal+ ?",
  es: "¿Cuáles son los canales que transmite Crystal+?",
  de: "Welche Kanäle streamt Crystal+?",
  it: "Quali sono i canali che Crystal+ trasmette?",
  tr: "Crystal+ hangi kanalları yayınlıyor?",
  ru: "Какие каналы транслирует Crystal+?",
  pl: "Jakie kanały transmituje Crystal+?",
};
export const channelsInfoAnswer = {
  en: "Crystal+ brings you an extensive array of channels from around the world. We're proud to offer coverage of nearly all the world's channels, spanning various genres and languages. For a comprehensive list of the channels we stream, please take a moment to explore our dedicated channel lineup. Your entertainment choices are diverse and abundant with Crystal+.",
  fr: "Crystal+ vous offre un large éventail de chaînes du monde entier. Nous sommes fiers de couvrir presque toutes les chaînes mondiales, couvrant divers genres et langues. Pour une liste complète des chaînes que nous diffusons, veuillez prendre un moment pour explorer notre programmation dédiée aux chaînes. Vos choix de divertissement sont variés et abondants avec Crystal+.",
  es: "Crystal+ te ofrece una amplia gama de canales de todo el mundo. Estamos orgullosos de ofrecer cobertura de casi todas las cadenas del mundo, abarcando varios géneros e idiomas. Para obtener una lista completa de los canales que transmitimos, tómate un momento para explorar nuestra programación de canales dedicados. Tus opciones de entretenimiento son diversas y abundantes con Crystal+.",
  de: "Crystal+ bringt Ihnen eine umfangreiche Auswahl an Kanälen aus der ganzen Welt. Wir sind stolz darauf, nahezu alle weltweiten Kanäle abzudecken und verschiedene Genres und Sprachen abzudecken. Für eine umfassende Liste der von uns übertragenen Kanäle laden wir Sie ein, unsere dedizierte Kanalübersicht zu erkunden. Ihre Unterhaltungsmöglichkeiten sind vielfältig und reichhaltig mit Crystal+.",
  it: "Crystal+ ti offre un'ampia gamma di canali provenienti da tutto il mondo. Siamo orgogliosi di offrire copertura di quasi tutti i canali del mondo, che spaziano tra vari generi e lingue. Per una lista completa dei canali che trasmettiamo, ti preghiamo di dedicare un momento per esplorare la nostra line-up dedicata ai canali. Le tue scelte di intrattenimento sono diverse e abbondanti con Crystal+.",
  tr: "Crystal+, dünya genelindeki geniş bir kanal yelpazesi sunar. Neredeyse tüm dünya kanallarını kapsamaktan gurur duyuyoruz ve çeşitli türleri ve dilleri kapsıyoruz. Yayınladığımız kanalların kapsamlı bir listesi için lütfen özel kanal programımızı keşfetmek için bir an ayırın. Crystal+ ile eğlence seçenekleriniz çeşitlidir ve bol miktardadır.",
  ru: "Crystal+ предлагает вам обширный спектр каналов со всего мира. Мы гордимся тем, что предлагаем покрытие практически всех мировых каналов, охватывающих разные жанры и языки. Для полного списка каналов, которые мы транслируем, пожалуйста, уделите мгновение, чтобы исследовать нашу специальную линейку каналов. Ваши варианты развлечения разнообразны и обширны с Crystal+.",
  pl: "Crystal+ oferuje szeroki wybór kanałów z całego świata. Jesteśmy dumni z tego, że oferujemy pokrycie niemal wszystkich kanałów na świecie, obejmujących różne gatunki i języki. Dla pełnej listy kanałów, które transmitujemy, proszę poświęcić chwilę na zapoznanie się z naszym dedykowanym zestawieniem kanałów. Twoje wybory rozrywki są różnorodne i obfite z Crystal+.",
};
export const channelsList = {
  en: "Channels List",
  fr: "Liste des chaînes",
  es: "Lista de canales",
  de: "Kanalliste",
  it: "Lista canali",
  tr: "Kanal Listesi",
  ru: "Список каналов",
  pl: "Lista kanałów",
};
export const discountQuestion = {
  en: "Does Crystal+ offer a discount?",
  fr: "Crystal+ propose-t-il une remise ?",
  es: "¿Ofrece Crystal+ un descuento?",
  de: "Bietet Crystal+ einen Rabatt?",
  it: "Offre Crystal+ uno sconto?",
  tr: "Crystal+ indirim sunuyor mu?",
  ru: "Предоставляет ли Crystal+ скидку?",
  pl: "Czy Crystal+ oferuje zniżkę?",
};
export const discountQuestionAnswer = {
  en: "Crystal+ occasionally offers discounts during special events such as Christmas or other occasions. Additionally, upon purchasing a subscription, you will receive a voucher discount that can be applied to your future purchases. Stay tuned for exclusive offers to enhance your entertainment experience with Crystal+.",
  fr: "Crystal+ propose occasionnellement des remises lors d'événements spéciaux tels que Noël ou d'autres occasions. De plus, lors de l'achat d'un abonnement, vous recevrez un bon de réduction qui pourra être appliqué à vos achats futurs. Restez à l'écoute pour des offres exclusives afin d'améliorer votre expérience de divertissement avec Crystal+.",
  es: "Crystal+ ofrece ocasionalmente descuentos durante eventos especiales como Navidad u otras ocasiones. Además, al comprar una suscripción, recibirás un descuento en forma de vale que se puede aplicar a tus compras futuras. Mantente atento a las ofertas exclusivas para mejorar tu experiencia de entretenimiento con Crystal+.",
  de: "Crystal+ bietet gelegentlich Rabatte während besonderer Veranstaltungen wie Weihnachten oder anderen Anlässen an. Zusätzlich erhältst du beim Kauf eines Abonnements einen Gutscheinrabatt, der auf zukünftige Einkäufe angewendet werden kann. Bleib gespannt auf exklusive Angebote, um dein Unterhaltungserlebnis mit Crystal+ zu verbessern.",
  it: "Crystal+ offre occasionalmente sconti durante eventi speciali come Natale o altre occasioni. Inoltre, al momento dell'acquisto di un abbonamento, riceverai uno sconto voucher che potrà essere applicato ai tuoi acquisti futuri. Resta sintonizzato per offerte esclusive per migliorare la tua esperienza di intrattenimento con Crystal+.",
  tr: "Crystal+, Noel gibi özel etkinlikler sırasında zaman zaman indirimler sunar. Ayrıca, bir abonelik satın aldığınızda, gelecekteki alışverişlerinize uygulanabilecek bir indirim kuponu alırsınız. Crystal+ ile eğlence deneyiminizi geliştirmek için özel teklifleri kaçırmayın.",
  ru: "Crystal+ время от времени предлагает скидки во время специальных мероприятий, таких как Рождество или другие случаи. Кроме того, при покупке подписки вы получите скидочный купон, который можно будет применить к будущим покупкам. Следите за эксклюзивными предложениями, чтобы улучшить свой опыт развлечений с Crystal+.",
  pl: "Crystal+ okazjonalnie oferuje zniżki podczas wydarzeń specjalnych, takich jak Boże Narodzenie lub inne okazje. Dodatkowo, po zakupie subskrypcji otrzymasz kupon rabatowy, który można zastosować do przyszłych zakupów. Bądź na bieżąco z ekskluzywnymi ofertami, aby wzmocnić swoje doświadczenie rozrywkowe z Crystal+.",
};

export const helmetDescription = {
  en: "Find answers for the most frequent questions about our IPTV service with Crystal IPTV",
  fr: "Trouvez des réponses aux questions les plus fréquentes sur notre service IPTV avec Crystal IPTV",
  es: "Encuentra respuestas a las preguntas más frecuentes sobre nuestro servicio de IPTV con Crystal IPTV",
  de: "Finden Sie Antworten auf die häufigsten Fragen zu unserem IPTV-Service mit Crystal IPTV",
  it: "Trova risposte alle domande più frequenti sul nostro servizio IPTV con Crystal IPTV",
  tr: "Crystal IPTV ile IPTV hizmetimiz hakkında en sık sorulan soruların cevaplarını bulun",
  ru: "Найдите ответы на самые часто задаваемые вопросы о нашем IPTV-сервисе с Crystal IPTV",
  pl: "Znajdź odpowiedzi na najczęstsze pytania dotyczące naszej usługi IPTV z Crystal IPTV",
};
