import { atom } from "recoil";

const initializeIsAthenticated = () => {
  // INITIALIZE ISAUTHENTICATED ATOM BASED ON THE SAVED ACCESS TOKEN IN THE LOCALSTORAGE
  const localstorageToken = localStorage.getItem("accessToken");
  if (localstorageToken) {
    return true;
  } else return false;
};

export const authenticatedAtom = atom({
  key: "authenticatedAtom",
  default: initializeIsAthenticated(),
});

export const displayLanguageAtom = atom({
  key: "displayLanguages",
  default: "en",
});

// IPTV PREFERENCES ATOMS

export const chosenSubscriptionAtom = atom({
  key: "chosenSubscription",
  default: 12,
});

export const voucherAtom = atom({
  key: "voucher",
  default: "",
});

export const isVoucherValidAtom = atom({
  key: "isVoucherValid",
  default: "N",
});

export const voucherRateAtom = atom({
  key: "voucherRateAtom",
  default: "",
});

export const bundleChannelsSelectionAtom = atom({
  key: "bundleChannelsSelection",
  default: {
    france: false,
    uk: false,
    spain: false,
    germany: false,
    portugal: false,
    switzerland: false,
    austria: false,
    italy: false,
    scotland: false,
    russia: false,
    poland: false,
  },
});

export const bundleVodSelectionAtom = atom({
  key: "bundleVodSelection",
  default: {
    netflix: false,
    hbomax: false,
    disney_plus: false,
    paramount_plus: false,
    apple_tv_plus: false,
    prime_video: false,
  },
});

export const emailWhatsappAtom = atom({
  key: "emailWhatsapp",
  default: {
    email: "",
    whatsapp: "",
  },
});
// PAYMENT ATOMS
export const creditCardInfoAtom = atom({
  key: "creditCardInfo",
  default: {
    cardNumber: "",
    nameOnCard: "",
    expirationDate: "",
    cvv: "",
  },
});
