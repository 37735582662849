import { Link } from "react-router-dom";

// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
// import images
import sport_events from "../../images/home_page/sport_events.webp";
import netflix_shows from "../../images/home_page/netflix_shows.webp";
import disney_shows from "../../images/home_page/disneyplus_shows.webp";
import bbcearth_shows from "../../images/home_page/bcc_earth_show.webp";

// import paragraphs
import * as int from "../../../paragraphs/AllIn.js";

// import CSS
import "../../styles/card_one.css";

function CardOne() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //

  return (
    <div style={{ padding: "40px 10px" }}>
      <h1>{int.weJoinTheBest[displayLanguage]}</h1>
      <div className="card_one_items_wrapper">
        <Link to="sport">
          <div className="category_wrapper">
            <img
              alt={"sport_events"}
              src={sport_events}
              className="category_card"
            />
            <small>{int.findOutMore[displayLanguage]}</small>
          </div>
        </Link>
        <Link to="movies-and-series">
          <div className="category_wrapper">
            <img
              src={netflix_shows}
              alt="netflix_shows"
              className="category_card"
            />
            <small>{int.findOutMore[displayLanguage]}</small>
          </div>
        </Link>
        <Link to="kidz">
          <div className="category_wrapper">
            <img
              src={disney_shows}
              alt="disney_shows"
              className="category_card"
            />
            <small>{int.findOutMore[displayLanguage]}</small>
          </div>
        </Link>
        <Link to="documentaries-page">
          <div className="category_wrapper">
            <img
              src={bbcearth_shows}
              alt="bbcearth_shows"
              className="category_card"
            />
            <small>{int.findOutMore[displayLanguage]}</small>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default CardOne;
