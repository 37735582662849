import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms.js";
import * as int from "../../paragraphs/AllIn.js";
// import channels list as json string
import { tvShows } from "../../paragraphs/channels_list.js";

function createData(id, title, genre, year) {
  return { id, title, genre, year };
}

const VodsList = JSON.parse(tvShows);

const rows = VodsList.map((item, index) => {
  return createData(index, item.title, item.genre, item.year);
});
// DATA GRID
const columns = [
  {
    field: "title",
    headerName: "Title",
    width: 200,
    headerClassName: "channels_list_header",
  },
  {
    field: "genre",
    headerName: "Genre",
    width: 200,
    headerClassName: "channels_list_header",
  },
  {
    field: "year",
    headerName: "Year",
    width: 200,
    headerClassName: "channels_list_header",
  },
];

const VodList = () => {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper animation_wrapper_entrance"
  );
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
  }, []);
  return (
    <>
      <Helmet htmlAttributes={{ lang: displayLanguage }}>
        <title> {int.helmetTitleMoviesSeriesList[displayLanguage]}</title>
        <meta
          name="description"
          content={int.helmetDescriptionMoviesSeriesList[displayLanguage]}
        />
        <meta property="og:title" content="Crystal IPTV" />
        <meta
          property="og:description"
          content="Premium IPTV and VOD subsriptions"
        />
        <meta property="og:url" content="www.crystaliptv.com/channels-list" />
      </Helmet>
      <Box
        sx={{
          width: "100%",
          maxWidth: 600,
          margin: "auto",
          "& .channels_list_header": {
            backgroundColor: "#e0e0e0",
          },
        }}
        className={wrapperClass}
      >
        <h2 className="pricing_page_header">
          {int.moviesAndSeriesList[displayLanguage]}
        </h2>
        <p style={{ textAlign: "center" }}>
          {int.moviesAndSeriesTableDescription[displayLanguage]}
        </p>
        <br />
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            autoPageSize
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 100 },
              },
            }}
          />
        </div>
        <br />
      </Box>
    </>
  );
};

export default VodList;
