import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { displayLanguageAtom, authenticatedAtom } from "../../Atoms";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
// import { GoogleLogin } from "@react-oauth/google";

import "./../styles/signuppage.css";

// Components imports
import Spinner from "../../app_comps/comps/Spinner";

// import toastify notificatons
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Images import
// import google_icon from "../images/google_icon.svg";

// import paragraphs
import * as int from "../../paragraphs/sign_up_paragraphs.js";
function SignUpPage() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const setAuthenticatedAtom = useSetRecoilState(authenticatedAtom);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const fullnameInput = useRef();
  const emailInput = useRef();
  const passwordInput = useRef();
  const confirmPasswordInput = useRef();
  const [wrapperClass, setWrapperClass] = useState(
    "sign_up_wrapper sign_up_wrapper_entrance"
  );
  const submitButton = useRef();
  const navigate = useNavigate();

  // Define error handlers that return JSX, note that these function are handling the GUI onplay,
  // the functionality of the sign up is handled by the submit function
  // define a function that renders [this field is required] when needed after
  // the user hits submit, we pass in the state related to the input and the input referecend to by Ref
  const handleFullnameFieldError = () => {
    if (submitted && fullname === "") {
      // get our hand on the input using useRef and change its id
      fullnameInput.current.id = "error_input";
      return (
        <div className="auth_error prevent_select">
          {int.thisFieldIsRequired[displayLanguage]}
        </div>
      );
    }
  };
  // define a function that renders an error message if the user did not enter an email
  // or entered an invalid email
  const handleEmailFieldError = () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/;
    const emailCheck = email.match(emailRegex);

    //
    if (submitted && email === "") {
      // get our hand on the input using useRef and change its id
      emailInput.current.id = "error_input";
      return (
        <div className="auth_error prevent_select">
          {int.thisFieldIsRequired[displayLanguage]}
        </div>
      );
    } else if (submitted && emailCheck === null) {
      emailInput.current.id = "error_input";
      return (
        <div className="auth_error prevent_select">
          {int.emailIsNotValid[displayLanguage]}
        </div>
      );
    } else if (submitted && emailInUse) {
      return (
        <div className="auth_error prevent_select">
          {int.emailAlreadyInUse[displayLanguage]}
        </div>
      );
    }
  };

  const handlePasswordFieldError = () => {
    if (submitted && password === "") {
      // get our hand on the input using useRef and change its id
      passwordInput.current.id = "error_input";
      return (
        <div className="auth_error prevent_select">
          {int.thisFieldIsRequired[displayLanguage]}
        </div>
      );
    } else if (submitted && password.length < 8) {
      return (
        <div className="auth_error prevent_select">
          {int.passwordNotlong[displayLanguage]}
        </div>
      );
    }
  };

  // define a function that handles confirm passowrd error message
  const handleConfirmPasswordFieldError = () => {
    if (submitted && confirmPassword === "") {
      confirmPasswordInput.current.id = "error_input";
      return (
        <div className="auth_error prevent_select">
          {int.thisFieldIsRequired[displayLanguage]}
        </div>
      );
    } else if (submitted && confirmPassword !== password) {
      return (
        <div className="auth_error prevent_select">
          {int.passwordsMatch[displayLanguage]}
        </div>
      );
    }
  };

  const handleRecaptchaError = () => {
    if (submitted && !recaptcha) {
      return (
        <div className="auth_error prevent_select">
          {int.completeCaptcha[displayLanguage]}
        </div>
      );
    }
  };

  // define submit button click handler
  const handleSubmit = (e) => {
    e.preventDefault();

    // turn on the spinner showing progess
    setSpinning(true);
    // disable the submit btutton
    submitButton.current.disabled = true;
    // we set submitted to "true" to let handleErrorRender know that the use
    // submitted the form
    setSubmitted(true);
    // check if all the fields conditions were satisfied, if so, fire the HTTP request
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/;
    const emailCheck = email.match(emailRegex);

    if (
      fullname !== "" &&
      emailCheck !== null &&
      password.length >= 8 &&
      password === confirmPassword &&
      recaptcha
    ) {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/register/`,
        data: {
          full_name: fullname,
          email: email,
          password: password,
          language: displayLanguage,
        },
      })
        .then((res) => {
          console.log(res.data);
          const accestoken = res.data.token;
          const userInfo = JSON.stringify(res.data);

          // we need to store the token in the localstorage and set the Auth atom to true
          localStorage.setItem("accessToken", accestoken);
          localStorage.setItem("userInfo", userInfo);
          setAuthenticatedAtom(true);

          // redirect the user to the home page
          navigate("/signup_completed");
        })
        .catch((err) => {
          if (err.code === "ERR_NETWORK") {
            toast.error(int.connectionIssue[displayLanguage]);
          }
          // if the request was unscuccessfull we will render the "network error" component
          else if (
            err.response.data.detail === "User with this email already exists"
          ) {
            setEmailInUse(true);
          } // set spinning to false and re-activate the sbumit button
          setSpinning(false);
          submitButton.current.disabled = false;
        });
    } else {
      setSpinning(false);
      submitButton.current.disabled = false;
    }
  };

  const handleCaptchaChange = (e) => {
    setRecaptcha(true);
  };

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("sign_up_wrapper");
  }, []);

  return (
    <div className="page">
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={wrapperClass}>
        <h1>{int.joinUs[displayLanguage]}</h1>
        <p>{int.paragraph1[displayLanguage]}</p>
        <div className="google_oauth_wrapper"></div>

        <form onSubmit={handleSubmit} className="form_wrapper">
          <label htmlFor="fullname">
            {int.fullname[displayLanguage]}
            <small> *</small>
          </label>
          <input
            ref={fullnameInput}
            id="fullname_input"
            type="text"
            value={fullname}
            placeholder={int.enterYourFullname[displayLanguage]}
            autoComplete="name"
            maxLength={30}
            onChange={(e) => {
              setFullname(e.target.value);
              fullnameInput.current.id = "";
            }}
          />
          {handleFullnameFieldError()}
          <label htmlFor="email">
            {int.email[displayLanguage]}
            <small> *</small>
          </label>
          <input
            ref={emailInput}
            id="email_input"
            type="email"
            value={email}
            maxLength={30}
            placeholder={int.enterYourEmail[displayLanguage]}
            onChange={(e) => {
              setEmail(e.target.value);
              emailInput.current.id = "";
              // as long the user change the email input the email in use error must dismount cause
              // we won't know if the newly inputted email is in use or no until after the user
              // send another request and get the response
              setEmailInUse(false);
            }}
          />
          {handleEmailFieldError()}
          <label htmlFor="password">
            {int.password[displayLanguage]} <small>*</small>
          </label>
          <input
            ref={passwordInput}
            id="password_input"
            type="password"
            value={password}
            maxLength={30}
            placeholder={int.enterPassword[displayLanguage]}
            onChange={(e) => {
              setPassword(e.target.value);
              passwordInput.current.id = "";
            }}
          />
          {handlePasswordFieldError()}
          <label htmlFor="confirm_password">
            {int.confirmPassword[displayLanguage]}
            <small> *</small>
          </label>
          <input
            ref={confirmPasswordInput}
            id="confirmPasswordInput"
            type="password"
            value={confirmPassword}
            maxLength={30}
            placeholder={int.confirmPassword[displayLanguage]}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              confirmPasswordInput.current.id = "";
            }}
          />
          {handleConfirmPasswordFieldError()}
          <div className="captcha_box">
            <ReCAPTCHA
              sitekey="6Lc8OUUmAAAAAJpWBpSDWZM_DfXNAIqblRtVjMG8"
              onChange={handleCaptchaChange}
            />
          </div>

          {handleRecaptchaError()}
          <button ref={submitButton} type="submit" className="submit_btn">
            {spinning ? <Spinner size="small" /> : int.submit[displayLanguage]}
          </button>
        </form>
        <div className="redirect">
          <span className="redirect_anchor">
            {int.alredyHaveAnAccount[displayLanguage]}
          </span>
          <Link to="/login">{int.logIn[displayLanguage]}</Link>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
