import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// // import react scroll

// import paragraphs
import * as int from "../../../paragraphs/welcome_bar_paragraphs";
// import images
import whatsapp_logo from "../../images/WhatsApp_logo.svg";
import mailit_icon from "../../images/mailit.svg";
//
function WelcomeBarAuth() {
  const [username, setUsername] = useState("");
  const displayLanguage = useRecoilValue(displayLanguageAtom);

  function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    // get user info from the localstorage
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    try {
      let user_name = userInfo["first_name"];
      user_name = user_name.split(" ")[0];
      user_name = capitalizeFirstLetter(user_name);
      setUsername(user_name);
    } catch {}
  }, []);

  return (
    <div className="welcome_bar_wrapper">
      <div id="hide_on_mobile">
        <p>
          {int.welcome[displayLanguage]} <strong>{username}! </strong>
          {int.sentence[displayLanguage]}
        </p>
      </div>
      <Link to="pricing" className="welcome_bar_chose_your_plan">
        <button className="falvaour_btn">
          {int.chooseYourPlan[displayLanguage]}
        </button>
      </Link>
      <div style={{ display: "inline-flex" }}>
        <a href="https://wa.me/15551234567" target="_blank">
          <button className="whatsapp_btn">
            <img src={whatsapp_logo} alt="whatsapp_logo" />
            <div>
              <p className="contact_us">{int.contactUs[displayLanguage]}</p>
              <p className="whatsapp">WhatsApp</p>
            </div>
          </button>
        </a>
        <a href="mailto:webmaster@example.com" target="_blank">
          <button className="whatsapp_btn">
            <img src={mailit_icon} alt="mailit_icon" />
            <div>
              <p className="contact_us">{int.contactUs[displayLanguage]}</p>
              <p className="whatsapp">Email</p>
            </div>
          </button>
        </a>
      </div>
    </div>
  );
}

export default WelcomeBarAuth;
