import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
// import components
import OrderSummaryDetails from "./OrderSummaryDetails";
// import paragraphs
import * as int from "../../../paragraphs/payment_paragraphs.js";
// import images
import payment_step1 from "../../images/secure_pay/payment_step_1.svg";
import payment_step2 from "../../images/secure_pay/payment_step_2.svg";
import payment_step3 from "../../images/secure_pay/payment_step_3.svg";

// custom styles
const currentStepStyle = {
  fontWeight: "600",
  color: "#221f30",
};

function OrderSummary({ currentStep, stepSetter }) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);

  //

  // set current payement step handler
  const updateCurrentPaymentStep = (step) => {
    stepSetter(step);
  };
  //
  return (
    <div className="order_summary_wrapper">
      <div className="payment_detail_header">
        <h1>{int.orderSummary[displayLanguage]}</h1>
      </div>
      <div className="payment_steps">
        <div
          className="a_payment_step"
          onClick={() => {
            updateCurrentPaymentStep(1);
          }}
        >
          <img src={payment_step1} alt="payment_step1" />
          <div className="a_payment_step_des">
            <span>{int.step[displayLanguage]} 1</span>
            <span style={currentStep == 1 ? currentStepStyle : {}}>
              {int.preferences[displayLanguage]}
            </span>
          </div>
        </div>
        <div
          className="a_payment_step"
          onClick={() => {
            updateCurrentPaymentStep(2);
          }}
        >
          <img src={payment_step2} alt="payment_step2" />
          <div className="a_payment_step_des">
            <span>{int.step[displayLanguage]} 2</span>
            <span style={currentStep == 2 ? currentStepStyle : {}}>
              {int.payment[displayLanguage]}
            </span>
          </div>
        </div>
        <div
          className="a_payment_step"
          onClick={() => {
            updateCurrentPaymentStep(3);
          }}
        >
          <img src={payment_step3} alt="payment_step3" />
          <div className="a_payment_step_des last_a_payment_step_des">
            <span>{int.step[displayLanguage]} 3</span>
            <span style={currentStep == 3 ? currentStepStyle : {}}>
              {int.review[displayLanguage]}
            </span>
          </div>
        </div>
      </div>
      <OrderSummaryDetails />
    </div>
  );
}

export default OrderSummary;
