import { useSetRecoilState } from "recoil";
import { displayLanguageAtom } from "./Atoms";
import { useEffect } from "react";

function Initialization() {
  const setDisplayLanguage = useSetRecoilState(displayLanguageAtom);
  // this component does some intializations and does not return any JSX`

  useEffect(() => {
    // INITIALIZE LANGUAGES PREFERENCE
    // get the user's preffered languages and update the display language atom
    const availableLanguages = ["en", "fr", "es", "de", "it", "tr", "ru", "pl"];
    const preferredLanguage = navigator.language.slice(0, 2);
    const localStorageDisplayLanguage = localStorage.getItem("displayLanguage");

    // check if the localStorage contains a previously selected language if so,
    // we would need to set update the language atom with it, if not we will
    // just set the prefered language in he atom.
    // Check if the localStorage displayLanguage value exists, if so update the displayLanguage atom

    if (localStorageDisplayLanguage) {
      setDisplayLanguage(localStorageDisplayLanguage);
      // if not, we will them use the preferred language to update the displayLanguage atom
    } else if (availableLanguages.includes(preferredLanguage)) {
      setDisplayLanguage(preferredLanguage);
    }

    // Initialize Google Analytics
  }, []);

  return null;
}

export default Initialization;
