// import paragraphs
import * as int from "../../paragraphs/landing_page_paragraphs.js";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms.js";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { Helmet } from "react-helmet";
// import CSS
import "../styles/landing_page.css";
// import components
import LanguageSelection from "../comps/navbar/LanguageSelection";
import LandingPageSection2 from "../comps/landing_page/LandingPageSection2";
import LandingPageSection3 from "../comps/landing_page/LandingPageSection3";
import LandingPageSection4 from "../comps/landing_page/LandingPageSection4";
import LandingPageSection5 from "../comps/landing_page/LandingPageSection5";
import LandingPageFaq from "../comps/landing_page/LandingPageFaq.js";
// import images
import crystal_logo from "../images/crystaliptv_logo.svg";

function LandingPage() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const pricingPlans = useRef(null);
  //

  const scrollTOPlans = () => {
    window.scrollTo({
      top: pricingPlans.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet htmlAttributes={{ lang: displayLanguage }}>
        <title> {int.helmetTitle[displayLanguage]}</title>
        <meta
          name="description"
          content={int.helmetDescription[displayLanguage]}
        />
        <meta property="og:title" content="Crystal IPTV" />
        <meta
          property="og:description"
          content="Premium IPTV and VOD subsriptions"
        />
        <meta property="og:url" content="www.crystaliptv.com/landing" />
      </Helmet>
      <section className="section_1">
        <div className="section_1_envelop">
          <nav className="landing_page_nav">
            <LanguageSelection />
            <Link className="visit_site" to="/">
              {int.visitUs[displayLanguage]}
            </Link>
          </nav>

          <div className="section_1_body">
            <img
              className="crystal_logo"
              src={crystal_logo}
              alt="crystal_logo"
            />
            <h1>{int.endlessChannels[displayLanguage]}</h1>
            <div className="rainbow_box_container">
              <div className="rainbow_bar"></div>
              <div className="rainbow_bar_blur"></div>
              <div className="rainbow_bar_blur blur2"></div>
            </div>
            <p>{int.subscriptionPlans[displayLanguage]}</p>

            <button
              style={{ fontWeight: 400 }}
              className="choose_plan"
              onClick={scrollTOPlans}
            >
              {int.planSelection[displayLanguage]}
            </button>

            <small>
              {int.freeTrial[displayLanguage]}
              <Link
                to="/terms-and-conditions"
                target="_blank"
                style={{ color: "#fff" }}
              >
                <span> {int.termsApply[displayLanguage]}</span>
              </Link>
            </small>
          </div>
        </div>
      </section>
      <section className="section_2">
        <LandingPageSection2 />
      </section>
      <section className="section_3">
        <LandingPageSection3 />
      </section>
      <section className="section_4" ref={pricingPlans}>
        <h1>{int.selectPlan[displayLanguage]}</h1>
        <LandingPageSection4 />
      </section>
      <section className="section5">
        <LandingPageSection5 />
      </section>
      <section>
        <LandingPageFaq />
      </section>
    </>
  );
}

export default LandingPage;
