import { useState } from "react";
import { grey, purple } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// import images
import netflix_logo from "../../images/home_page/logos/Netflix_2015_logo.svg";
import hbo_logo from "../../images/home_page/logos/HBO_Max.svg";
import disneyplus_logo from "../../images/home_page/logos/Disney+_logo.svg";
import primevideo_logo from "../../images/home_page/logos/Amazon_Prime_Video_logo.svg";
import appletv_logo from "../../images/home_page/logos/Apple_TV_Plus_Logo.svg";
// UK CHANNELS
import bbc_logo from "../../images/home_page/logos/BBC_logo_(1997-2021).svg";
import sky_logo from "../../images/home_page/logos/Sky_Group_logo_2020.webp";
import disneychannel_logo from "../../images/home_page/logos/Disney_Channel_2022.svg";
import cnn_logo from "../../images/home_page/logos/CNN.svg";
import itv_logo from "../../images/home_page/logos/ITV_logo_2019.svg";
import dave_logo from "../../images/home_page/logos/Dave.svg";
import discovery_logo from "../../images/home_page/logos/2019_Discovery_logo.svg";
import nationalgeographic_logo from "../../images/home_page/logos/National-Geographic-Logo.svg";
import historychannel_logo from "../../images/home_page/logos/History_Logo.svg";
import curiousity_stream from "../../images/home_page/logos/CuriosityStream.svg";
// // FRANCE CHANNELS
import tf1_logo from "../../images/home_page/logos/TF1_2013.svg";
import canalplus_logo from "../../images/home_page/logos/Logo_Canal+_1995.svg";
import beinsports_logo from "../../images/home_page/logos/bein-sports.svg";
import m6_logo from "../../images/home_page/logos/Logo_M6_(2020,_fond_clair).svg";
import rmc_logo from "../../images/home_page/logos/Logo_RMC_Sport_2018.svg";
import arte_logo from "../../images/home_page/logos/Arte-Logo.svg";
// // SPAIN CHANNELS
import telemundo_logo from "../../images/home_page/logos/Telemundo_2018.svg";
import cnnespana_logo from "../../images/home_page/logos/CNN_en_Espanol_2014.svg";
import antena3_logo from "../../images/home_page/logos/Antena_3_new.svg";
import movistar_logo from "../../images/home_page/logos/logo-movistar.svg";
import daznlaliga_logo from "../../images/home_page/logos/dazn-laliga-negro.svg";
import fox_logo from "../../images/home_page/logos/FOX_International.svg";
// // GERMANY CHANNELS
import zdf_logo from "../../images/home_page/logos/ZDF_logo.svg";
import rtl_logo from "../../images/home_page/logos/RTL_logo.svg";
import dazn_logo from "../../images/home_page/logos/dazn.svg";
import sport1_logo from "../../images/home_page/logos/Sport-1-Logo,_2013.svg";
import wdr_logo from "../../images/home_page/logos/wdr-westdeutscher.svg";
// // ITALY CHANNELS
import rai_logo from "../../images/home_page/logos/Logo_of_RAI_(2016).svg";
import canale5_logo from "../../images/home_page/logos/Canale_5_2018_logo.svg";
import italia1_logo from "../../images/home_page/logos/italia-1.svg";
import dmax_logo from "../../images/home_page/logos/DMAX_Logo_2016.svg";
import la7_logo from "../../images/home_page/logos/la7_logo.svg";
import cartoonito_logo from "../../images/home_page/logos/Cartoonito_logo.svg";
// // TURKEY CHANNELS
import trt_logo from "../../images/home_page/logos/TRT_logo_(2018-).svg";
import kanald_logo from "../../images/home_page/logos/Kanal_D_logo.svg";
import atv_logo from "../../images/home_page/logos/Atv_logo_2010.svg";
import star_logo from "../../images/home_page/logos/Star_Television_logo.svg";
import cn_logo from "../../images/home_page/logos/Cartoon_Network_2010_logo.svg";
// // POLAND CHANNELS
import tvp_logo from "../../images/home_page/logos/TVP_logo.svg";
import polsat_logo from "../../images/home_page/logos/Polsat_2021_gradient.svg";
import tvn_logo from "../../images/home_page/logos/TVN_logo.svg";
import elevensports_logo from "../../images/home_page/logos/ELEVEN_SPORTS.webp";
import animalplanet_logo from "../../images/home_page/logos/Animal_Planet_logo.svg";

// // RUSSIA CHANNELS
import russia1_logo from "../../images/home_page/logos/Russia-1.svg";
import channelsone_logo from "../../images/home_page/logos/channel-one-russia.svg";
import ren_logo from "../../images/home_page/logos/REN_TV_logo_2015.svg";
import matchtv_log from "../../images/home_page/logos/MatchTV_Logo.svg";

function HighlightedChannels(props) {
  const region = props.region;

  //
  const ukChannels = () => {
    if (region == "en") {
      return (
        <>
          <img src={bbc_logo} alt="BBC_logo" />
          <img src={sky_logo} alt="sky_logo" className="hc35" />
          <img
            src={disneychannel_logo}
            alt="disneychannel_logo"
            className="hc25"
          />
          <img src={cnn_logo} alt="cnn_logo" />
          <img src={itv_logo} alt="itv_logo" className="hc25" />
          <img src={dave_logo} alt="dave_logo" />
          <img src={discovery_logo} alt="discovery_logo" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="hc25"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="hc30"
          />
          <img
            src={curiousity_stream}
            alt="curiousity_stream"
            className="hc25"
          />
        </>
      );
    }
  };

  const franceChannels = () => {
    if (region == "fr") {
      return (
        <>
          <img src={tf1_logo} alt="tf1_logo" className="hc25" />
          <img src={canalplus_logo} alt="canalplus_logo" className="hc25" />
          <img src={beinsports_logo} alt="beinsports_logo" />
          <img src={m6_logo} alt="m6_logo" className="hc25" />
          <img src={rmc_logo} alt="rmc_logo" className="hc30" />
          <img src={arte_logo} alt="arte_logo" />
          <img src={discovery_logo} alt="discovery_logo" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="hc25"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="hc30"
          />
        </>
      );
    }
  };

  const spainChannels = () => {
    if (region == "es") {
      return (
        <>
          <img src={telemundo_logo} alt="telemundo_logo" className="hc35" />
          <img src={cnnespana_logo} alt="cnnespana_logo" className="hc35" />
          <img src={antena3_logo} alt="antena3_logo" className="hc35" />
          <img src={movistar_logo} alt="movistar_logo" className="30px" />
          <img src={daznlaliga_logo} alt="daznlaliga_logo" className="hc45" />
          <img src={fox_logo} alt="fox_logo" />
          <img src={discovery_logo} alt="discovery_logo" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="hc25"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="hc30"
          />
          <img
            src={curiousity_stream}
            alt="curiousity_stream"
            className="hc25"
          />
        </>
      );
    }
  };

  const germanyChannels = () => {
    if (region == "de") {
      return (
        <>
          <img src={zdf_logo} alt="zdf_logo" className="hc35" />
          <img src={rtl_logo} alt="rtl_logo" className="hc35" />
          <img src={sky_logo} alt="sky_logo" className="hc35" />
          <img src={dazn_logo} alt="dazn_logo" className="hc35" />
          <img src={sport1_logo} alt="sport1_logo" className="hc25" />
          <img src={wdr_logo} alt="wdr_logo" />
          <img src={arte_logo} alt="arte_logo" />
          <img src={discovery_logo} alt="discovery_logo" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="hc25"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="hc30"
          />
        </>
      );
    }
  };

  const italyChannels = () => {
    if (region == "it") {
      return (
        <>
          <img src={rai_logo} alt="rai_logo" className="hc35" />
          <img src={canale5_logo} alt="canale5_logo" className="hc35" />
          <img src={italia1_logo} alt="italia1_logo" className="hc35" />
          <img src={sky_logo} alt="sky_logo" className="hc35" />
          <img src={dmax_logo} alt="dmax_logo" className="hc30" />
          <img src={la7_logo} alt="la7_logo" className="hc30" />
          <img src={cartoonito_logo} alt="cartoonito_logo" className="hc25" />
          <img src={discovery_logo} alt="discovery_logo" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="hc25"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="hc30"
          />
        </>
      );
    }
  };

  const turkeyChannels = () => {
    if (region == "tr") {
      return (
        <>
          <img src={trt_logo} alt="trt_logo" />
          <img src={kanald_logo} alt="kanald_logo" className="hc25" />
          <img src={atv_logo} alt="atv_logo" className="hc30" />
          <img src={star_logo} alt="star_logo" className="hc30" />
          <img src={beinsports_logo} alt="beinsports_logo" />
          <img src={cn_logo} alt="cn_logo" className="hc30" />
          <img src={discovery_logo} alt="discovery_logo" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="hc25"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="hc30"
          />
        </>
      );
    }
  };

  const polandChannels = () => {
    if (region == "pl") {
      return (
        <>
          <img src={tvp_logo} alt="tvp_logo" className="hc35" />
          <img src={polsat_logo} alt="polsat_logo" className="hc35" />
          <img src={tvn_logo} alt="tvn_logo" className="hc35" />
          <img
            src={elevensports_logo}
            alt="elevensports_logo"
            className="hc25"
          />
          <img src={canalplus_logo} alt="canalplus_logo" className="hc25" />
          <img
            src={disneychannel_logo}
            alt="disneychannel_logo"
            className="hc25"
          />
          <img
            src={animalplanet_logo}
            alt="animalplanet_logo"
            className="hc25"
          />
          <img src={discovery_logo} alt="discovery_logo" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="hc25"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="hc30"
          />
        </>
      );
    }
  };
  const russiaChannels = () => {
    if (region == "ru") {
      return (
        <>
          <img src={russia1_logo} alt="russia1_logo" />
          <img src={channelsone_logo} alt="channelsone_logo" className="hc30" />
          <img src={ren_logo} alt="ren_logo" className="hc35" />
          <img src={matchtv_log} alt="matchtv_log" className="hc25" />
          <img src={discovery_logo} alt="discovery_logo" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="hc25"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="hc30"
          />
        </>
      );
    }
  };

  //
  return (
    <div className="highlighted_channels">
      <img src={netflix_logo} alt="netflix_logo" />
      <img src={hbo_logo} alt="hbo_max_logo" className="hc25" />
      <img src={disneyplus_logo} alt="disney+_logo" className="hc30" />
      <img src={primevideo_logo} alt="amazon_video_prime_logo" />
      <img src={appletv_logo} alt="apple_tv_plus" />
      {/* Channels based on the user's language  */}
      {ukChannels()} {franceChannels()} {spainChannels()} {germanyChannels()}
      {italyChannels()} {turkeyChannels()} {russiaChannels()} {polandChannels()}
    </div>
  );
}

export default HighlightedChannels;
