// import images
import { useEffect, useState } from "react";
import IPTV_preferences_decoration2 from "../../images/secure_pay/IPTV_preferences_decoration2.webp";
import Skeleton from "@mui/material/Skeleton";
import { grey } from "@mui/material/colors";
// Recoil imports
import { useRecoilState, useRecoilValue } from "recoil";
import {
  displayLanguageAtom,
  bundleChannelsSelectionAtom,
  bundleVodSelectionAtom,
} from "../../../Atoms";
// import paragraphs
import * as int from "../../../paragraphs/payment_paragraphs.js";
///

// Bundle selection list
const channelsList = [
  "france",
  "uk",
  "spain",
  "germany",
  "portugal",
  "switzerland",
  "austria",
  "italy",
  "scotland",
  "russia",
  "poland",
  "arabic",
];
const vodList = [
  "netflix",
  "hbomax",
  "disney_plus",
  "paramount_plus",
  "apple_tv_plus",
  "prime_video",
];

const vodProperTitles = {
  netflix: "Netflix",
  hbomax: "HBO Max",
  disney_plus: "Disney+",
  paramount_plus: "Paramount+",
  apple_tv_plus: "Apple TV+",
  prime_video: "Amazon Prime Video",
};
//
function IPTVPreferencesSubscriptions({ setSelected }) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [bundleChannelsSelection, setBundleChannelsSelection] = useRecoilState(
    bundleChannelsSelectionAtom
  );
  const [bundleVodSelection, setBundleVodSelection] = useRecoilState(
    bundleVodSelectionAtom
  );
  const [imgLoaded, setImgLoaded] = useState(false);
  const [wrapperClass, setWrapperClass] = useState(
    "payment_credit_card_wrapper  animation_wrapper_entrance2 animation_wrapper"
  );
  //

  //
  const handleContinue = () => {
    setSelected("email_whatsapp");
  };
  const handleBack = () => {
    setSelected("subscriptions");
  };
  // handle channels selection checkboxes
  const produceChannelsCheckboxes = (list) => {
    return list.map((item) => {
      return (
        <div className="single_check" key={item}>
          <label htmlFor={item} className="bundleSelectionLabel">
            {int[item][displayLanguage]}
          </label>
          <input
            className="bundleSelectionInput"
            type="checkbox"
            name={item}
            id={item}
            checked={bundleChannelsSelection[item]}
            onChange={(e) => {
              const oldBundleChannelsSelection = { ...bundleChannelsSelection };
              oldBundleChannelsSelection[item] =
                !oldBundleChannelsSelection[item];
              setBundleChannelsSelection(oldBundleChannelsSelection);
            }}
          />
        </div>
      );
    });
  };
  const produceVodCheckboxes = (list) => {
    return list.map((item) => {
      return (
        <div className="single_check" key={item}>
          <label htmlFor="">{vodProperTitles[item]}</label>
          <input
            type="checkbox"
            name={item}
            id={item}
            checked={bundleVodSelection[item]}
            onChange={(e) => {
              const oldBundleVodSelection = { ...bundleVodSelection };
              oldBundleVodSelection[item] = !oldBundleVodSelection[item];
              setBundleVodSelection(oldBundleVodSelection);
            }}
          />
        </div>
      );
    });
  };
  //
  useEffect(() => {
    setWrapperClass("payment_credit_card_wrapper  animation_wrapper ");
  }, []);
  return (
    <div className={wrapperClass}>
      <div className="payment_credit_cards_first_half">
        <img
          style={{ display: imgLoaded ? "block" : "none" }}
          src={IPTV_preferences_decoration2}
          alt="IPTV_preferences_decoration"
          onLoad={() => {
            setImgLoaded(true);
          }}
        />
        {!imgLoaded && (
          <div>
            <Skeleton
              variant="rectangular"
              width={170}
              height={341}
              animation="wave"
              sx={{ color: grey[900] }}
            />
          </div>
        )}
      </div>
      <div className="payment_credit_cards_second_half bundle_selection_edit">
        <div className="bundle_selection_instruction">
          <h1>{int.chooseChannelsVODs[displayLanguage]}</h1>
          <p>{int.devicePerformanceMessage[displayLanguage]}</p>
        </div>

        <div className="credit_card_form">
          <div className="channels_selection_wrapper">
            {produceChannelsCheckboxes(channelsList)}
          </div>

          <div className="channels_selection_wrapper">
            {produceVodCheckboxes(vodList)}
          </div>

          <div className="actioning_btns_wrapper">
            <button
              className="validate_btn"
              type="submit"
              onClick={handleContinue}
            >
              {int.continueButton[displayLanguage]}
            </button>
            <button className="validate_btn return_btn" onClick={handleBack}>
              {int.backButton[displayLanguage]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IPTVPreferencesSubscriptions;
