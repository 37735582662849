import { Link, useNavigate, useLocation } from "react-router-dom";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// import css
import "../../styles/footer.css";

// import paragraphs
import * as int from "../../../paragraphs/footer_paragraphs";

// import images
import visa_icon from "../../images/payment_methods/visa_icon.svg";
import mastercard_icon from "../../images/payment_methods/mastercard_icon.svg";
import americaexpress_icon from "../../images/payment_methods/americanexpress_icon.svg";
import paypal_icon from "../../images/payment_methods/paypal_icon.svg";
import amazonpay_icon from "../../images/payment_methods/amzonpay_icon.svg";

// import components
import ContactUSWhatsapp from "../ContactUSWhatsapp";
//
function Footer() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubscribeBtn = () => {
    navigate("/signup");
  };

  if (
    location.pathname != "/account-settings" &&
    location.pathname != "/payment" &&
    location.pathname != "/admin-panel"
  ) {
    return (
      <footer>
        <div className="footer_wrapper">
          <div className="footer_content_box">
            <ul>
              <li>{int.ourService[displayLanguage]}</li>

              <li>{int.vouchers[displayLanguage]}</li>
              <li>
                <Link to="/">{int.howItWorks[displayLanguage]}</Link>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
            </ul>
          </div>
          <div className="footer_content_box">
            <ul>
              <li>{int.support[displayLanguage]}</li>
              <li>{int.contactSupport[displayLanguage]}</li>
              <li>Whatsapp</li>
              <li>{int.email[displayLanguage]}</li>
            </ul>
          </div>
          <div className="footer_content_box">
            <ul>
              <li>{int.ourCompany[displayLanguage]}</li>
              <li>
                <Link to="/terms-and-conditions">
                  {int.termsAndConditions[displayLanguage]}
                </Link>
              </li>
              <li>
                {<Link to="/about-us"> {int.aboutUs[displayLanguage]} </Link>}
              </li>
            </ul>
          </div>
          <div className="footer_content_box">
            <ul>
              <li>{int.supportedPaymentSystems[displayLanguage]}</li>
              <li>
                <div className="payment_methods_wrapper">
                  <img src={visa_icon} alt="visa_icon" />
                  <img src={mastercard_icon} alt="mastercard_icon" />
                  <img src={americaexpress_icon} alt="americaexpress_icon" />
                  <img src={paypal_icon} alt="paypal_icon" />
                  <img src={amazonpay_icon} alt="amazonpay_icon" />
                </div>
                <div id="subscribe_now">
                  {int.subscribeNow[displayLanguage]}
                </div>
                <button
                  className="falvaour_btn"
                  style={{ margin: "0 ", minLength: "40px" }}
                  onClick={handleSubscribeBtn}
                >
                  {int.signUp[displayLanguage]}
                </button>
              </li>
              <ContactUSWhatsapp color={"white"} />
            </ul>
          </div>
        </div>
        <div className="horizontal_rule"></div>
        <div className="copyrights">
          Crystal ITPV © 2023 - {int.allRightsReserved[displayLanguage]}
        </div>
      </footer>
    );
  }
}

export default Footer;
