import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { chosenSubscriptionAtom } from "../../../Atoms";

const PricingCardExecute = ({ periodCode }) => {
  const navigate = useNavigate();
  const setChosenSubsription = useSetRecoilState(chosenSubscriptionAtom);

  useEffect(() => {
    setChosenSubsription(periodCode);
    navigate("/process-order");
  }, [periodCode]);

  //
  return null;
};

export default PricingCardExecute;
