// import images
import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { grey } from "@mui/material/colors";
import credit_cards_decoration from "../../images/secure_pay/credit_cards_decoration.svg";
import master_card_logo from "../../images/secure_pay/master_card_logo.svg";
import visa_logo from "../../images/secure_pay/visa_logo.svg";
import american_express_logo from "../../images/secure_pay/american_express_logo.svg";
import discover_logo from "../../images/secure_pay/discover_logo.webp";
// Recoil imports
import { useRecoilValue, useRecoilState } from "recoil";
import { displayLanguageAtom, creditCardInfoAtom } from "../../../Atoms";
// import paragraphs
import * as int from "../../../paragraphs/payment_paragraphs.js";
///
function PaymentDetailsCreditCard({ stepSetter }) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [creditCardInformation, setCreditCardInformation] =
    useRecoilState(creditCardInfoAtom);
  const [creditCardLogo, setCreditCardLogo] = useState(master_card_logo);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [wrapperClass, setWrapperClass] = useState(
    "payment_credit_card_wrapper animation_wrapper_entrance2 animation_wrapper"
  );

  //handle credit card logo type
  const produceCreditCardLogo = (string) => {
    const char0 = string.charAt(0);
    const char01 = string.slice(0, 2);
    if (char0 == "4") {
      setCreditCardLogo(master_card_logo);
    } else if (["51", "52", "53", "54", "55"].includes(char01)) {
      setCreditCardLogo(visa_logo);
    } else if (["34", "37"].includes(char01)) {
      setCreditCardLogo(american_express_logo);
    } else if (char0 == "6") {
      setCreditCardLogo(discover_logo);
    }
  };

  // Inputs on change handlers
  // this function checks if a number contains digits only
  //   HELPER FUNCTIONS
  function containsOnlyNumbers(str) {
    return /^[\d\s]+$/.test(str);
  }
  function addSpaceAfterFourChars(str) {
    var result = "";

    for (var i = 0; i < str.length; i++) {
      result += str.charAt(i);

      if ((i + 1) % 4 === 0) {
        result += "  ";
      }
    }

    return result.trim();
  }
  //   CHANGE HANDLERS
  const creditCardOnChange = (e) => {
    const inputString = e.target.value;
    const inputStringNoSpaces = inputString.replace(/\s/g, "");

    if (
      (containsOnlyNumbers(inputString) && inputString.length < 23) ||
      inputString == ""
    ) {
      let updatedCreditCardInformation = { ...creditCardInformation };
      updatedCreditCardInformation.cardNumber =
        addSpaceAfterFourChars(inputStringNoSpaces);
      setCreditCardInformation(updatedCreditCardInformation);
    }
    produceCreditCardLogo(inputString);
  };

  const nameOnChange = (e) => {
    const inputString = e.target.value;
    let updatedCreditCardInformation = { ...creditCardInformation };
    updatedCreditCardInformation.nameOnCard = inputString;
    setCreditCardInformation(updatedCreditCardInformation);
  };

  const expirationDateOnChange = (e) => {
    const inputString = e.target.value;
    let updatedCreditCardInformation = { ...creditCardInformation };
    updatedCreditCardInformation.expirationDate = inputString;
    setCreditCardInformation(updatedCreditCardInformation);
  };

  const cvvOnChange = (e) => {
    const cvv = e.target.value;
    if ((cvv.length < 4 && containsOnlyNumbers(cvv)) || cvv == "") {
      let updatedCreditCardInformation = { ...creditCardInformation };
      updatedCreditCardInformation.cvv = cvv;
      setCreditCardInformation(updatedCreditCardInformation);
    }
  };
  //

  useEffect(() => {
    setWrapperClass("payment_credit_card_wrapper animation_wrapper ");
  }, []);
  return (
    <div className={wrapperClass}>
      <div className="payment_credit_cards_first_half">
        <img
          style={{ display: imgLoaded ? "block" : "none" }}
          src={credit_cards_decoration}
          alt="credit_cards_decoration"
          onLoad={() => {
            setImgLoaded(true);
          }}
        />
        {!imgLoaded && (
          <div>
            <Skeleton
              variant="rectangular"
              width={170}
              height={341}
              animation="wave"
              sx={{ color: grey[900] }}
            />
          </div>
        )}
      </div>
      <div className="payment_credit_cards_second_half">
        <form className="credit_card_form">
          <div>
            <label htmlFor="cardNumber">
              {int.creditCard[displayLanguage]}
            </label>
            <div className="input_wrapper">
              <input
                type="text"
                id="cardNumber"
                value={creditCardInformation.cardNumber}
                placeholder="xxxx xxxx xxxx xxxx"
                onChange={(e) => creditCardOnChange(e)}
                required
              />
              <img src={creditCardLogo} alt="master_card_logo" />
            </div>
          </div>

          <div>
            <label htmlFor="cardName">{int.name[displayLanguage]}</label>
            <div className="input_wrapper">
              <input
                type="text"
                id="cardName"
                value={creditCardInformation.nameOnCard}
                placeholder={int.nameOnCard[displayLanguage]}
                onChange={(e) => nameOnChange(e)}
                required
              />
            </div>
          </div>
          <div className="expiration_cvv">
            <div>
              <label htmlFor="expirationDate">
                {int.expirationDate[displayLanguage]}
              </label>
              <div className="input_wrapper">
                <input
                  style={{ padding: "12px 5px" }}
                  type="month"
                  id="expirationDate"
                  value={creditCardInformation.expirationDate}
                  onChange={(e) => {
                    expirationDateOnChange(e);
                  }}
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="cvv">CVV</label>
              <div className="input_wrapper">
                <input
                  type="text"
                  id="cvv"
                  value={creditCardInformation.cvv}
                  placeholder="cvv"
                  onChange={(e) => cvvOnChange(e)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="actioning_btns_wrapper">
            <button className="validate_btn" type="submit">
              {int.continueButton[displayLanguage]}
            </button>
            <button
              className="validate_btn return_btn"
              onClick={() => {
                stepSetter(1);
              }}
            >
              {int.backButton[displayLanguage]}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PaymentDetailsCreditCard;
