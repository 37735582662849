import { useState } from "react";
// import CSS
import "../styles/secure_pay.css";
// import components
import IPTVPreferences from "../comps/payment/IPTVPreferences";
import PaymentDetails from "../comps/payment/PaymentDetails";
import OrderSummary from "../comps/payment/OrderSummary";

//
function PaymentPage() {
  const [currentStep, setCurrentStep] = useState(1);
  //
  return (
    <div className="payment_page_wrapper">
      {currentStep == 1 && (
        <div className="main_half">
          <IPTVPreferences stepSetter={setCurrentStep} />
        </div>
      )}
      {currentStep == 2 && (
        <div className="main_half">
          <PaymentDetails stepSetter={setCurrentStep} />
        </div>
      )}
      <div className="order_summary">
        <OrderSummary currentStep={currentStep} stepSetter={setCurrentStep} />
      </div>
    </div>
  );
}

export default PaymentPage;
