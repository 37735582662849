export const creditCard = {
  en: "Credit Card",
  fr: "Carte de crédit",
  es: "Tarjeta de crédito",
  de: "Kreditkarte",
  it: "Carta di credito",
  tr: "Kredi kartı",
  ru: "Кредитная карта",
  pl: "Karta kredytowa",
};
export const name = {
  en: "Name",
  fr: "Nom",
  es: "Nombre",
  de: "Name",
  it: "Nome",
  tr: "Ad",
  ru: "Имя",
  pl: "Imię",
};
export const expirationDate = {
  en: "Expiration date",
  fr: "Date d'expiration",
  es: "Fecha de vencimiento",
  de: "Ablaufdatum",
  it: "Data di scadenza",
  tr: "Son kullanma tarihi",
  ru: "Срок действия",
  pl: "Data ważności",
};

export const nameOnCard = {
  en: "Name on card",
  fr: "Nom sur la carte",
  es: "Nombre en la tarjeta",
  de: "Name auf der Karte",
  it: "Nome sulla carta",
  tr: "Kart üzerindeki isim",
  ru: "Имя на карте",
  pl: "Imię na karcie",
};

export const continueButton = {
  en: "Continue",
  fr: "Continuer",
  es: "Continuar",
  de: "Weiter",
  it: "Continua",
  tr: "Devam",
  ru: "Продолжить",
  pl: "Kontynuuj",
};
export const backButton = {
  en: "Back",
  fr: "Retour",
  es: "Volver",
  de: "Zurück",
  it: "Indietro",
  tr: "Geri",
  ru: "Назад",
  pl: "Wstecz",
};

export const cardSecure = {
  en: "Card is secure",
  fr: "La carte est sécurisée",
  es: "La tarjeta es segura",
  de: "Die Karte ist sicher",
  it: "La carta è sicura",
  tr: "Kart güvenli",
  ru: "Карта защищена",
  pl: "Karta jest bezpieczna",
};
export const dataProtection = {
  en: "Your data is protected, everything will be private",
  fr: "Vos données sont protégées, tout restera privé",
  es: "Sus datos están protegidos, todo será privado",
  de: "Ihre Daten sind geschützt, alles bleibt privat",
  it: "I tuoi dati sono protetti, tutto rimarrà privato",
  tr: "Verileriniz koruma altındadır, her şey özel kalacaktır",
  ru: "Ваши данные защищены, все останется конфиденциальным",
  pl: "Twoje dane są chronione, wszystko będzie prywatne",
};
export const paymentDetails = {
  en: "Payment details",
  fr: "Détails de paiement",
  es: "Detalles de pago",
  de: "Zahlungsdetails",
  it: "Dettagli di pagamento",
  tr: "Ödeme detayları",
  ru: "Детали платежа",
  pl: "Szczegóły płatności",
};
export const orderSummary = {
  en: "Order summary",
  fr: "Récapitulatif de la commande",
  es: "Resumen del pedido",
  de: "Bestellübersicht",
  it: "Riepilogo dell'ordine",
  tr: "Sipariş özeti",
  ru: "Сводка заказа",
  pl: "Podsumowanie zamówienia",
};
export const step = {
  en: "Step",
  fr: "Étape",
  es: "Paso",
  de: "Schritt",
  it: "Passo",
  tr: "Adım",
  ru: "Шаг",
  pl: "Krok",
};
export const preferences = {
  en: "Preferences",
  fr: "Préférences",
  es: "Preferencias",
  de: "Einstellungen",
  it: "Preferenze",
  tr: "Tercihler",
  ru: "Настройки",
  pl: "Preferencje",
};
export const payment = {
  en: "Payment",
  fr: "Paiement",
  es: "Pago",
  de: "Zahlung",
  it: "Pagamento",
  tr: "Ödeme",
  ru: "Оплата",
  pl: "Płatność",
};
export const review = {
  en: "Review",
  fr: "Revue",
  es: "Revisión",
  de: "Überprüfung",
  it: "Revisione",
  tr: "İnceleme",
  ru: "Обзор",
  pl: "Recenzja",
};
export const mySubscriptions = {
  en: "My subscription",
  fr: "Mes abonnements",
  es: "Mis suscripciones",
  de: "Meine Abonnements",
  it: "Le mie sottoscrizioni",
  tr: "Aboneliklerim",
  ru: "Мои подписки",
  pl: "Moje subskrypcje",
};
export const subscription = {
  en: "Subscription",
  fr: "Abonnement",
  es: "Suscripción",
  de: "Abo",
  it: "Abbonamento",
  tr: "Abonelik",
  ru: "Подписка",
  pl: "Subskrypcja",
};

export const subscriptions = {
  en: "Subscriptions",
  fr: "Abonnements",
  es: "Suscripciones",
  de: "Abonnements",
  it: "Abbonamenti",
  tr: "Abonelikler",
  ru: "Подписки",
  pl: "Subskrypcje",
};
export const bundleSelection = {
  en: "Bundle selection",
  fr: "Sélection du pack",
  es: "Selección del paquete",
  de: "Paket-Auswahl",
  it: "Selezione del bundle",
  tr: "Paket seçimi",
  ru: "Выбор пакета",
  pl: "Wybór pakietu",
};
export const iptvPreferences = {
  en: "IPTV Preferences",
  fr: "Préférences IPTV",
  es: "Preferencias de IPTV",
  de: "IPTV-Einstellungen",
  it: "Preferenze IPTV",
  tr: "IPTV Tercihleri",
  ru: "Настройки IPTV",
  pl: "Preferencje IPTV",
};
export const reset = {
  en: "Reset",
  fr: "Réinitialiser",
  es: "Restablecer",
  de: "Zurücksetzen",
  it: "Ripristina",
  tr: "Sıfırla",
  ru: "Сбросить",
  pl: "Resetuj",
};
export const month = {
  en: "Month",
  fr: "Mois",
  es: "Mes",
  de: "Monat",
  it: "Mese",
  tr: "Ay",
  ru: "Месяц",
  pl: "Miesiąc",
};

export const months = {
  en: "Months",
  fr: "Mois",
  es: "Meses",
  de: "Monate",
  it: "Mesi",
  tr: "Aylar",
  ru: "Месяцы",
  pl: "Miesiące",
};
export const year = {
  en: "Year",
  fr: "Année",
  es: "Año",
  de: "Jahr",
  it: "Anno",
  tr: "Yıl",
  ru: "Год",
  pl: "Rok",
};
export const chooseChannelsVODs = {
  en: "Please choose the desired channels and VODs",
  fr: "Veuillez choisir les chaînes et VODs souhaités",
  es: "Por favor, elija los canales y VODs deseados",
  de: "Bitte wählen Sie gewünschten Kanäle und VODs aus.",
  it: "Si prega di scegliere i canali e i VOD desiderati",
  tr: "Lütfen istenen kanalları ve VOD'ları seçin",
  ru: "Пожалуйста, выберите желаемые каналы и VOD",
  pl: "Proszę wybrać żądane kanały i VODy",
};
export const devicePerformanceMessage = {
  en: "If you're using a low-performance device, please avoid selecting all bundles. Remember, you can change these preferences anytime.",
  fr: "Si vous utilisez un appareil peu performant, veuillez éviter de sélectionner tous les packs. N'oubliez pas que vous pouvez modifier ces préférences à tout moment.",
  es: "Si estás utilizando un dispositivo de baja capacidad, evita seleccionar todos los paquetes. Recuerda que puedes cambiar estas preferencias en cualquier momento.",
  de: "Wenn Sie ein leistungsschwaches Gerät verwenden, vermeiden Sie bitte die Auswahl aller Pakete. Denken Sie daran, dass Sie diese Einstellungen jederzeit ändern können.",
  it: "Se stai utilizzando un dispositivo poco performante, evita di selezionare tutti i bundle. Ricorda che puoi modificare queste preferenze in qualsiasi momento.",
  tr: "Düşük performanslı bir cihaz kullanıyorsanız, lütfen tüm paketleri seçmekten kaçının. Bu tercihleri istediğiniz zaman değiştirebileceğinizi unutmayın.",
  ru: "Если у вас устройство с низкой производительностью, пожалуйста, избегайте выбора всех пакетов. Помните, что вы всегда можете изменить эти настройки.",
  pl: "Jeśli korzystasz z urządzenia o niskiej wydajności, unikaj wybierania wszystkich pakietów. Pamiętaj, że możesz zmieniać te preferencje w dowolnym momencie.",
};
export const france = {
  en: "France channels",
  fr: "Chaînes françaises",
  es: "Canales de Francia",
  de: "Frankreich-Kanäle",
  it: "Canali francesi",
  tr: "Fransa kanalları",
  ru: "Французские каналы",
  pl: "Kanały francuskie",
};
export const uk = {
  en: "UK channels",
  fr: "Chaînes britanniques",
  es: "Canales del Reino Unido",
  de: "UK-Kanäle",
  it: "Canali britannici",
  tr: "İngiltere kanalları",
  ru: "Британские каналы",
  pl: "Kanały brytyjskie",
};
export const spain = {
  en: "Spain channels",
  fr: "Chaînes espagnoles",
  es: "Canales de España",
  de: "Spanische Kanäle",
  it: "Canali spagnoli",
  tr: "İspanya kanalları",
  ru: "Испанские каналы",
  pl: "Kanały hiszpańskie",
};
export const germany = {
  en: "Germany channels",
  fr: "Chaînes allemandes",
  es: "Canales de Alemania",
  de: "Deutsche Kanäle",
  it: "Canali tedeschi",
  tr: "Almanya kanalları",
  ru: "Немецкие каналы",
  pl: "Kanały niemieckie",
};
export const portugal = {
  en: "Portugal channels",
  fr: "Chaînes portugaises",
  es: "Canales de Portugal",
  de: "Portugiesische Kanäle",
  it: "Canali portoghesi",
  tr: "Portekiz kanalları",
  ru: "Португальские каналы",
  pl: "Kanały portugalskie",
};
export const switzerland = {
  en: "Switzerland channels",
  fr: "Chaînes suisses",
  es: "Canales de Suiza",
  de: "Schweizer Kanäle",
  it: "Canali svizzeri",
  tr: "İsviçre kanalları",
  ru: "Швейцарские каналы",
  pl: "Kanały szwajcarskie",
};
export const austria = {
  en: "Austria channels",
  fr: "Chaînes autrichiennes",
  es: "Canales de Austria",
  de: "Österreichische Kanäle",
  it: "Canali austriaci",
  tr: "Avusturya kanalları",
  ru: "Австрийские каналы",
  pl: "Kanały austriackie",
};
export const italy = {
  en: "Italy channels",
  fr: "Chaînes italiennes",
  es: "Canales de Italia",
  de: "Italienische Kanäle",
  it: "Canali italiani",
  tr: "İtalya kanalları",
  ru: "Итальянские каналы",
  pl: "Kanały włoskie",
};
export const scotland = {
  en: "Scotland channels",
  fr: "Chaînes écossaises",
  es: "Canales de Escocia",
  de: "Schottische Kanäle",
  it: "Canali scozzesi",
  tr: "İskoçya kanalları",
  ru: "Шотландские каналы",
  pl: "Kanały szkockie",
};
export const russia = {
  en: "Russia channels",
  fr: "Chaînes russes",
  es: "Canales de Rusia",
  de: "Russische Kanäle",
  it: "Canali russi",
  tr: "Rusya kanalları",
  ru: "Российские каналы",
  pl: "Kanały rosyjskie",
};
export const poland = {
  en: "Poland channels",
  fr: "Chaînes polonaises",
  es: "Canales de Polonia",
  de: "Polnische Kanäle",
  it: "Canali polacchi",
  tr: "Polonya kanalları",
  ru: "Польские каналы",
  pl: "Kanały polskie",
};
export const arabic = {
  en: "Arabic channels",
  fr: "Chaînes arabes",
  es: "Canales árabes",
  de: "Arabische Kanäle",
  it: "Canali arabi",
  tr: "Arap kanalları",
  ru: "Арабские каналы",
  pl: "Kanały arabskie",
};
export const contactInfoPrompt = {
  en: "Please input your email or/and WhatsApp number",
  fr: "Veuillez saisir votre adresse e-mail et/ou votre numéro de WhatsApp",
  es: "Por favor, ingrese su correo electrónico y/o número de WhatsApp",
  de: "Bitte geben Sie Ihre E-Mail-Adresse und/oder Ihre WhatsApp-Nummer ein",
  it: "Si prega di inserire il proprio indirizzo email e/o numero di WhatsApp",
  tr: "Lütfen e-posta adresinizi ve/veya WhatsApp numaranızı girin",
  ru: "Пожалуйста, введите свой адрес электронной почты и/или номер WhatsApp",
  pl: "Proszę podać swój adres e-mail i/lub numer WhatsApp",
};
export const accountDeliveryInstruction = {
  en: "Once your order is placed, you'll receive your unique IPTV username and password, along with a user-friendly guide to help you install and use your IPTV service on different devices.",
  fr: "Une fois votre commande passée, vous recevrez votre nom d'utilisateur et votre mot de passe IPTV unique, ainsi qu'un guide convivial pour vous aider à installer et utiliser votre service IPTV sur différents appareils.",
  es: "Una vez realizada tu compra, recibirás tu nombre de usuario y contraseña únicos para IPTV, junto con una guía fácil de usar para ayudarte a instalar y utilizar el servicio de IPTV en diferentes dispositivos.",
  de: "Nachdem Ihre Bestellung aufgegeben wurde, erhalten Sie Ihren eindeutigen IPTV-Benutzernamen und Ihr Passwort sowie eine benutzerfreundliche Anleitung, die Ihnen bei der Installation und Nutzung Ihres IPTV-Dienstes auf verschiedenen Geräten hilft.",
  it: "Una volta effettuato l'ordine, riceverai il tuo nome utente e password IPTV unici, insieme a una guida facile da usare per aiutarti ad installare e utilizzare il servizio IPTV su diversi dispositivi.",
  tr: "Siparişiniz tamamlandıktan sonra, farklı cihazlarda IPTV hizmetinizi kurmanıza ve kullanmanıza yardımcı olacak kullanıcı dostu bir kılavuz ile birlikte benzersiz IPTV kullanıcı adı ve şifrenizi alacaksınız.",
  ru: "После оформления заказа вы получите свое уникальное имя пользователя и пароль для IPTV, а также понятное руководство, которое поможет вам установить и использовать услугу IPTV на разных устройствах.",
  pl: "Po złożeniu zamówienia otrzymasz swoją unikalną nazwę użytkownika i hasło do IPTV, wraz z przyjaznym poradnikiem, który pomoże Ci zainstalować i korzystać z usługi IPTV na różnych urządzeniach.",
};
export const email = {
  en: "Email",
  fr: "Email",
  es: "Email",
  de: "E-Mail",
  it: "Email",
  tr: "E-posta",
  ru: "Email",
  pl: "Email",
};
export const whatsappNumber = {
  en: "WhatsApp number",
  fr: "Numéro WhatsApp",
  es: "Número de WhatsApp",
  de: "WhatsApp-Nummer",
  it: "Numero WhatsApp",
  tr: "WhatsApp numarası",
  ru: "Номер WhatsApp",
  pl: "Numer WhatsApp",
};
export const crystalIPTVSubscription1Year = {
  en: "Crystal IPTV Subscription (1 year)",
  fr: "Abonnement Crystal IPTV (1 an)",
  es: "Suscripción Crystal IPTV (1 año)",
  de: "Crystal IPTV Abonnement (1 Jahr)",
  it: "Abbonamento Crystal IPTV (1 anno)",
  tr: "Crystal IPTV Aboneliği (1 yıl)",
  ru: "Подписка на Crystal IPTV (1 год)",
  pl: "Subskrypcja Crystal IPTV (1 rok)",
};
export const crystalIPTVSubscription6Months = {
  en: "Crystal IPTV Subscription (6 months)",
  fr: "Abonnement Crystal IPTV (6 mois)",
  es: "Suscripción Crystal IPTV (6 meses)",
  de: "Crystal IPTV Abonnement (6 Monate)",
  it: "Abbonamento Crystal IPTV (6 mesi)",
  tr: "Crystal IPTV Aboneliği (6 ay)",
  ru: "Подписка на Crystal IPTV (6 месяцев)",
  pl: "Subskrypcja Crystal IPTV (6 miesięcy)",
};

export const crystalIPTVSubscription3Months = {
  en: "Crystal IPTV Subscription (3 months)",
  fr: "Abonnement Crystal IPTV (3 mois)",
  es: "Suscripción Crystal IPTV (3 meses)",
  de: "Crystal IPTV Abonnement (3 Monate)",
  it: "Abbonamento Crystal IPTV (3 mesi)",
  tr: "Crystal IPTV Aboneliği (3 ay)",
  ru: "Подписка на Crystal IPTV (3 месяца)",
  pl: "Subskrypcja Crystal IPTV (3 miesiące)",
};
export const crystalIPTVSubscription1Month = {
  en: "Crystal IPTV Subscription (1 month)",
  fr: "Abonnement Crystal IPTV (1 mois)",
  es: "Suscripción Crystal IPTV (1 mes)",
  de: "Crystal IPTV Abonnement (1 Monat)",
  it: "Abbonamento Crystal IPTV (1 mese)",
  tr: "Crystal IPTV Aboneliği (1 ay)",
  ru: "Подписка на Crystal IPTV (1 месяц)",
  pl: "Subskrypcja Crystal IPTV (1 miesiąc)",
};
export const total = {
  en: "Total",
  fr: "Total",
  es: "Total",
  de: "Gesamt",
  it: "Totale",
  tr: "Toplam",
  ru: "Итого",
  pl: "Suma",
};
export const addAnotherSubscription = {
  en: "Add another subscription",
  fr: "Ajouter une autre souscription",
  es: "Agregar otra suscripción",
  de: "Weitere Abonnement hinzufügen",
  it: "Aggiungi un'altra sottoscrizione",
  tr: "Başka bir abonelik ekle",
  ru: "Добавить еще одну подписку",
  pl: "Dodaj kolejną subskrypcję",
};
export const voucherOptional = {
  en: "Voucher (optional)",
  fr: "Bon d'achat (optionnel)",
  es: "Voucher (opcional)",
  de: "Gutschein (optional)",
  it: "Buono sconto (opzionale)",
  tr: "Kupon (isteğe bağlı)",
  ru: "Ваучер (по желанию)",
  pl: "Voucher (opcjonalny)",
};
export const validVoucher = {
  en: "Valid voucher",
  fr: "Bon d'achat valide",
  es: "Voucher válido",
  de: "Gültiger Gutschein",
  it: "Voucher valido",
  tr: "Geçerli kupon",
  ru: "Действующий ваучер",
  pl: "Ważny voucher",
};
export const invalidVoucher = {
  en: "Invalid voucher",
  fr: "Bon d'achat invalide",
  es: "Voucher no válido",
  de: "Ungültiger Gutschein",
  it: "Voucher non valido",
  tr: "Geçersiz kupon",
  ru: "Недействительный ваучер",
  pl: "Nieważny voucher",
};
export const crystalIPTVSubscriptions = {
  en: "Crystal IPTV Subscriptions",
  fr: "Abonnements Crystal IPTV",
  es: "Suscripciones de Crystal IPTV",
  de: "Crystal IPTV Abonnements",
  it: "Abbonamenti Crystal IPTV",
  tr: "Crystal IPTV Abonelikleri",
  ru: "Подписки на Crystal IPTV",
  pl: "Subskrypcje Crystal IPTV",
};
export const voucherDiscount = {
  en: "Voucher discount",
  fr: "Réduction avec bon d'achat",
  es: "Descuento con voucher",
  de: "Gutschein-Rabatt",
  it: "Sconto con voucher",
  tr: "Kupon indirimi",
  ru: "Скидка с ваучером",
  pl: "Rabat z vouchera",
};
export const discountRate = {
  en: "Discount rate",
  fr: "Taux de réduction",
  es: "Tasa de descuento",
  de: "Rabattquote",
  it: "Tasso di sconto",
  tr: "İndirim oranı",
  ru: "Ставка скидки",
  pl: "Stopa rabatu",
};
export const discount = {
  en: "Discount",
  fr: "Réduction",
  es: "Descuento",
  de: "Rabatt",
  it: "Sconto",
  tr: "İndirim",
  ru: "Скидка",
  pl: "Rabat",
};
export const emailWhatsappInputError = {
  en: "Your subscription information can be sent to you through email or WhatsApp. Please provide one of these contact details.",
  fr: "Vos informations d'abonnement peuvent vous être envoyées par e-mail ou WhatsApp. Veuillez fournir l'un de ces coordonnées.",
  es: "Su información de suscripción puede enviarse por correo electrónico o WhatsApp. Por favor, proporcione uno de estos detalles de contacto.",
  de: "Ihre Abonnementinformationen können Ihnen per E-Mail oder WhatsApp zugesandt werden. Bitte geben Sie eine dieser Kontaktinformationen an.",
  it: "Le informazioni sulla tua iscrizione possono essere inviate tramite email o WhatsApp. Fornisci uno di questi dettagli di contatto.",
  tr: "Abonelik bilgileriniz size e-posta veya WhatsApp aracılığıyla gönderilebilir. Lütfen bu iletişim bilgilerinden birini sağlayın.",
  ru: "Информацию о вашей подписке можно отправить вам по электронной почте или WhatsApp. Пожалуйста, укажите один из этих контактов.",
  pl: "Informacje o subskrypcji mogą być przesłane na Twój adres e-mail lub WhatsApp. Proszę podać jedne z tych danych kontaktowych.",
};
