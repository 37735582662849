import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
// import images
import whatsapp_logo from "../images/WhatsApp_logo.svg";
import mailit_icon from "../images/mailit.svg";
// import CSS
import "../styles/welcome_bar.css";
// import paragraphs
import * as int from "../../paragraphs/AllIn.js";
//
function ContactUSWhatsapp({ color }) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  const customTextColor = color == "grey" ? { color: "#585858" } : {};
  return (
    <div className="contact_us_whatsapp_wrapper">
      <div style={{ display: "inline-flex" }}>
        <a href="https://wa.me/15551234567" target="_blank">
          <button className="whatsapp_btn">
            <img src={whatsapp_logo} alt="whatsapp_logo" />
            <div>
              <p className="contact_us" style={customTextColor}>
                {int.contactUs[displayLanguage]}
              </p>
              <p className="whatsapp" style={customTextColor}>
                WhatsApp
              </p>
            </div>
          </button>
        </a>
        <a href="mailto:webmaster@example.com" target="_blank">
          <button className="whatsapp_btn">
            <img src={mailit_icon} alt="mailit_icon" />
            <div>
              <p className="contact_us" style={customTextColor}>
                {int.contactUs[displayLanguage]}
              </p>
              <p className="whatsapp" style={customTextColor}>
                Email
              </p>
            </div>
          </button>
        </a>
      </div>
    </div>
  );
}

export default ContactUSWhatsapp;
