import { useState } from "react";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import fourK from "../../images/4k.svg";
import hd from "../../images/hd.svg";
//

function ChannelLoader({ item, index }) {
  const [loaded, setLoaded] = useState(false);
  //

  return (
    <Grid item xs={12} md={6}>
      <ListItem
        className="resolution_container"
        key={index}
        sx={{
          border: "1px solid #bababa",
          borderRadius: "5px",
          margin: "0 ",
          padding: "20px 0",
          background:
            "linear-gradient(90deg, rgba(4,4,4,0) 0%, rgba(38,40,44,0.05) 100%);",
        }}
      >
        <Grid container direction="row" spacing={1}>
          <Grid item xs={4}>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
                height: "100%",
              }}
            >
              <img
                style={{ display: loaded ? "block" : "none" }}
                src={item.logo}
                width={item.hasOwnProperty("height") ? item.height : 60}
                onLoad={() => {
                  setLoaded(true);
                }}
              />
              {!loaded && <CircularProgress />}
            </span>
          </Grid>
          <Grid item xs={8}>
            <ListItemText primary={item.title} secondary={item.country} />
          </Grid>
        </Grid>

        {item.hasOwnProperty("res") ? (
          <span className="resolution">
            <img
              src={item.res === "4K" ? fourK : item.res === "HD" ? hd : ""}
              alt="fourK"
              height={"100%"}
            />
          </span>
        ) : (
          ""
        )}
      </ListItem>
    </Grid>
  );
}
export default ChannelLoader;
