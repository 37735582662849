import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
import LandingPricingCard from "./LandingPricingCard";
//
import * as int from "../../../paragraphs/landing_page_paragraphs.js";
//
const LandingPageSection4 = () => {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  return (
    <>
      <div className="landing_cards_wrapper">
        <LandingPricingCard rainbow price={"€03.99"} periodCode={12} />
        <LandingPricingCard price={"€05.99"} periodCode={6} />
        <LandingPricingCard price={"€06.99"} periodCode={3} />
        <LandingPricingCard price={"€09.99"} periodCode={1} />
      </div>
    </>
  );
};

export default LandingPageSection4;
