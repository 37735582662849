// recoil imports
import { useRecoilValue } from "recoil";
import {
  displayLanguageAtom,
  chosenSubscriptionAtom,
  isVoucherValidAtom,
  voucherRateAtom,
} from "../../../Atoms";
// import paragraphs
import * as int from "../../../paragraphs/payment_paragraphs.js";
//
function OrderSummaryDetails() {
  const chosenSubscription = useRecoilValue(chosenSubscriptionAtom);
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const isVoucherValid = useRecoilValue(isVoucherValidAtom);
  const voucherRate = useRecoilValue(voucherRateAtom);

  const subscriptionPrices = {
    1: "€09.99",
    3: "€20.99",
    6: "€39.99",
    12: "€65.99",
  };
  const subscriptionPriodLanguage = {
    1: int.crystalIPTVSubscription1Month[displayLanguage],
    3: int.crystalIPTVSubscription3Months[displayLanguage],
    6: int.crystalIPTVSubscription6Months[displayLanguage],
    12: int.crystalIPTVSubscription1Year[displayLanguage],
  };
  //
  const produceDetailsList = (item) => {
    return (
      <div className="order_details_unit">
        <p>{subscriptionPriodLanguage[item]}</p>
        <strong>{subscriptionPrices[item]}</strong>
      </div>
    );
  };

  const handlePriceTotal = () => {
    const price = subscriptionPrices[chosenSubscription];
    // Remove the Euro symbol and any leading/trailing whitespace
    const cleanedString = price.replace(/[^0-9.]/g, "").trim();
    // Parse the cleaned string as a float
    const floatValue = parseFloat(cleanedString);
    return floatValue;
  };

  const handleDiscountAmount = () => {
    const discountAmount = (handlePriceTotal() * parseInt(voucherRate)) / 100;
    return discountAmount.toFixed(2);
  };

  const produceDiscountCalculations = () => {
    if (isVoucherValid == "V") {
      return (
        <>
          <h2 className="my_subscription">
            {int.voucherDiscount[displayLanguage]}
          </h2>
          <div className="order_details_unit">
            <p>{int.discountRate[displayLanguage]}</p>
            <strong>{voucherRate}%</strong>
          </div>
          <div className="order_details_unit">
            <p>{int.discount[displayLanguage]}</p>
            <strong>€{handleDiscountAmount()}</strong>
          </div>
          <div className="order_details_unit total_unit">
            <p>Total</p>
            <strong>
              €{(handlePriceTotal() - handleDiscountAmount()).toFixed(2)}
            </strong>
          </div>
        </>
      );
    }
  };
  //
  return (
    <div className="order_summary_details">
      <h2 className="my_subscription">
        {int.crystalIPTVSubscriptions[displayLanguage]}
      </h2>
      {produceDetailsList(chosenSubscription)}
      <div className="order_details_unit order_details_unit_total total_unit">
        <p>{int.total[displayLanguage]}</p>
        <strong>€{handlePriceTotal()}</strong>
      </div>

      {produceDiscountCalculations()}
    </div>
  );
}

export default OrderSummaryDetails;
