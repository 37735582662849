import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms.js";
import ChannelLoader from "../comps/channels_list/ChannelLoader";
import * as int from "../../paragraphs/AllIn.js";
// import channels list as json string
import { channelsList } from "../../paragraphs/channels_list.js";

const produceChannels = (items) => {
  return items.map((item, index) => {
    return <ChannelLoader item={item} index={index} />;
  });
};

function ChannelsList() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [channels, setChannels] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [countryFilter, setCountryFilter] = useState("United Kingdom");
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper animation_wrapper_entrance"
  );
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
    if (searchTerm !== "") {
      const searchTermLower = searchTerm.toLowerCase();
      const processedChannels = channelsList.filter(
        (channel) =>
          channel.country === countryFilter &&
          channel.title.toLowerCase().includes(searchTermLower)
      );
      setChannels(processedChannels);
    } else {
      const processedChannels = channelsList.filter(
        (channel) => channel.country === countryFilter
      );
      setChannels(processedChannels);
    }
  }, [searchTerm, countryFilter]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: displayLanguage }}>
        <title> {int.helmetTitleChannelsList[displayLanguage]}</title>
        <meta
          name="description"
          content={int.helmetDescriptionChannelsList[displayLanguage]}
        />
        <meta property="og:title" content="Crystal IPTV" />
        <meta
          property="og:description"
          content="Premium IPTV and VOD subsriptions"
        />
        <meta property="og:url" content="www.crystaliptv.com/channels-list" />
      </Helmet>
      <Box
        sx={{
          width: "100%",
          maxWidth: 800,
          minHeight: 800,
          margin: "auto",
          "& .channels_list_header": {
            backgroundColor: "#e0e0e0",
          },
        }}
        className={wrapperClass}
      >
        <h2 className="pricing_page_header">
          {int.tvChannelsList[displayLanguage]}
        </h2>
        <p style={{ textAlign: "center" }}>
          {int.channelsTableDescription[displayLanguage]}
        </p>
        <br />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            padding: "5px 10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              marginBottom: "15px",
            }}
          >
            <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField
              id="input-with-sx"
              label="Find Channel"
              variant="standard"
              margin="dense"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </Box>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Country
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={countryFilter}
              onChange={(e) => {
                setSearchTerm("");
                setCountryFilter(e.target.value);
              }}
              label="Country"
            >
              <MenuItem value="United Kingdom">United Kingdom</MenuItem>
              <MenuItem value="France">France</MenuItem>
              <MenuItem value="Spain">Spain</MenuItem>
              <MenuItem value="Germany">Germany</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Grid container spacing={1}>
          {produceChannels(channels)}
        </Grid>
      </Box>
      <br />
    </>
  );
}

export default ChannelsList;
