export const channelsList = [
  {
    title: "BBC ONE",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/01/BBC_One_logo_%28box_variant%29.svg",
    res: "4K",
  },
  {
    title: "BBC TWO",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/0/05/BBC_Two_HD.svg",
  },
  {
    title: "ITV",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/d/d0/ITV_logo_2019.svg",
  },
  {
    title: "Channel 4",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Channel_4_logo_2015.svg",
    height: 40,
  },
  {
    title: "Channel 5",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/c/cb/Channel_5_%28UK%29_2016.svg",
    height: 40,
  },
  {
    title: "Sky One",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/1/14/Sky_One_-_Logo_2020.png",
    height: 80,
  },
  {
    title: "Sky News",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/4/43/Sky_News_2015_Logo.svg",
    height: 90,
  },
  {
    title: "E4",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/1/18/E4_%28channel%29_logo.svg",
  },
  {
    title: "BBC News",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/6/62/BBC_News_2019.svg",
  },
  {
    title: "CBBC",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/3/35/CBBC_%282023%29.svg",
  },
  {
    title: "CBeebies",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/b/b5/CBeebies_2023.svg",
    height: 90,
  },
  {
    title: "Dave",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Dave_2022.svg",
  },
  {
    title: "Film4",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/5/53/Film4_logo_2018.svg",
  },
  {
    title: "More4",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/e/e6/More4_logo_2018.svg",
  },
  {
    title: "BBC Four",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/6/6d/BBC_Four_logo_2021.svg",
  },
  {
    title: "ITV2",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/d/d8/ITV2_logo_2022.svg",
  },
  {
    title: "ITV3",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/f/f6/ITV3_logo_2013.svg",
  },
  {
    title: "ITV4",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/5/57/ITV4_logo_%282022%29.svg",
  },
  {
    title: "5USA",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/06/5USA_logo.svg",
  },
  {
    title: "5STAR",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/d/dd/5Star_logo_2019.svg",
  },
  {
    title: "Comedy Central",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Comedy_Central_2018.svg",
  },
  {
    title: "Comedy Central Extra",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/4/49/Comedy_Central_Extra_logo_2019.svg",
  },
  {
    title: "MTV",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/c/ce/MTV-Logo.svg",
  },
  {
    title: "MTV Music",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/f/f4/MTV_Music_2021_logo.svg",
  },
  {
    title: "MTV Base",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/f/f2/MTV_Base_2013_logo.svg",
  },
  {
    title: "MTV Hits",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/f/f8/MTV_Hits_2017_logo.svg",
  },
  {
    title: "MTV Classic",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/f/fb/MTV_Classic_2017_logo.svg",
  },
  {
    title: "4Music",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/4/49/4Music_logo_2018.svg",
    height: 90,
  },
  {
    title: "5Spike",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/2/28/5Spike_Logo.svg",
    height: 90,
  },
  {
    title: "5SELECT",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/en/d/da/5Select_logo.svg",
    height: 90,
  },
  {
    title: "Discovery Channel",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/2/27/Discovery_Channel_-_Logo_2019.svg",
    height: 90,
  },
  {
    title: "National Geographic",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/6/6a/National-Geographic-Logo.svg",
    height: 90,
  },
  {
    title: "Animal Planet",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/07/Animal_Planet_logo.svg",
  },
  {
    title: "History Channel",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/f/f5/History_Logo.svg",
  },
  {
    title: "Sky Arts",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Logo_de_Sky_Arts.png",
    height: 80,
  },
  {
    title: "Eurosport",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/1/17/Eurosport_Logo_2015.svg",
    height: 100,
  },
  {
    title: "CNN",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/6/66/CNN_International_logo.svg",
  },
  {
    title: "Al Jazeera English",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/fr/0/0f/Al_Jazeera.svg",
    height: 40,
  },
  {
    title: "CNBC",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/e/e3/CNBC_logo.svg",
  },
  {
    title: "Bloomberg",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/5/5d/New_Bloomberg_Logo.svg",
    height: 90,
  },
  {
    title: "Sky Sports News",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/2/23/Sky_sports_news_2021_logo.svg",
    height: 100,
  },
  {
    title: "BT Sport 1",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/e/ea/BT_Sport_logo_2019.svg",
    height: 95,
  },
  {
    title: "BT Sport 2",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/e/ea/BT_Sport_logo_2019.svg",
    height: 95,
  },
  {
    title: "ESPN",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/2/2f/ESPN_wordmark.svg",
    height: 90,
  },
  {
    title: "Premier Sports",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Premier_sports_logo_2018.svg",
    height: 100,
  },
  {
    title: "BBC Parliament",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/4/49/BBC_Parliament_Logo.svg",
  },
  {
    title: "Sky Atlantic",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/1/18/Sky_Atlantic_-_Logo_2020.svg",
    height: 100,
  },
  {
    title: "Sky Cinema",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Sky_Cinema_-_Logo_2020.svg",
    height: 100,
  },
  {
    title: "Food Network",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/4/45/Food_Network_Logo.svg",
  },
  {
    title: "HGTV",
    country: "United Kingdom",
    logo: "https://upload.wikimedia.org/wikipedia/commons/c/c5/HGTV_US_Logo_2015.svg",
  },
  // FRENCH
  {
    title: "TF1",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/3/3c/TF1_logo_2013.svg",
    height: 70,
  },
  {
    title: "France 2",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/5/53/France_2_2018.svg",
  },
  {
    title: "France 3",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/d/dd/France_3_2018.svg",
  },
  {
    title: "France 4",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/3/3b/France_4_2018.svg",
  },
  {
    title: "France 5",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/5/50/France_5_2018.svg",
  },
  {
    title: "France 24",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/fr/c/ce/FRANCE24.svg",
  },
  {
    title: "Arte",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Arte-Logo.svg",
    height: 80,
  },
  {
    title: "Gulli",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/fr/8/88/Logo-Gulli.svg",
  },
  {
    title: "Franceinfo",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/03/Franceinfo.svg",
    height: 90,
  },
  {
    title: "Chérie 25",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Logo_Ch%C3%A9rie_25.svg",
  },
  {
    title: "beIN Sports",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/3/35/BeINSports2017.png",
    height: 100,
  },

  {
    title: "beIN Sports 2",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/b/b1/Bein_sports2_2017.svg",
    height: 100,
  },
  {
    title: "beIN Sports 3",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/5/51/Bein_Sports_3.svg",
    height: 100,
  },
  {
    title: "beIN Sports MAX 4",
    country: "France",

    logo: "https://static.wikia.nocookie.net/logopedia/images/2/29/Bein_sports_Max_4.svg",
    height: 100,
  },
  {
    title: "beIN Sports MAX 5",
    country: "France",

    logo: "https://static.wikia.nocookie.net/logopedia/images/f/fa/Bein_Sports_Max_5.svg",
    height: 100,
  },
  {
    title: "beIN Sports MAX 6",
    country: "France",

    logo: "https://static.wikia.nocookie.net/logopedia/images/4/48/Bein_Sports_Max_6.svg",
    height: 100,
  },
  {
    title: "beIN Sports MAX 7",
    country: "France",

    logo: "https://static.wikia.nocookie.net/logopedia/images/2/29/Bein_Sports_Max_7.svg",
    height: 100,
  },
  {
    title: "beIN Sports MAX 8",
    country: "France",

    logo: "https://static.wikia.nocookie.net/logopedia/images/d/db/Bein_Sports_Max_8.svg",
    height: 100,
  },
  {
    title: "beIN Sports MAX 9",
    country: "France",

    logo: "https://static.wikia.nocookie.net/logopedia/images/5/50/Bein_Sports_Max_9.svg",
    height: 100,
  },
  {
    title: "beIN Sports MAX 10",
    country: "France",

    logo: "https://static.wikia.nocookie.net/logopedia/images/3/3d/Bein_Sports_Max_10.svg",
    height: 100,
  },
  {
    title: "Canal+",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Logo_Canal%2B_1995.svg",
    height: 80,
  },
  {
    title: "Canal+ Sport",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/2/25/Canal%2B_Sport_2023.svg",
    height: 80,
  },
  {
    title: "Canal+ Cinéma(s)",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/2/21/Canal%2B_Cin%C3%A9mas_2023.svg",
    height: 80,
  },
  {
    title: "Canal+ Séries",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/3/33/Canal%2B_S%C3%A9ries_2023.svg",
    height: 80,
  },
  {
    title: "Canal+ Kids",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/b/b1/Canal%2B_Kids_2023.svg",
    height: 80,
  },
  {
    title: "Canal+ Docs",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/a/a7/Canal%2B_Docs_2023.svg",
    height: 80,
  },
  {
    title: "Canal+ Grand Écran",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/2/24/Canal%2B_Grand_%C3%89cran_2023.svg",
    height: 80,
  },
  {
    title: "Canal+ Sport 360",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/e/e7/Canal%2B_Sport_360_2023.svg",
    height: 80,
  },
  {
    title: "Canal+ Foot",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/c/c5/Canal%2B_Foot_2023.svg",
    height: 80,
  },
  {
    title: "Canal+ Box Office",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/3/35/Canal%2B_Box_Office_2023.svg",
    height: 80,
  },
  {
    title: "CNews",
    country: "France",
    logo: "https://static.wikia.nocookie.net/logopedia/images/5/57/CNews.svg",
    height: 90,
  },
  {
    title: "RMC Découverte",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/fr/b/b3/RMC_D%C3%A9couverte_logo_2017.svg",
  },
  {
    title: "M6",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/fr/2/22/M6_2009.svg",
  },

  {
    title: "C8",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Logo_C8_2016.svg",
  },
  {
    title: "W9",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/6/62/W9-Logo.svg",
  },

  {
    title: "TMC",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/en/5/54/TMC_logo.svg",
    height: 70,
  },
  {
    title: "TFX  ",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/d/d3/TFX_logo.svg",
  },
  {
    title: "NRJ 12",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/en/6/65/NRJ_12_2015_logo.png",
  },

  {
    title: "La Chaîne parlementaire",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/fr/1/1f/La_cha%C3%AEne_parlementaire.svg",
  },

  {
    title: "Culturebox",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Culuturebox_logo_2021.svg",
  },

  {
    title: "BFM TV",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/4/45/Logo_BFMTV_2019.svg",
  },

  {
    title: "CNews",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Canal_News_logo.svg",
    height: 90,
  },

  {
    title: "L'Équipe",
    country: "France",
    logo: "https://upload.wikimedia.org/wikipedia/commons/3/32/L%27%C3%89quipe_wordmark.svg",
    height: 90,
  },
  {
    title: "ZDF",
    country: "Germany",
    logo: "https://upload.wikimedia.org/wikipedia/commons/c/c1/ZDF_logo.svg",
    height: 80,
  },
  {
    title: "RTL",
    country: "Germany",
    logo: "https://upload.wikimedia.org/wikipedia/commons/5/51/RTL_logo.svg",
  },
  {
    title: "Deutsche Welle",
    country: "Germany",
    logo: "https://upload.wikimedia.org/wikipedia/commons/7/75/Deutsche_Welle_symbol_2012.svg",
    height: 80,
  },
  {
    title: "ARD 1",
    country: "Germany",
    logo: "https://upload.wikimedia.org/wikipedia/commons/6/68/ARD_logo.svg",
    height: 90,
  },
  {
    title: "Sport 1",
    country: "Germany",
    logo: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Sport-1-Logo%2C_2013.svg",
    height: 90,
  },
  {
    title: "DAZN 1",
    country: "Germany",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/06/DAZN_Logo_Master.svg",
  },
  {
    title: "DAZN 2",
    country: "Germany",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/06/DAZN_Logo_Master.svg",
  },
  {
    title: "DAZN 3",
    country: "Germany",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/06/DAZN_Logo_Master.svg",
  },
  {
    title: "La Sexta",
    country: "Spain",
    logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/La_Sexta_%28A3M%29.svg",
    height: 50,
  },
  {
    title: "La 1",
    country: "Spain",
    logo: "https://upload.wikimedia.org/wikipedia/commons/1/19/Logo_TVE-1.svg",
    height: 80,
  },
  {
    title: "Movistar La Liga",
    country: "Spain",
    logo: "https://static.wikia.nocookie.net/logopedia/images/c/cd/LaLigaTV_por_Movistar_Plus%2B_2023.svg",
    height: 70,
  },
  {
    title: "Movistar La Liga UHD",
    country: "Spain",
    logo: "https://static.wikia.nocookie.net/logopedia/images/d/d3/LaLigaTV_UHD_por_Movistar_Plus%2B_2023.png",
    height: 90,
    res: "4K",
  },
  {
    title: "Movistar La Liga 2",
    country: "Spain",
    logo: "https://static.wikia.nocookie.net/logopedia/images/4/4c/LaLigaTV_2_por_Movistar_Plus%2B_2023.png",
    height: 80,
  },
  {
    title: "Movistar La Liga 3",
    country: "Spain",
    logo: "https://static.wikia.nocookie.net/logopedia/images/0/0e/LaLigaTV_3_por_Movistar_Plus%2B_2023.png",
    height: 80,
  },

  {
    title: "Cuatro",
    country: "Spain",
    logo: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Logotipo_de_Cuatro.svg",
    height: 90,
  },
  {
    title: "RTVE",
    country: "Spain",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Logo_RTVE.svg/1920px-Logo_RTVE.svg.png",
  },
  {
    title: "FOX Deportes",
    country: "Spain",
    logo: "https://upload.wikimedia.org/wikipedia/commons/f/f4/FOX_Deportes_logo.svg",
    height: 70,
  },
  {
    title: "Estrella TV",
    country: "Spain",
    logo: "https://upload.wikimedia.org/wikipedia/en/9/99/Estrella_TV_-_2020_logo.png",
    height: 50,
  },
  {
    title: "Universo ",
    country: "Spain",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Universo_2017.svg/1920px-Universo_2017.svg.png",
    height: 80,
  },
  {
    title: "Telecinco",
    country: "Spain",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Telecinco.svg/225px-Telecinco.svg.png",
    height: 55,
  },
  {
    title: "Cinelatino",
    country: "Spain",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Cine_Latino_logo.svg/1280px-Cine_Latino_logo.svg.png",
  },
  {
    title: "Telemundo",
    country: "Spain",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Telemundo_logo_2018.svg/1024px-Telemundo_logo_2018.svg.png",
  },
  {
    title: "DAZN LaLiga",
    country: "Spain",
    logo: "https://static.wikia.nocookie.net/logopedia/images/9/97/DAZN_LaLiga_2023.svg",
    height: 80,
  },
  {
    title: "DAZN F1",
    country: "Spain",
    logo: "https://static.wikia.nocookie.net/logopedia/images/c/cc/DAZN_F1.svg",
    height: 80,
  },
  {
    title: "DAZN F1 4K",
    country: "Spain",
    logo: "https://static.wikia.nocookie.net/logopedia/images/f/f5/DAZN_F1_4K.svg",
    res: "4K",
    height: 80,
  },
  {
    title: "____",
    country: "Spain",
    logo: "_______",
  },
];

export const tvShows =
  '[{"title":"Game of Thrones","genre":"Fantasy","year":2014},{"title":"Stranger Things","genre":"Science Fiction","year":2016},{"title":"Breaking Bad","genre":"Crime Drama","year":2008},{"title":"The Crown","genre":"Drama","year":2016},{"title":"Friends","genre":"Comedy","year":1994},{"title":"Black Mirror","genre":"Science Fiction","year":2011},{"title":"The Mandalorian","genre":"Science Fiction","year":2019},{"title":"The Office (US)","genre":"Comedy","year":2005},{"title":"Sherlock","genre":"Mystery","year":2010},{"title":"The Witcher","genre":"Fantasy","year":2019}, {"title":"Prison Break","genre":"Action","year":2005}]';
