import { useState, useEffect } from "react";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// import paragraphs
import * as int from "../../../paragraphs/payment_paragraphs.js";

// import css
import "../../styles/account_settings.css";
//
const selecetedCSS =
  "account_settings_options_button account_settings_options_button_selected black_underline";
const unselectedCSS = "account_settings_options_button";

function IPTVPreferencesHeader({ selected, setSelected }) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper_entrance2 animation_wrapper account_settings_options_wrapper"
  );
  // handle currently seleced option mechanism
  const handleSelected = (btn) => {
    setSelected(btn);
  };

  //
  useEffect(() => {
    setWrapperClass("animation_wrapper account_settings_options_wrapper");
  }, []);

  return (
    <>
      <div className={wrapperClass}>
        <button
          key={1}
          className={selected == "subscriptions" ? selecetedCSS : unselectedCSS}
          onClick={() => {
            handleSelected("subscriptions");
          }}
        >
          {int.subscriptions[displayLanguage]}
        </button>
        <button
          key={2}
          className={
            selected == "bundle_selection" ? selecetedCSS : unselectedCSS
          }
          onClick={() => {
            handleSelected("bundle_selection");
          }}
        >
          {int.bundleSelection[displayLanguage]}
        </button>{" "}
        <button
          key={3}
          className={
            selected == "email_whatsapp" ? selecetedCSS : unselectedCSS
          }
          onClick={() => {
            handleSelected("email_whatsapp");
          }}
        >
          Email / WhatsApp
        </button>
      </div>
    </>
  );
}

export default IPTVPreferencesHeader;
