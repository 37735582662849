import { Link, useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom, authenticatedAtom } from "../../../Atoms";
import "../../styles/navbar.css";

// sub components imports
import LanguageSelection from "./LanguageSelection";
import ProfileSettingsGear from "./ProfileSettingsGear";

// Images imports
import crystaliptv_logo from "../../images/crystaliptv_logo.svg";

// Paragraphs imports
import * as int from "../../../paragraphs/navbar_paragraphs";

//
function NavBar() {
  const isAuth = useRecoilValue(authenticatedAtom);
  // bring the display languages atom for internationalization
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname != "/landing" && location.pathname != "/admin-panel") {
    return (
      <>
        <nav className="navbar">
          <img
            src={crystaliptv_logo}
            alt="crystaliptv_logo"
            className="navbar_logo"
            onClick={() => {
              navigate("/");
            }}
          />

          <div className="nav_links">
            <Link to="/pricing">{int.pricing[displayLanguage]}</Link>
            <Link to="/channels-list">{int.channelsList[displayLanguage]}</Link>
            <Link to="/movies-series-list">
              {int.moviesAndSeriesList[displayLanguage]}
            </Link>
            <Link to="/faq">FAQs</Link>
          </div>

          <div className="account_gears">
            <LanguageSelection />
            <div className="account_gears_seperator"></div>
            <ProfileSettingsGear />
          </div>
        </nav>
        <div className="rainbow_line"></div>
      </>
    );
  }
}

export default NavBar;
